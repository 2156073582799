import Episode, { DistanceKey } from 'models/entities/Episode';

// DistanceNode is a helper class for calculating distances
class DistanceNode {
  episode: Episode;
  related = new Set();
  vector: number[] = [];
  distances: number[] = [];

  constructor(episode: Episode) {
    this.episode = episode;
  }
}

type FieldInfluence = Partial<Record<keyof Episode | 'year', number>>;

// calculateEpisodeDistances calculates episode distances for the given key
export const calculateEpisodeDistances = (
  episodes: Episode[],
  key: DistanceKey
) => {
  const nodes = getDistanceNodes(episodes);
  // Calculate
  calculate(nodes, key);
};

// getDistanceNodes converts Episodes to DistanceNodes
export const getDistanceNodes = (episodes: Episode[]): DistanceNode[] => {
  return episodes.map((episode) => new DistanceNode(episode));
};

// calculate distances for the given key
export const calculate = (nodes: DistanceNode[], key: DistanceKey) => {
  // Influence per field
  const fieldInfluence: FieldInfluence = {
    theme: 2,
    location: 1,
    tag: 1,
    speaker: 1,
    year: 1,
  };

  // Combine distanceKey with theme
  switch (key) {
    case DistanceKey.THEME:
      calculateDistances(key, nodes, ['theme'], fieldInfluence, true);
      break;
    default:
      const includeFields: Array<keyof Episode> = [
        'theme',
        key as keyof Episode,
      ];

      // Lower theme field influence
      fieldInfluence[key as keyof Episode] = 1;
      fieldInfluence['theme'] = 0.4;
      fieldInfluence['year'] = 0.2;

      calculateDistances(key, nodes, includeFields, fieldInfluence, false);
  }
};

const calculateDistances = (
  distanceKey: DistanceKey,
  nodes: DistanceNode[],
  includeFields: Array<keyof Episode>,
  fieldInfluence: FieldInfluence,
  addYearScore: boolean
) => {
  // Unique keys and types
  const keys = new Map();

  // Get related uids
  nodes.forEach((node) => {
    // Per field
    includeFields.forEach((field) => {
      // Field based
      node.episode[field as DistanceKey].forEach((related) => {
        keys.set(related, field);
        node.related.add(related);
      });
    });

    // Per year
    const year = node.episode.year.toString();
    keys.set(year, 'year');
    node.related.add(year);
  });

  // Create vector
  nodes.forEach((node) => {
    keys.forEach((type: string, uid: string) => {
      node.vector.push(
        node.related.has(uid) ? fieldInfluence[type as DistanceKey] || 0 : 0
      );
    });
    node.distances = [];
  });

  // Get all distances, make use of array symmetry
  let dist, yearDist;
  for (let i = 0, len = nodes.length; i < len; i++) {
    nodes[i].distances.push(0); // distance to self, always 0
    for (let j = i + 1; j < len; j++) {
      // Calculate year distance
      yearDist = addYearScore
        ? Math.abs(nodes[i].episode.year - nodes[j].episode.year) / 20
        : 0;
      // Distance
      dist = yearDist + distance(nodes[i].vector, nodes[j].vector);
      nodes[i].distances.push(dist);
      nodes[j].distances.push(dist);
    }
  }
  // Store distances to episodes
  nodes.forEach((node, index) => {
    node.episode.distances[distanceKey] = node.distances;
    node.episode.storeIndex = index;
  });
};

// Get squared distance between two vectors
const distanceSquared = (a: number[], b: number[]) => {
  let sum = 0;
  for (let n = 0, len = a.length; n < len; n++) {
    sum += (a[n] - b[n]) * (a[n] - b[n]);
  }
  return sum;
};

// Get distance between two vectors
const distance = (a: number[], b: number[]) => {
  return Math.sqrt(distanceSquared(a, b));
};
