import Episode from 'models/entities/Episode';
import EpisodeDetails from 'models/entities/EpisodeDetails';
import Location from 'models/entities/Location';
import Maker from 'models/entities/Maker';
import Speaker from 'models/entities/Speaker';
import Tag from 'models/entities/Tag';
import Theme from 'models/entities/Theme';
import Year from 'models/entities/Year';

// EntityMapper contains mappers that create different Entity instances
export default class EntityMapper {
  // episode maps an object to an Episode instance
  static episode(obj: Partial<Episode>): Episode {
    const episode = new Episode();
    episode.id = obj.id || episode.id;
    episode.uid = obj.uid || episode.uid;
    episode.name = obj.name || episode.name;
    episode.date = obj.date || episode.date;
    episode.speaker = obj.speaker || episode.speaker;
    episode.tag = obj.tag || episode.tag;
    episode.location = obj.location || episode.location;
    episode.maker = obj.maker || episode.maker;
    episode.theme = obj.theme || episode.theme;
    return episode;
  }

  // episodeDetails maps an object to an EpisodeDetails instance
  static episodeDetails(obj: Partial<EpisodeDetails>): EpisodeDetails {
    const episode = new EpisodeDetails();
    episode.id = obj.id || episode.id;
    episode.uid = obj.uid || episode.uid;
    episode.name = obj.name || episode.name;
    episode.date = obj.date || episode.date;
    episode.poms = obj.poms || episode.poms;
    episode.description = obj.description || episode.description;
    episode.episode = obj.episode || episode.episode;
    episode.season = obj.season || episode.season;
    episode.date = obj.date || episode.date;
    episode.websiteUrl = obj.websiteUrl || episode.websiteUrl;
    episode.imageUrl = obj.imageUrl || episode.imageUrl;
    episode.thumbnailUrl = obj.thumbnailUrl || episode.thumbnailUrl;
    episode.vimeoId = obj.vimeoId || episode.vimeoId;
    episode.vimeoUrl = obj.vimeoUrl || episode.vimeoUrl;
    episode.englishVersion = obj.englishVersion || episode.englishVersion;
    episode.englishVersionUrl =
      obj.englishVersionUrl || episode.englishVersionUrl;
    episode.speaker = obj.speaker || episode.speaker;
    episode.tag = obj.tag || episode.tag;
    episode.location = obj.location || episode.location;
    episode.theme = obj.theme || episode.theme;
    episode.maker = obj.maker || episode.maker;
    episode.director = obj.director || episode.director;
    episode.producer = obj.producer || episode.producer;
    episode.researcher = obj.researcher || episode.researcher;
    episode.chiefEditor = obj.chiefEditor || episode.chiefEditor;

    return episode;
  }

  // location maps an object to a Location instance
  static location(obj: Partial<Location>): Location {
    const location = new Location();
    location.id = obj.id || location.id;
    location.name = obj.name || location.name;
    return location;
  }

  // maker maps an object to a Maker instance
  static maker(obj: Partial<Maker>): Maker {
    const maker = new Maker();
    maker.id = obj.id || maker.id;
    maker.name = obj.name || maker.name;
    return maker;
  }

  // speaker maps an object to a Speaker instance
  static speaker(obj: Partial<Speaker>): Speaker {
    const speaker = new Speaker();
    speaker.id = obj.id || speaker.id;
    speaker.name = obj.name || speaker.name;
    speaker.firstName = obj.firstName || speaker.firstName;
    speaker.lastName = obj.lastName || speaker.lastName;
    speaker.description = obj.description || speaker.description;
    return speaker;
  }

  // tag maps an object to a Tag instance
  static tag(obj: Partial<Tag>): Tag {
    const tag = new Tag();
    tag.id = obj.id || tag.id;
    tag.name = obj.name || tag.name;
    tag.terms = obj.terms || tag.terms;
    return tag;
  }

  // theme maps an object to a Theme instance
  static theme(obj: Partial<Theme>): Theme {
    const theme = new Theme();
    theme.id = obj.id || theme.id;
    theme.name = obj.name || theme.name;
    theme.longName = obj.longName || theme.longName;
    theme.description = obj.description || theme.description;
    theme.subjects = obj.subjects || theme.subjects;
    theme.imageUrl = obj.imageUrl || theme.imageUrl;
    theme.thumbnailUrl = obj.thumbnailUrl || theme.thumbnailUrl;
    return theme;
  }

  // year maps an object to a Year instance
  static year(obj: Partial<Year>): Year {
    const year = new Year();
    year.id = obj.id || year.id;
    year.name = obj.name || year.name;
    year.year = obj.name ? parseInt(obj.name) : year.year;
    return year;
  }
}
