import './InfoToolTip.scss';
import ToolTip from 'components/Tooltip/ToolTip';
import { FunctionComponent } from 'react';

interface Props {
  title: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const InfoToolTip: FunctionComponent<Props> = ({
  title,
  style = undefined,
  onClick = undefined,
}) => {
  return (
    <ToolTip className="InfoToolTip" title={title} style={style}>
      <span onClick={onClick}>info</span>
    </ToolTip>
  );
};

export default InfoToolTip;
