import { FONT_NAME } from 'config/constants';
import * as d3 from 'd3';
import { toggleFilter } from 'events/navigation';
import { getPixelDeviceRatio } from 'hooks/useDevice';
import Theme from 'models/entities/Theme';
import Store from 'models/Store';
import * as PIXI from 'pixi.js';
import BaseLayout from './BaseLayout';
import SimulationNode from './SimulationNode';
import Viewport from './Viewport';

const FORCE_TIMELINE = 'timeline';

export enum TimelineSort {
  THEME = 'theme',
  DATE = 'date',
}

export default class EpisodeTimelineLayout extends BaseLayout {
  private nodePositions: PIXI.Point[] = [];

  private viewport: Viewport;

  private lines: PIXI.Sprite[] = [];
  private years: PIXI.BitmapText[] = [];
  private linesContainer: PIXI.Container = new PIXI.Container();
  private yearsContainer: PIXI.Container = new PIXI.Container();

  private themeIndex: Record<string, number> = {};
  private themes: Set<Theme | undefined> = new Set();
  private yearExtent: number[] = [];
  private yearRange = 0;
  private yearWidth = 0;
  private nodeHeight = 0;
  private maxNodesPerYear = 0;
  private nodesPerYear: Record<number, SimulationNode[]> = {};
  private columns = 0;
  private maxNodesPerColumn = 14;
  private nodesPerColumn = 0;
  private minScaleX = 0;
  private minScaleY = 0;
  private sort: TimelineSort;

  constructor(viewport: Viewport, sort: TimelineSort) {
    super();
    this.viewport = viewport;
    this.sort = sort;

    // Init
    this.initContainers();
    this.addUpdateListener();

    // Forces
    this.removeForceCenter();
    this.removeForceCollide();
    this.addForceTimeline();

    this.viewport.shouldCenterOnMinScale = false;
  }

  initContainers() {
    // Lines container
    this.viewport.addChildAt(this.linesContainer, 0);

    // Years container
    this.yearsContainer.interactiveChildren = true;
    this.viewport.addChildAt(this.yearsContainer, 0);
  }

  addUpdateListener() {
    PIXI.Ticker.shared.add(this.onUpdate);
  }

  removeUpdateListener() {
    PIXI.Ticker.shared.remove(this.onUpdate);
  }

  onUpdate = () => {
    const minScale = (this.minScaleY * 1.01) / getPixelDeviceRatio();
    // Restrict drag direction
    if (this.viewport.getTargetScale() < minScale) {
      if (!this.viewport.restrictDraggingToX) {
        this.viewport.drag({ direction: 'x' });
        this.viewport.restrictDraggingToX = true;
      }
    } else {
      if (this.viewport.restrictDraggingToX) {
        this.viewport.drag({ direction: 'all' });
        this.viewport.restrictDraggingToX = false;
      }
    }

    // Year scales/positions
    this.years.forEach((year) => {
      year.scale.set(
        Math.max(
          0.1,
          Math.min(this.yearWidth / 200, 0.3 + 0.2 / this.viewport.scale.x)
        )
      );
      year.position.y =
        50 * year.scale.x +
        (Math.min(this.maxNodesPerYear, this.nodesPerColumn) + 0.4) *
          this.nodeHeight *
          0.5;
    });
  };

  destroy() {
    this.lines = [];
    this.years = [];
    this.removeUpdateListener();
    this.viewport.removeChild(this.linesContainer, this.yearsContainer);
    this.viewport.drag();
    this.viewport.restrictDraggingToX = false;
    super.destroy();
  }

  onNodesUpdate(allNodes: boolean) {
    if (this.nodes.length === 0) {
      this.active = false;
      this.clearLines();
      this.clearYears();
      return;
    }
    this.updateLayout();
    this.updateNodePositions();
    this.resetScale();
    this.updateLines();
    this.updateYears();
    this.active = true;
  }

  updateThemeSort() {
    // Theme index
    this.themes = new Set();
    this.nodes.forEach((node) => {
      this.themes.add(
        Store._entities.themes.find(
          (theme) => theme.id === node.targetNode.episode?.getTheme()
        )
      );
    });
    this.themeIndex = { unknown: this.themes.size - 1 };
    (Array.from(this.themes.values()) as Theme[])
      .sort((a: Theme, b: Theme) => a.name.localeCompare(b.name))
      .forEach((theme, index) => {
        if (theme) {
          this.themeIndex[theme.id] = index;
        }
      });

    // Sort nodes per year based on theme
    Object.values(this.nodesPerYear).forEach((nodes) => {
      nodes.sort((nodeA, nodeB) => {
        const themeDiff =
          this.themeIndex[nodeB.targetNode.episode?.getTheme() || 'unknown'] -
          this.themeIndex[nodeA.targetNode.episode?.getTheme() || 'unknown'];
        if (themeDiff !== 0) {
          return themeDiff;
        }
        return (
          (nodeB.targetNode.episode?.time || 0) -
          (nodeA.targetNode.episode?.time || 0)
        );
      });
    });
  }

  updateDateSort() {
    // Sort nodes per year based on date
    Object.values(this.nodesPerYear).forEach((nodes) => {
      nodes.sort((nodeA, nodeB) => {
        return (
          (nodeB.targetNode.episode?.time || 0) -
          (nodeA.targetNode.episode?.time || 0)
        );
      });
    });
  }

  updateLayout() {
    // Get nodes per year
    this.nodesPerYear = {};
    this.nodes.forEach((node) => {
      const year = node.targetNode.episode?.year || 0;
      if (!(year in this.nodesPerYear)) {
        this.nodesPerYear[year] = [];
      }
      this.nodesPerYear[year].push(node);
    });

    switch (this.sort) {
      case TimelineSort.THEME:
        this.updateThemeSort();
        break;
      case TimelineSort.DATE:
        this.updateDateSort();
        break;
    }

    // Loop nodes, create year extent
    this.yearExtent = d3.extent(
      Object.keys(this.nodesPerYear).map((v) => parseInt(v))
    ) as number[];

    this.maxNodesPerYear = 0;
    Object.values(this.nodesPerYear).forEach((nodes) => {
      this.maxNodesPerYear = Math.max(nodes.length, this.maxNodesPerYear);
    });

    this.yearRange = this.yearExtent[1] - this.yearExtent[0] + 1;

    const columnRatio = this.viewport.getHeight() / this.viewport.getWidth();
    this.nodesPerColumn = Math.min(
      this.maxNodesPerColumn,
      this.maxNodesPerYear,
      Math.max(
        3,
        Math.round(
          (Math.min(this.maxNodesPerColumn, this.maxNodesPerYear) / 7) *
            columnRatio *
            2 *
            this.yearRange
        )
      )
    );

    this.columns = Math.ceil(this.maxNodesPerYear / this.nodesPerColumn);

    this.yearWidth = 120 + 420 * this.columns;
    this.nodeHeight = 455;
  }

  updateNodePositions() {
    const nodePositions: Map<SimulationNode, PIXI.Point> = new Map();

    Object.entries(this.nodesPerYear).forEach(([year, nodes]) => {
      const columns: Array<SimulationNode[]> = [];
      for (let i = 0; i < this.columns; i++) {
        columns.push([]);
      }

      const colWidth = 0.75 / this.columns;

      const baseX =
        parseInt(year) -
        this.yearExtent[0] -
        this.yearRange / 2 +
        0.5 +
        colWidth;
      const baseY = this.nodesPerColumn - this.nodesPerColumn / 2;

      const cols = Math.ceil(nodes.length / this.nodesPerColumn);

      const partialHeight =
        nodes.length % this.nodesPerColumn
          ? (nodes.length % this.nodesPerColumn) - 1
          : this.nodesPerColumn; //;
      const baseSize = cols * partialHeight;

      nodes.forEach((node, index) => {
        const col =
          index > baseSize
            ? cols - 2 - ((index - baseSize) % Math.max(1, cols - 1))
            : cols - 1 - (index % cols);
        columns[col].push(node);
      });

      Object.values(columns).forEach((nodes, col) => {
        const nodeX = (col - cols / 2 - 0.5) * colWidth;
        nodes.forEach((node, index) => {
          const nodeY = index + 0.6;
          const pos = new PIXI.Point();
          pos.x = this.yearWidth * (baseX + nodeX);
          pos.y = this.nodeHeight * (baseY - nodeY);
          nodePositions.set(node, pos);
        });
      });
    });

    this.nodePositions = this.nodes.map(
      (node) => nodePositions.get(node) || new PIXI.Point()
    );
  }

  resetScale() {
    const margin =
      0.1 * this.viewport.getWidth() + 0.1 * this.viewport.getHeight();
    // Reset scale
    this.minScaleX = Math.min(
      1.5,
      (this.viewport.getWidth() -
        margin * Math.min(1.5, this.viewport.getWidth() / 400)) /
        (this.yearRange * this.yearWidth)
    );
    this.minScaleY = Math.min(
      1.5,
      (this.viewport.getHeight() - margin) /
        (Math.min(this.maxNodesPerYear, this.nodesPerColumn) * this.nodeHeight)
    );
    const resetScale =
      Math.max(
        window.innerWidth < 500 ? 0.065 : 0.0001,
        Math.min(0.6, Math.min(this.minScaleX, this.minScaleY))
      ) / getPixelDeviceRatio();
    this.viewport.reset(resetScale, new PIXI.Point(0, 0));
  }

  addForceTimeline() {
    this.simulation.force(FORCE_TIMELINE, (alpha: number) => {
      if (!this.active) {
        return;
      }
      // Node positions
      this.nodes.forEach((node, i) => {
        node.x += alpha * (this.nodePositions[i].x - node.x);
        node.y += alpha * (this.nodePositions[i].y - node.y);
      });
    });
  }

  clearLines() {
    this.linesContainer.removeChildren();
  }

  updateLines() {
    this.clearLines();

    const halfYearRange = this.yearRange / 2;
    for (
      let year = this.yearExtent[0];
      year <= this.yearExtent[1] + 1;
      year++
    ) {
      const line = new PIXI.Sprite(PIXI.Texture.WHITE);
      line.anchor.set(0.5);
      line.alpha = 0.2;
      line.scale.y =
        ((Math.min(this.maxNodesPerYear, this.nodesPerColumn) + 0.4) *
          this.nodeHeight) /
        16;
      line.scale.x = 2;
      line.position.y = 0;
      line.position.x =
        (year - this.yearExtent[0] - halfYearRange) * this.yearWidth;
      this.linesContainer.addChild(line);
      this.lines.push(line);
    }
  }

  clearYears() {
    this.yearsContainer.removeChildren();
  }

  updateYears() {
    this.clearYears();

    const halfYearRange = this.yearRange / 2;

    const onYearClick = (year: number) => (e: PIXI.InteractionEvent) => {
      const isOtherMouseButton =
        e.data.pointerType === 'mouse' && e.data.button > 0;
      const isMultiTouch =
        (e.data.originalEvent as TouchEvent).touches?.length > 1;
      if (isOtherMouseButton || isMultiTouch) {
        return;
      }
      toggleFilter('years', year.toString(), true);
    };

    if (!PIXI.BitmapFont.available[FONT_NAME]) {
      return;
    }

    for (let year = this.yearExtent[0]; year <= this.yearExtent[1]; year++) {
      const text = new PIXI.BitmapText(year.toString(), {
        fontName: FONT_NAME,
      });
      text.scale.set(0);
      text.alpha = 1;
      text.anchor.set(0.5);
      text.position.x =
        30 + (year - this.yearExtent[0] - halfYearRange + 0.5) * this.yearWidth;
      text.position.y =
        80 +
        (Math.min(this.maxNodesPerYear, this.nodesPerColumn) + 0.4) *
          this.nodeHeight *
          0.5;
      this.yearsContainer.addChild(text);
      this.years.push(text);

      // interaction
      text.interactive = true;
      text.buttonMode = true;
      text.on('click', onYearClick(year));
      text.on('touchend', onYearClick(year));
    }
  }
}
