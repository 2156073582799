import * as d3 from 'd3';
import SimulationNode from './SimulationNode';

export enum FORCES {
  FORCE_COLLIDE = 'FORCE_COLLIDE',
  FORCE_CENTER_GRAVITY = 'FORCE_CENTER_GRAVITY',
  FORCE_CENTER = 'FORCE_CENTER',
}

const NODE_SIZE = 210;

export default class BaseLayout {
  protected baseAlpha = 0.4;
  protected active = false;
  protected simulation: d3.Simulation<SimulationNode, undefined>;
  protected nodes: SimulationNode[] = [];
  protected tickCallbacks: (() => void)[] = [];
  protected allNodes = false;

  constructor() {
    this.simulation = this.createSimulation();
    this.addForceCenter();
    this.addForceCollide();
  }

  // Create simulation
  createSimulation() {
    const simulation = d3
      .forceSimulation(this.nodes)
      .alphaDecay(0.005)
      .alpha(this.baseAlpha)
      .on('tick', () => {
        this.tickCallbacks.forEach((callback) => {
          callback();
        });
      });

    this.addTickCallback(() => {
      this.syncNodePositions();
    });

    // pause by default
    simulation.stop();
    return simulation;
  }

  addTickCallback(callback: () => void) {
    this.tickCallbacks.push(callback);
  }

  // Add force collide
  addForceCollide() {
    const forceCollide = d3
      .forceCollide()
      .radius(
        (d) =>
          NODE_SIZE *
          ((d as SimulationNode).targetNode.episode?.getScale() || 1)
      );
    this.simulation.force(FORCES.FORCE_COLLIDE, forceCollide);

    this.addTickCallback(() => {
      // Stronger near end of simulation
      forceCollide.strength(1 - this.simulation.alpha());
    });
  }

  removeForceCollide() {
    this.simulation.force(FORCES.FORCE_COLLIDE, null);
  }

  addForceCenterGravity() {
    this.simulation.force(FORCES.FORCE_CENTER_GRAVITY, (alpha) => {
      const factor = this.nodes.length * 5;
      this.nodes.forEach((node) => {
        node.vx += (-node.x / factor) * alpha * 3;
        node.vy += (-node.y / factor) * alpha * 3;
      });
    });
  }

  removeForceCenterGravity() {
    this.simulation.force(FORCES.FORCE_CENTER_GRAVITY, null);
  }

  addForceCenter() {
    this.simulation.force(FORCES.FORCE_CENTER, d3.forceCenter());
  }

  removeForceCenter() {
    this.simulation.force(FORCES.FORCE_CENTER, null);
  }

  updateNodes(nodes: SimulationNode[], allNodes = false) {
    this.nodes = nodes;
    this.allNodes = allNodes;
    this.simulation.nodes(nodes);
    this.onNodesUpdate(allNodes);
    this.restart();
  }

  // Override to add extra node actions
  onNodesUpdate(allNodes: boolean) {}

  syncNodePositions() {
    this.nodes.forEach((node) => {
      node.syncPosition();
    });
  }

  restart() {
    this.simulation.alpha(this.baseAlpha).restart();
  }

  stop() {
    this.simulation.stop();
  }

  destroy() {
    this.stop();
    this.active = false;
  }
}
