import { FunctionComponent, useCallback } from 'react';
import './EpisodeHeader.scss';
import classnames from 'clsx';
import EpisodeDetails from 'models/entities/EpisodeDetails';
import { patchEpisode, patchFilter, toggleFilter } from 'events/navigation';
import { isMobile, isTablet } from 'hooks/useDevice';
import { EntitiesKeys } from 'models/Entities';

interface Props {
  episode: EpisodeDetails;
  ready: boolean;
}

// EpisodeHeader shows the episode details header
const EpisodeHeader: FunctionComponent<Props> = ({ episode, ready }) => {
  const onBack = useCallback(() => {
    patchEpisode('');
  }, []);

  return (
    <div
      className={classnames(
        'EpisodeHeader',
        'theme-' + (episode.getTheme()?.id || ''),
        { loading: !ready }
      )}
    >
      <div className="left">
        <div className="back" onClick={onBack} />
        <h2>{episode.name}</h2>
      </div>
      <div className="details">
        {/* Theme */}
        <div
          onClick={(e) => {
            onFilterClick(
              'themes',
              episode.getTheme()?.id || '',
              e && (e.altKey || e.shiftKey || e.ctrlKey)
            );
          }}
        >
          <h5>thema</h5>
          <h4>{episode.getTheme()?.name || '-'}</h4>
        </div>
        {/* Year */}
        <div
          onClick={(e) => {
            onFilterClick(
              'years',
              episode.year.toString(),
              e && (e.altKey || e.shiftKey || e.ctrlKey)
            );
          }}
        >
          <h5>uitzenddatum</h5>
          <h4>{episode.date}</h4>
        </div>
      </div>
    </div>
  );
};

const onFilterClick = (key: string, value: string, alt: boolean) => {
  if (alt) {
    patchFilter(
      JSON.stringify({
        [key]: [value],
      }),
      true
    );
  } else {
    const resetEpisode = isMobile() || isTablet() || window.innerWidth < 1100;
    toggleFilter(key as keyof EntitiesKeys, value, resetEpisode);
  }
};

export default EpisodeHeader;
