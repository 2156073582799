import { useMemo } from 'react';

import SearchQuery from 'models/SearchQuery';
import SearchQueryMapper from 'mappers/SearchQueryMapper';

type Hook = (props: { query: string }) => SearchQuery;

// useSearchQuery creates a SearchQuery from the given string
const useSearchQuery: Hook = ({ query }) => {
  // Update search object if query.search string changes
  const search = useMemo(() => {
    const search = SearchQueryMapper.fromJSONString(query);
    return search;
  }, [query]);

  return search;
};

export default useSearchQuery;
