import { EntityType } from 'models/Entities';

export default class Theme {
  type: string = EntityType.THEME;
  id: string = '';
  name: string = '';
  longName: string = '';
  description: string = '';
  subjects: string = '';
  imageUrl: string = '';
  thumbnailUrl: string = '';
}
