export const createSeededRandom = (seed: number) => {
  var mask = 0xffffffff;
  var m_w = (123456789 + seed) & mask;
  var m_z = (987654321 - seed) & mask;

  return () => {
    m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
    m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;

    var result = ((m_z << 16) + (m_w & 65535)) >>> 0;
    result /= 4294967296;
    return result;
  };
};

export interface Randomizer {
  reset: () => void;
  get: () => number;
}

export class MathRandom implements Randomizer {
  reset() {}
  get() {
    return Math.random();
  }
}

export class SeededRandom implements Randomizer {
  seed: number = 0;
  mask = 0xffffffff;
  m_z: number = 0;
  m_w: number = 0;

  constructor(seed: number) {
    this.seed = seed;
    this.reset();
  }

  reset() {
    this.m_w = (123456789 + this.seed) & this.mask;
    this.m_z = (987654321 - this.seed) & this.mask;
  }

  get() {
    this.m_z = (36969 * (this.m_z & 65535) + (this.m_z >>> 16)) & this.mask;
    this.m_w = (18000 * (this.m_w & 65535) + (this.m_w >>> 16)) & this.mask;

    let result = ((this.m_z << 16) + (this.m_w & 65535)) >>> 0;
    return result / 4294967296;
  }
}
