import { FunctionComponent, useCallback } from 'react';
import './EpisodeAnnotations.scss';
import Annotations, { AnnotationsKeys } from 'models/Annotations';
import Annotation from 'models/Annotation';
import classnames from 'clsx';
import SearchQuery from 'models/SearchQuery';
import { patchSegment } from 'events/navigation';
import Tooltip from 'components/Tooltip/ToolTip';
import useDevice from 'hooks/useDevice';

interface Props {
  annotations: Annotations | null;
  activeAnnotations: Annotations | null;
}

// EpisodeAnnotations shows the episode annotations
const EpisodeAnnotations: FunctionComponent<Props> = ({
  annotations,
  activeAnnotations,
}) => {
  return (
    <div
      className={classnames('EpisodeAnnotations', {
        active: annotations,
      })}
    >
      {annotations ? (
        <>
          <AnnotationList
            title="spraak"
            type="speech"
            annotations={annotations}
            activeAnnotations={activeAnnotations}
          />
          <AnnotationList
            title="beeld"
            type="image"
            annotations={annotations}
            activeAnnotations={activeAnnotations}
          />
        </>
      ) : null}
    </div>
  );
};

interface AnnotationListProps {
  title: string;
  type: keyof AnnotationsKeys;
  annotations: Annotations;
  activeAnnotations: Annotations | null;
}

const AnnotationList = ({
  annotations,
  type,
  title,
  activeAnnotations,
}: AnnotationListProps) => {
  const { IS_MOBILE } = useDevice();

  const onAnnotationClick = useCallback(
    (annotation: Annotation) => {
      const annotationQuery = new SearchQuery();
      const queryString =
        annotation.label.indexOf(' ') > -1
          ? `"${annotation.label}"`
          : annotation.label;
      annotationQuery.annotation = queryString;
      switch (type) {
        case 'speech':
          annotationQuery.annotationSpeech = true;
          annotationQuery.annotationImage = false;
          break;
        case 'image':
          annotationQuery.annotationSpeech = false;
          annotationQuery.annotationImage = true;
          break;
        default:
          console.error('Invalid annotation type', type);
      }
      patchSegment(annotationQuery);
    },
    [type]
  );
  return (
    <div
      className={classnames({
        empty: annotations[type].length === 0,
      })}
    >
      <b>{title}</b>
      <i />
      <div>
        {getUniqueAnnotations(annotations[type]).map((annotation) => {
          if (annotation.confidence < 0.2) {
            return undefined;
          }

          const label = (
            <span
              className={classnames({
                active:
                  activeAnnotations &&
                  activeAnnotations[type].includes(annotation),
              })}
              style={{ opacity: annotation.confidence }}
              key={annotation.label}
              onClick={onAnnotationClick.bind(this, annotation)}
              draggable={!IS_MOBILE}
              onDragStart={
                !IS_MOBILE
                  ? (e) => {
                      e.dataTransfer.setData('text', annotation.label);
                      e.dataTransfer.setData('target', type);
                    }
                  : undefined
              }
            >
              {annotation.label}
            </span>
          );
          return annotation.category ? (
            <Tooltip
              key={annotation.label}
              title={annotation.category.replace(/^TL_/, '')}
            >
              {label}
            </Tooltip>
          ) : (
            label
          );
        })}
      </div>
    </div>
  );
};

const getUniqueAnnotations = (annotations: Annotation[]) => {
  const annotationsByLabel = new Map<string, Annotation>();
  annotations.forEach((annotation) => {
    const label = annotation.label.toLowerCase();
    if (
      !annotationsByLabel.has(label) ||
      (annotationsByLabel.get(label) as Annotation).confidence <
        annotation.confidence
    ) {
      annotationsByLabel.set(label, annotation);
    }
  });
  return Array.from(annotationsByLabel.values());
};

export default EpisodeAnnotations;
