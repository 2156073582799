import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import './EpisodeAnnotationSearch.scss';
import SearchQuery from 'models/SearchQuery';
import { debounce } from 'debounce';
import { patchInfo, patchSearch, patchSegment } from 'events/navigation';
import Input, { changeInputValue } from 'components/Input/Input';
import ToolTip from 'components/Tooltip/ToolTip';
import { Info } from 'models/Info';
import { AnnotationType } from 'models/Annotation';

interface Props {
  segmentQuery: SearchQuery;
}

const debouncedPatchSegment = debounce(patchSegment, 600);

// EpisodeAnnotationSearch handles the episode annotation search
const EpisodeAnnotationSearch: FunctionComponent<Props> = ({
  segmentQuery,
  //setSearchQuery,
}) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChangeForm = useCallback(() => {
    if (!formRef.current) {
      return;
    }
    const annotationQuery = new SearchQuery();
    annotationQuery.annotation = (
      formRef.current.elements.namedItem('annotation') as HTMLInputElement
    ).value;
    annotationQuery.annotationSpeech = (
      formRef.current.elements.namedItem(
        'annotation-speech'
      ) as HTMLInputElement
    ).checked;
    annotationQuery.annotationImage = (
      formRef.current.elements.namedItem('annotation-image') as HTMLInputElement
    ).checked;
    debouncedPatchSegment(annotationQuery);
  }, []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    debouncedPatchSegment.flush();
    return false;
  }, []);

  // Update formRef inputs with segmentQuery data
  useEffect(() => {
    if (!formRef.current || !inputRef.current) {
      return;
    }

    // Annotation Speech
    (
      formRef.current.elements.namedItem(
        'annotation-speech'
      ) as HTMLInputElement
    ).checked = segmentQuery.annotationSpeech;

    // Annotation Image
    (
      formRef.current.elements.namedItem('annotation-image') as HTMLInputElement
    ).checked = segmentQuery.annotationImage;

    // Input
    changeInputValue(inputRef.current, segmentQuery.annotation);
  }, [segmentQuery]);

  const onSearchEpisodes = useCallback(() => {
    patchSearch(segmentQuery);
  }, [segmentQuery]);

  const onInputDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    if (!inputRef.current || !formRef.current) {
      return;
    }

    e.preventDefault();

    try {
      const text = e.dataTransfer.getData('text');
      const target = e.dataTransfer.getData('target');

      // Prevent accidental text select/drop from input
      if (inputRef.current.value === text) {
        return;
      }

      if (target) {
        // Annotation Speech
        (
          formRef.current.elements.namedItem(
            'annotation-speech'
          ) as HTMLInputElement
        ).checked = target === AnnotationType.SPEECH;

        // Annotation Image
        (
          formRef.current.elements.namedItem(
            'annotation-image'
          ) as HTMLInputElement
        ).checked = target === AnnotationType.IMAGE;
      }

      // Input value
      changeInputValue(
        inputRef.current,
        text.indexOf(' ') > -1 ? `"${text}"` : text
      );
    } catch (e) {}
  }, []);

  const onInputDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  return (
    <div className="EpisodeAnnotationSearch">
      <form ref={formRef} onSubmit={onSubmit}>
        <div onDrop={onInputDrop} onDragOver={onInputDragOver}>
          <Input
            name="annotation"
            defaultValue={segmentQuery.annotation}
            placeholder="zoek binnen deze aflevering"
            onChange={onChangeForm}
            ref={inputRef}
          />
        </div>
        <span className="in">in</span>
        <div className="annotation-type">
          <label>
            <input
              type="checkbox"
              name="annotation-speech"
              defaultChecked={segmentQuery.annotationSpeech}
              onChange={onChangeForm}
            />
            spraak
          </label>
          <ToolTip
            title="Let op: AI beeldherkenning kent nog grote foutmarges"
            style={{ width: 250 }}
          >
            <label>
              <input
                type="checkbox"
                name="annotation-image"
                defaultChecked={segmentQuery.annotationImage}
                onChange={onChangeForm}
              />
              beeld
            </label>
          </ToolTip>
        </div>

        <ToolTip
          title="Meer informatie over de gebruikte algoritmes"
          style={{ width: 310 }}
        >
          <div
            className="info-button"
            onClick={() => {
              patchInfo(Info.DATA);
            }}
          ></div>
        </ToolTip>
      </form>

      {!segmentQuery.isAnnotationEmpty() && (
        <div className="search-episodes" onClick={onSearchEpisodes}>
          zoek in andere afleveringen
        </div>
      )}
    </div>
  );
};

export default EpisodeAnnotationSearch;
