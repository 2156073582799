import { useEffect, useState } from 'react';
import { showError } from 'hooks/useNotifications';

import Entities from 'models/Entities';
import Env from 'config/Env';
import EntitiesMapper from 'mappers/EntitiesMapper';

type Hook = () => [Entities, boolean];

// useEntities loads the entities from the api
const useEntities: Hook = () => {
  const [ready, setReady] = useState(false);
  const [entities, setEntities] = useState<Entities>(new Entities());

  // Load entities once
  useEffect(() => {
    const controller = new AbortController();

    const loadEntities = async () => {
      const url = Env.dataHost + 'entities.json';
      try {
        const response = await fetch(url, { signal: controller.signal });
        const json = await response.json();
        if (json && typeof json == 'object') {
          setEntities(EntitiesMapper.entities(json));
          setReady(true);
        } else {
          showError('Entities not found');
        }
      } catch (err) {
        console.debug(err);
        showError('Could not load entities');
      }
    };

    loadEntities();
    return () => {
      controller.abort();
    };
  }, []);

  return [entities, ready];
};

export default useEntities;
