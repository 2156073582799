import * as PIXI from 'pixi.js';
import { useEffect, useState } from 'react';
import Episode from 'models/entities/Episode';
import Env from 'config/Env';
import {
  TILE_ARROW,
  TILES_PER_SPRITESHEET_128,
  TILE_PLACEHOLDER_IMAGE,
} from 'config/constants';

type Hook = (episodes: Episode[]) => boolean;

// useAssetsLoader preloads all (visual) assets
const useAssetsLoader: Hook = (episodes) => {
  const [ready, setReady] = useState(false);

  // Load PIXI assets
  useEffect(() => {
    const loader = PIXI.Loader.shared;

    // Load episodes spritesheets
    const load = async () => {
      // Require episodes
      if (episodes.length === 0) {
        return;
      }
      // Load placeholder image
      !loader.resources[TILE_PLACEHOLDER_IMAGE] &&
        loader.add(TILE_PLACEHOLDER_IMAGE);

      // Load spritesheets 128px on bigger devices (better quality)
      const sheets = Math.ceil(episodes.length / TILES_PER_SPRITESHEET_128);
      for (let sheet = 1; sheet <= sheets; sheet++) {
        const url = Env.tilesHost + '128/spritesheet_' + sheet + '.json';
        const name = 'episodes_' + sheet;
        !loader.resources[name] && loader.add({ name, url });
      }
      loader.load();
    };

    // Load other textures
    [TILE_ARROW].forEach((url) => {
      !loader.resources[url] && loader.add(url);
    });

    // Complete listener
    const completeListener = loader.onComplete.add(() => {
      setReady(true);
    });

    // Error listener
    const errorListener = loader.onError.add((e) => {});

    load();
    return () => {
      loader.onComplete.detach(completeListener);
      loader.onError.detach(errorListener);
    };
  }, [episodes]);

  return ready;
};

export default useAssetsLoader;
