import { useEffect, useState } from 'react';

type Hook = (imageUrl: string) => boolean;

const useImageReady: Hook = (imageUrl) => {
  const [ready, setReady] = useState(false);
  // Preload image
  useEffect(() => {
    setReady(false);

    // Load the image
    const image = new Image();
    image.onload = () => {
      setReady(true);
    };
    image.src = imageUrl;
    return () => {
      image.onload = null;
    };
  }, [imageUrl]);

  return ready;
};

export default useImageReady;
