import Viewport from 'models/visual/Viewport';
import { useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';

type Hook = (props: { home: boolean; viewport: Viewport | null }) => void;

// useHomeReset performs actions based on home state
const useHomeReset: Hook = ({ home, viewport }) => {
  const lastHome = useRef(false);

  // Update
  useEffect(() => {
    if (!viewport) {
      return;
    }
    if (lastHome.current !== home) {
      lastHome.current = home;
      if (home) {
        viewport.focusTo(new PIXI.Point(0, 0), viewport.minScale);
      }
    }
  }, [home, viewport]);
};

export default useHomeReset;
