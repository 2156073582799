import { useEffect, useRef, useState } from 'react';

import VideoSegment from 'models/VideoSegment';

type Hook = (props: {
  time: number | null;
  videoSegments: VideoSegment[];
  activeVideoSegments: VideoSegment[] | null;
}) => number | null;

// useActiveSegment returns the active videosegment index, based on the given time
const useActiveSegment: Hook = ({
  time,
  videoSegments,
  activeVideoSegments,
}) => {
  const lastIndex = useRef(-1);
  const [index, setIndex] = useState<number | null>(null);

  // set active segment
  useEffect(() => {
    if (time === null) {
      return;
    }

    const index = videoSegments.findIndex(
      (videoSegment) => videoSegment.start <= time && videoSegment.end > time
    );

    if (lastIndex.current === index) {
      return;
    }
    lastIndex.current = index;

    setIndex(index);

    return () => {
      setIndex(null);
    };
  }, [time, videoSegments, activeVideoSegments]);

  return index;
};

export default useActiveSegment;
