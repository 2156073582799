import './InfoPage.scss';

import { FunctionComponent, useEffect, useRef } from 'react';
import classnames from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';
import TabAbout from './TabAbout';
import TabThemes from './TabThemes';
import TabData from './TabData';
import TabCredits from './TabCredits';
import { STORE_INFO_TAB } from 'config/constants';
import { Info } from 'models/Info';
import { patchInfo } from 'events/navigation';

interface Props {
  tab: Info;
  onClose: () => void;
}

// Info shows the information page
const InfoPage: FunctionComponent<Props> = ({ tab, onClose }) => {
  const scrollRef = useRef<Scrollbars>(null);

  useEffect(() => {
    // Scroll to top on new tab
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }

    // Store tab in session storage
    window.sessionStorage.setItem(STORE_INFO_TAB, tab);
  }, [tab]);

  return (
    <div className="Info">
      <div className="tabs">
        <TabButton
          onClick={() => {
            patchInfo(Info.ABOUT);
          }}
          title="informatie"
          active={Info.ABOUT === tab}
        />
        <TabButton
          onClick={() => {
            patchInfo(Info.THEME);
          }}
          title="thema's"
          active={Info.THEME === tab}
        />
        <TabButton
          onClick={() => {
            patchInfo(Info.DATA);
          }}
          title="data"
          active={Info.DATA === tab}
        />
        <TabButton
          onClick={() => {
            patchInfo(Info.CREDITS);
          }}
          title="credits"
          active={Info.CREDITS === tab}
        />
      </div>

      <Scrollbars
        ref={scrollRef}
        className="Scrollbar"
        style={{
          width: '100%',
          height: 'calc(100% - 47px)',
        }}
      >
        {Info.ABOUT === tab && <TabAbout />}
        {Info.THEME === tab && <TabThemes onClose={onClose} />}
        {Info.DATA === tab && <TabData />}
        {Info.CREDITS === tab && <TabCredits />}
      </Scrollbars>
    </div>
  );
};

interface TabButtonProps {
  onClick: () => void;
  title: string;
  active: boolean;
}

const TabButton = ({ onClick, title, active }: TabButtonProps) => {
  return (
    <div className={classnames('tab', { active })} onClick={onClick}>
      {title}
    </div>
  );
};

export default InfoPage;
