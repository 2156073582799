import { useEffect } from 'react';
import Query, { PartialQuery } from 'models/Query';
import { EVENT_NAVIGATE_REPLACE, EVENT_NAVIGATE_PATCH } from 'config/constants';
import { useNavigate } from 'react-router';

type Hook = (query: Query) => void;

// useNav handles browser navigation
const useNav: Hook = (query) => {
  const navigate = useNavigate();

  // Navigation listener
  useEffect(() => {
    const { layout, layoutParam, search, filter, episode, segment, mode } =
      query;

    // Replace full query
    const replaceQuery = ((e: CustomEvent<{ query: Query | null }>) => {
      const newQuery = e.detail.query;
      if (!newQuery) {
        return;
      }

      // Check for changes
      const queryChanged = Object.keys(query).some(
        (key) =>
          (newQuery[key as keyof Query] as string) !==
          (query[key as keyof Query] as string)
      );
      if (!queryChanged) {
        return;
      }

      // Create query params
      const queryParams: PartialQuery = {
        layout: newQuery.layout,
        layoutParam: newQuery.layoutParam,
        search: newQuery.search,
        filter: newQuery.filter,
        episode: newQuery.episode,
        mode: newQuery.mode,
        segment: newQuery.segment,
      };

      // Remove empty keys
      Object.keys(queryParams).forEach((key) => {
        if (queryParams[key as keyof Query] === '') {
          delete queryParams[key as keyof Query];
        }
      });

      const params = new URLSearchParams(queryParams);
      navigate('/browse?' + params.toString());
    }) as EventListener;

    // Update query
    const patchQuery = ((e: CustomEvent<{ query: PartialQuery | null }>) => {
      const newQuery = e.detail.query;
      if (!newQuery) {
        return;
      }

      // Create url query
      const current = {
        layout,
        layoutParam,
        search,
        filter,
        episode,
        mode,
        segment,
      };

      // Check for changes
      const queryChanged = Object.keys(newQuery).some(
        (key) =>
          (query[key as keyof Query] as string) !==
          (newQuery[key as keyof Query] as string)
      );
      if (!queryChanged) {
        return;
      }

      const queryParams: PartialQuery = Object.assign(current, newQuery);

      // Remove empty keys
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key as keyof Query]) {
          delete queryParams[key as keyof Query];
        }
      });

      const params = new URLSearchParams(queryParams);

      navigate('/browse?' + params.toString());
    }) as EventListener;
    window.addEventListener(EVENT_NAVIGATE_REPLACE, replaceQuery);
    window.addEventListener(EVENT_NAVIGATE_PATCH, patchQuery);

    return () => {
      window.removeEventListener(EVENT_NAVIGATE_REPLACE, replaceQuery);
      window.removeEventListener(EVENT_NAVIGATE_PATCH, patchQuery);
    };
  }, [navigate, query]);
};

export default useNav;
