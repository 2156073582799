import { useEffect } from 'react';

import Query, { Layout } from 'models/Query';
import Store from 'models/Store';
import { DistanceKey } from 'models/entities/Episode';
import { parseDistanceKey } from 'util/enumParser';
import { calculateEpisodeDistances } from 'util/distance';

type Hook = (props: { query: Query; store: Store }) => void;

// useDistances makes sure that required episode distances are available to the clusterlayout
const useDistances: Hook = ({ query, store }) => {
  // Calculate missing distances for DistanceKeys in cluster layout
  useEffect(() => {
    // Require a store and cluster view
    const validStore = store && store.entities.episodes.length > 0;
    const validLayout = query.layout === Layout.EPISODE_CLUSTER;
    if (!validStore || !validLayout) {
      return;
    }

    // Calculate
    const key = parseDistanceKey(query.layoutParam, DistanceKey.THEME);
    const distancesCalculated = key in store.entities.episodes[0].distances;
    if (!distancesCalculated) {
      calculateEpisodeDistances(store.entities.episodes, key);
    }
  }, [store, query.layout, query.layoutParam]);
};

export default useDistances;
