import { useEffect, useState } from 'react';

import Env from 'config/Env';
import AnnotationMapper from 'mappers/AnnotationMapper';
import Annotation, { AnnotationType } from 'models/Annotation';

type Hook = (props: {
  annotationType: AnnotationType;
  episodeUID: string;
}) => [Annotation[], boolean];

// useAnnotation loads the episode annotations
const useAnnotation: Hook = ({ annotationType, episodeUID }) => {
  const [ready, setReady] = useState(false);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);

  // Load annotations
  useEffect(() => {
    const controller = new AbortController();

    const loadAnnotations = async () => {
      const url = Env.annotationsHost + `${annotationType}/${episodeUID}.json`;
      try {
        const response = await fetch(url, { signal: controller.signal });
        const json = await response.json();
        if (json && typeof json == 'object') {
          setAnnotations(AnnotationMapper.annotations(json));
        } else {
          console.error('Annotations not found, type', annotationType);
        }
      } catch (err) {
        console.debug(err);
        console.error('Could not load annotations');
        if (!controller.signal.aborted) {
          setReady(true);
        }
      }
      setReady(true);
    };
    // reset
    setReady(false);
    setAnnotations([]);

    loadAnnotations();

    return () => {
      controller.abort();
    };
  }, [annotationType, episodeUID]);

  return [annotations, ready];
};

export default useAnnotation;
