import { showError } from 'hooks/useNotifications';
import SearchQuery from 'models/SearchQuery';

// SearchQueryMapper contains mappers that create Search instances
export default class SearchQueryMapper {
  // fromString maps a json string to a Search instance
  static fromJSONString(json: string): SearchQuery {
    if (!json) {
      return new SearchQuery();
    }

    try {
      const obj = JSON.parse(json);
      return SearchQueryMapper.fromObject(obj);
    } catch (e) {
      console.error(e);
      showError('error_search_json_invalid');
    }
    return new SearchQuery();
  }

  // fromObject maps an object to a Search instance
  static fromObject(obj: Partial<SearchQuery>): SearchQuery {
    const search = new SearchQuery();

    search.all = typeof obj.all === 'string' ? obj.all : search.all;

    search.episode =
      typeof obj.episode === 'string' ? obj.episode : search.episode;

    search.annotation =
      typeof obj.annotation === 'string' ? obj.annotation : search.annotation;

    search.annotationSpeech =
      typeof obj.annotationSpeech === 'boolean'
        ? obj.annotationSpeech
        : search.annotationSpeech;

    search.annotationImage =
      typeof obj.annotationImage === 'boolean'
        ? obj.annotationImage
        : search.annotationImage;

    return search;
  }
}
