import { FunctionComponent, CSSProperties } from 'react';
import './ToolTipContent.scss';

interface Props {
  title: string;
  className?: string;
  style?: CSSProperties;
}
// ToolTipContent holds the tooltip content
const ToolTipContent: FunctionComponent<Props> = ({
  title,
  className = '',
  style,
  children,
}) => {
  return (
    <div className={'ToolTipContent ' + className} style={style}>
      {title}
      {children}
    </div>
  );
};

export default ToolTipContent;
