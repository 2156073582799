import { FunctionComponent } from 'react';
import './EpisodeTimeline.scss';
import classnames from 'clsx';
import EpisodeDetails from 'models/entities/EpisodeDetails';
import useVideoSegments from 'hooks/useVideoSegments';
import Timeline from 'components/Timeline/Timeline';
import VimeoPlayer from 'models/VimeoPlayer';
import SearchQuery from 'models/SearchQuery';
import useAnnotations from 'hooks/useAnnotations';
import useActiveSegment from 'hooks/useActiveSegment';
import EpisodeAnnotations from './EpisodeAnnotations';
import useActiveAnnotations from 'hooks/useActiveAnnotations';
import usePlayerTime from 'hooks/usePlayerTime';
import useActiveAnnotationsForSegment from 'hooks/useActiveAnnotationsForSegment';
import EpisodeAnnotationSearch from './EpisodeAnnotationSearch';
import useActiveVideoSegments from 'hooks/useActiveVideoSegments';

interface Props {
  episode: EpisodeDetails;
  segmentQuery: SearchQuery;
  ready: boolean;
}

// EpisodeTimeline is the root component for episode timeline and episode annotations related components
const EpisodeTimeline: FunctionComponent<Props> = ({
  episode,
  segmentQuery,
  ready,
}) => {
  const [videoSegments, videoSegmentsReady] = useVideoSegments({
    episodeUID: episode.uid,
  });

  const annotations = useAnnotations({
    episodeUID: episode.uid,
  });

  const time = usePlayerTime();

  const activeVideoSegments = useActiveVideoSegments({
    episodeUID: episode.uid,
    segmentQuery: segmentQuery,
    videoSegments,
    doUpdate: ready,
  });

  const activeSegmentIndex = useActiveSegment({
    time,
    videoSegments,
    activeVideoSegments,
  });

  const videoSegment =
    activeSegmentIndex === null ? null : videoSegments[activeSegmentIndex];

  const activeAnnotationsForSegment = useActiveAnnotationsForSegment({
    annotations,
    videoSegment,
  });

  const activeAnnotations = useActiveAnnotations({
    annotations: activeAnnotationsForSegment,
    time,
  });

  return (
    <div
      className={classnames('EpisodeTimeline', {
        loading: !videoSegmentsReady,
      })}
    >
      <Timeline
        key={episode.uid}
        segments={videoSegments}
        activeIndex={activeSegmentIndex}
        activeSegments={
          activeVideoSegments === null ? videoSegments : activeVideoSegments
        }
        episodeUID={episode.uid}
        playFrom={VimeoPlayer.playFrom}
      />

      <EpisodeAnnotations
        annotations={activeAnnotationsForSegment}
        activeAnnotations={activeAnnotations}
      />

      <EpisodeAnnotationSearch segmentQuery={segmentQuery} />
    </div>
  );
};

export default EpisodeTimeline;
