import { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import Query from 'models/Query';
import { isMobile, isTablet } from 'hooks/useDevice';

type Hook = (props: { app: PIXI.Application | null; query: Query }) => void;

// usePauseVisual pauses the visual on certain query states
const usePauseVisual: Hook = ({ app, query }) => {
  // Pause app on mobile devices when episode details are shown
  useEffect(() => {
    if (!app || app.stage === null) {
      return;
    }

    if (isTablet() || isMobile()) {
      if (query.episode && PIXI.Ticker.shared.started) {
        PIXI.Ticker.shared.stop();
        app.ticker.stop();
        app.stop();
      }

      if (!query.episode && !PIXI.Ticker.shared.started) {
        PIXI.Ticker.shared.start();
        app.ticker.start();
        app.start();
      }
    }
  }, [app, query.episode]);
};

export default usePauseVisual;
