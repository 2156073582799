import { FunctionComponent } from 'react';
import './NoResults.scss';

interface Props {}

// NoResults shows the no results message
const NoResults: FunctionComponent<Props> = () => {
  return (
    <div className="NoResults">
      <h4>Geen afleveringen gevonden</h4>
    </div>
  );
};

export default NoResults;
