import { FunctionComponent } from 'react';
import './Browser.scss';
import Visualization from 'components/Visualization/Visualization';
import Query from 'models/Query';
import Store from 'models/Store';
import Wave from 'components/Wave/Wave';
import Background from 'components/Background/Background';
import Result from 'models/Result';
import EpisodeView from 'components/EpisodeView/EpisodeView';
import Filter from 'models/Filter';
import ActiveFilters from 'components/ActiveFilters/ActiveFilters';
import Themes from 'components/Themes/Themes';
import Layouts from 'components/Layouts/Layouts';

import NoResults from 'components/NoResults/NoResults';
import SearchQuery from 'models/SearchQuery';
import Zoom from 'components/Zoom/Zoom';
import NavigationHint from 'components/NavigationHint/NavigationHint';
import useLayoutTranslation from 'hooks/useLayoutTranslation';

interface Props {
  home: boolean;
  ready: boolean;
  store: Store;
  filter: Filter;
  query: Query;
  segmentQuery: SearchQuery;
  result: Result;
  resultAllThemes: Result;
}

// Browser contains all UI components for the browser
const Browser: FunctionComponent<Props> = ({
  home,
  ready,
  store,
  filter,
  query,
  segmentQuery,
  result,
  resultAllThemes,
}) => {
  const layoutsTranslation = useLayoutTranslation(!!query.episode);

  return (
    <div className="Browser">
      <Background backgroundImage={''} />
      <Visualization ready={ready} home={home} result={result} query={query} />
      <Wave position="left" />

      <div className="gradient-overlay" />

      <div
        className="tools-left"
        style={{ maxWidth: `calc(100% - ${layoutsTranslation + 45}px)` }}
      >
        <ActiveFilters
          filter={filter}
          episodeCount={result.episodes.length}
          mode={query.mode}
        />
        {ready && <NavigationHint />}
      </div>

      {ready && (
        <EpisodeView
          episodeId={query.episode}
          mode={query.mode}
          filter={filter}
          segmentQuery={segmentQuery}
        />
      )}
      <Themes store={store} result={resultAllThemes} filter={filter} />

      <div
        className="tools-right"
        style={{ transform: `translateX(-${layoutsTranslation}px)` }}
      >
        <Wave position="right" />
        <Zoom />
        <Layouts
          mode={query.mode}
          layout={query.layout}
          layoutParam={query.layoutParam}
        />
        {ready && result.episodes.length === 0 && <NoResults />}
      </div>
    </div>
  );
};

export default Browser;
