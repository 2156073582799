export enum AnnotationType {
  'SPEECH' = 'speech',
  'IMAGE' = 'image',
}

export type AnnotationAssetArray = [number, number, number, string, string?];

export default class Annotation {
  // time in seconds
  start: number = 0;
  confidence: number = 1;
  label: string = '';
  category: string = '';

  // Duration (updates end)
  private _duration: number = 0;
  set duration(duration: number) {
    this._duration = duration;
    this._end = this.start + duration;
  }

  get duration(): number {
    return this._duration;
  }

  // End (updates duration)
  private _end: number = 0;
  set end(end: number) {
    this._end = end;
    this._duration = end - this.start;
  }

  get end(): number {
    return this._end;
  }
}
