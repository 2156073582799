export interface AdvancedQueryFields {
  and: string;
  or: string;
  not: string;
  exact: string;
}

export default class AdvancedQuery implements AdvancedQueryFields {
  and = '';
  or = '';
  not = '';
  exact = '';

  constructor(fields: Partial<AdvancedQueryFields>) {
    this.and = fields.and || this.and;
    this.or = fields.or || this.or;
    this.not = fields.not || this.not;
    this.exact = fields.exact || this.exact;
  }

  toSearchString() {
    // and
    let q = this.and.trim();

    // or
    if (this.or) {
      q +=
        ' (' +
        this.or
          .trim()
          .replace(/\|/g, ' ')
          .replace(/\s\s/g, ' ')
          .split(' ')
          .filter((v) => v.trim())
          .join(' | ') +
        ')';
    }

    // exact
    if (this.exact) {
      q +=
        ' "' + this.exact.trim().replace(/"/g, ' ').replace(/\s\s/g, ' ') + '"';
    }

    // not
    if (this.not) {
      q +=
        ' ' +
        this.not
          .trim()
          .replace(/^-/g, '')
          .replace(/ -/g, ' ')
          .split(' ')
          .filter((v) => v.trim())
          .map((v) => '-' + v)
          .join('|');
    }

    return q;
  }
}
