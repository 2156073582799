import { useMemo } from 'react';

import SearchQuery from 'models/SearchQuery';
import VideoSegment from 'models/VideoSegment';
import useSegmentSearch from './useSegmentSearch';

type Hook = (props: {
  episodeUID: string;
  videoSegments: VideoSegment[];
  segmentQuery: SearchQuery | null;
  doUpdate: boolean;
}) => VideoSegment[] | null;

// useActiveVideoSegments get active video segments from segmentQuery
const useActiveVideoSegments: Hook = ({
  episodeUID,
  videoSegments,
  segmentQuery,
  doUpdate,
}) => {
  const segmentResult = useSegmentSearch({
    episodeUID,
    segmentQuery,
    doUpdate,
  });

  const activeVideoSegments = useMemo<VideoSegment[] | null>(() => {
    // No result
    if (segmentResult == null) {
      // Make all active
      videoSegments.forEach((a) => {
        a.active = true;
      });
      return videoSegments;
    }

    // Make all inactive
    videoSegments.forEach((a) => {
      a.active = false;
    });

    const activeVideoSegments = videoSegments.filter((_, index) =>
      segmentResult.includes(index)
    );

    activeVideoSegments.forEach((a) => {
      a.active = true;
    });
    return activeVideoSegments;
  }, [videoSegments, segmentResult]);
  return activeVideoSegments;
};

export default useActiveVideoSegments;
