import { EVENT_HIGHLIGHT_ENTITY } from 'config/constants';

export interface EventHighlightEntity {
  ids?: string[];
}

// highlightEntity emits a new highlight entity event
export const highlightEntity = (ids?: string[]) => {
  const event = new CustomEvent<EventHighlightEntity>(EVENT_HIGHLIGHT_ENTITY, {
    detail: { ids },
  } as CustomEventInit);
  window.dispatchEvent(event);
};
