import Entities from './Entities';

export default class Store {
  static _entities: Entities = new Entities();
  entities: Entities = Store._entities;

  constructor(entities?: Entities) {
    this.entities = entities || this.entities;
    Store._entities = this.entities;
  }
}
