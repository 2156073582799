import { useEffect, useMemo } from 'react';

import Query from 'models/Query';
import Filter from 'models/Filter';
import FilterMapper from 'mappers/FilterMapper';
import Store from 'models/Store';

type Hook = (props: { query: Query; store: Store }) => Filter;

// useFilterResult creates a new Result set based on the filtered search results
const useFilter: Hook = ({ query, store }) => {
  const mapper = useMemo(() => {
    return new FilterMapper(store.entities);
  }, [store]);

  // Update filter object if query.filter string changes
  const filter = useMemo(() => {
    const filter = mapper.fromJSONString(query.filter);
    return filter;
  }, [mapper, query.filter]);

  // Reset filter scores
  useEffect(() => {
    // Reset filter scores
    store.entities.episodes.forEach((episode) => {
      // episode.resetScore();
    });
  }, [filter, store.entities.episodes]);

  return filter;
};

export default useFilter;
