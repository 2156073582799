import { useEffect, useState } from 'react';
import { IPAD_SCREEN_WIDTH } from 'config/constants';
import { getEpisodeViewWidth } from 'components/EpisodeView/EpisodeView';
import { debounce } from 'debounce';

type Hook = (hasEpisode: boolean) => number;

// useMode handles toggling between the simple and advanced mode
const useLayoutTranslation: Hook = (hasEpisode) => {
  const [layoutsTranslation, setLayoutsTranslation] = useState(
    getLayoutsTranslation(hasEpisode)
  );

  // Update layout on episode change or window resize
  useEffect(() => {
    const updateTranslation = () => {
      setLayoutsTranslation(getLayoutsTranslation(hasEpisode));
    };

    // Always update on hasEpisode change
    updateTranslation();

    // Window resize
    const debouncedUpdateTranslation = debounce(updateTranslation, 50);
    window.addEventListener('resize', debouncedUpdateTranslation);

    return () => {
      window.removeEventListener('resize', debouncedUpdateTranslation);
    };
  }, [hasEpisode]);

  return layoutsTranslation;
};

const getLayoutsTranslation = (hasEpisode: boolean) =>
  window.innerWidth > IPAD_SCREEN_WIDTH && hasEpisode
    ? getEpisodeViewWidth()
    : 0;

export default useLayoutTranslation;
