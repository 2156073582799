import { EVENT_VIEWPORT_ZOOM } from 'config/constants';

export interface EventViewportZoom {
  amount: number;
  // - Positive value: multiplier of target scale
  // - Negative valid: absolute value
}

// viewportZoom emits a new viewport-zoom event
export const viewportZoom = (amount: number) => {
  const event = new CustomEvent<EventViewportZoom>(EVENT_VIEWPORT_ZOOM, {
    detail: { amount },
  } as CustomEventInit);
  window.dispatchEvent(event);
};
