import './Suggestions.scss';

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useSuggestions from 'hooks/useSuggestions';
import classnames from 'clsx';
import Suggestion, { SuggestionTypeOptions } from 'models/Suggestion';
import { patchQuery, patchSearch, toggleFilter } from 'events/navigation';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { EntitiesKeys } from 'models/Entities';
import ToolTip from 'components/Tooltip/ToolTip';
import Store from 'models/Store';
import clsx from 'clsx';
import { translateEntityType } from 'util/translate';

interface Props {
  searchString: string;
  showToggle: boolean;
  onClose: (searchString?: string) => void;
}

// Suggestions shows a list of search suggestions
const Suggestions: FunctionComponent<Props> = ({
  searchString,
  showToggle,
  onClose,
}) => {
  const scrollRef = useRef<Scrollbars>(null);
  const [suggestions, ready] = useSuggestions(searchString.trim());
  const [selected, setSelected] = useState(0);

  // Reset selected
  useEffect(() => {
    setSelected(-1);
  }, [searchString]);

  const onSuggestionClick = useCallback(
    (suggestion: Suggestion, toggle: boolean) => {
      switch (suggestion.type) {
        case SuggestionTypeOptions.EPISODE:
          // Create filter key (entity type + s)
          if (toggle) {
            toggleFilter('episodes', suggestion.id, true);
          } else {
            patchQuery({
              search: '',
              filter: JSON.stringify({ episodes: [suggestion.id] }),
              episode: suggestion.id,
            });
          }
          break;
        case SuggestionTypeOptions.THEME:
        case SuggestionTypeOptions.TAG:
        case SuggestionTypeOptions.SPEAKER:
        case SuggestionTypeOptions.MAKER:
        case SuggestionTypeOptions.LOCATION:
        case SuggestionTypeOptions.YEAR:
          // Create filter key (entity type + s)
          const key: keyof EntitiesKeys = (suggestion.type +
            's') as keyof EntitiesKeys;
          if (toggle) {
            toggleFilter(key, suggestion.id, true);
          } else {
            patchQuery({
              search: '',
              filter: JSON.stringify({ [key]: [suggestion.id] }),
              episode: '',
            });
          }
          break;
        default:
          patchSearch({ all: suggestion.name });
      }
      setTimeout(() => {
        const searchString =
          suggestion.type === SuggestionTypeOptions.KEYWORDS
            ? suggestion.name
            : '';
        onClose(searchString);
      }, 10);
    },
    [onClose]
  );

  // Handle keyboard
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          setSelected((v) => Math.min(suggestions.length - 1, v + 1));
          e.preventDefault();
          break;
        case 'ArrowUp':
          setSelected((v) => Math.max(0, v - 1));
          e.preventDefault();
          break;
        case 'Enter':
          if (selected > 0 && suggestions[selected]) {
            onSuggestionClick(
              suggestions[selected],
              e.shiftKey || e.ctrlKey || e.altKey
            );
            e.preventDefault();
            setTimeout(() => {
              const searchString =
                suggestions[selected].type === SuggestionTypeOptions.KEYWORDS
                  ? undefined
                  : '';
              onClose(searchString);
            }, 10);
          }
          break;
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selected, suggestions, onSuggestionClick, onClose]);

  // Scroll to active suggestion
  useEffect(() => {
    const scroll = scrollRef.current;
    if (!scroll || suggestions.length === 0) {
      return;
    }
    const suggestionHeight = scroll.getScrollHeight() / suggestions.length;
    // top
    if (
      scroll.getScrollTop() + scroll.getClientHeight() <
      selected * suggestionHeight
    ) {
      scroll.scrollTop(
        (selected + 1) * suggestionHeight - scroll.getClientHeight()
      );
    }
    // bottom
    if (scroll.getScrollTop() > selected * suggestionHeight) {
      scroll.scrollTop(selected * suggestionHeight);
    }
  }, [selected, suggestions]);

  return (
    <div
      className={classnames('Suggestions', {
        ready,
        'has-results': suggestions.length > 0,
      })}
    >
      <Scrollbars
        ref={scrollRef}
        className="Scrollbar"
        autoHeight
        autoHeightMax={window.innerHeight - 80}
        style={{
          width: '100%',
          maxHeight: 'calc(var(--app-height) - 80px)',
        }}
      >
        <div className="suggestions">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className={classnames('suggestion', {
                selected: index === selected,
              })}
              onClick={() => {
                onSuggestionClick(suggestion, false);
              }}
            >
              <ToolTip title={translateEntityType(suggestion.type)}>
                <div
                  className={clsx(
                    'type-icon',
                    'icon-' + suggestion.type,
                    suggestion.type === 'theme'
                      ? 'theme-' + suggestion.id
                      : 'default'
                  )}
                />
              </ToolTip>
              <div className="label">
                <span className="title">{suggestion.name}</span>
                {getRelationCount(suggestion)}
              </div>
              {showToggle &&
                suggestion.type !== SuggestionTypeOptions.KEYWORDS && (
                  <ToolTip
                    title="Toevoegen aan filters"
                    className="toggle-tooltip"
                  >
                    <div
                      className="toggle"
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onSuggestionClick(suggestion, true);
                      }}
                    />
                  </ToolTip>
                )}
            </div>
          ))}
        </div>
        <div className="filler"></div>
      </Scrollbars>
    </div>
  );
};

const getRelationCount = (suggestion: Suggestion) => {
  const count = Store._entities.episodes.filter((episode) =>
    episode.isRelatedTo(suggestion.id)
  ).length;

  return count ? <span className="count">{count}</span> : null;
};

export default Suggestions;
