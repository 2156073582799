import { EntityType } from 'models/Entities';

export default class Year {
  type: string = EntityType.YEAR;
  id: string = '';
  name: string = '';
  year: number = 0;

  constructor(year?: number) {
    if (typeof year !== 'undefined') {
      this.year = year;
      this.id = year.toString();
      this.name = this.id;
    }
  }
}
