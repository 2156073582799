import { useEffect } from 'react';

type Hook = () => void;

// useAppHeight keeps the css app-height parameter up to date with the window innerheight
const useAppHeight: Hook = () => {
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    return () => window.removeEventListener('resize', appHeight);
  }, []);
};

export default useAppHeight;
