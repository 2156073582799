import { viewportZoom } from 'events/viewport';
import React from 'react';
import './Zoom.scss';

// Zoom shows the zoom buttons
const Zoom = () => (
  <div className="Zoom">
    <div className="zoom-button zoom-in" onClick={viewportZoomIn}></div>
    <div className="zoom-button zoom-out" onClick={viewportZoomOut}></div>
  </div>
);

const viewportZoomIn = (e: React.MouseEvent) => {
  const alt = e.shiftKey || e.altKey || e.ctrlKey;
  viewportZoom(alt ? -0.5 : 2);
};

const viewportZoomOut = (e: React.MouseEvent) => {
  const alt = e.shiftKey || e.altKey || e.ctrlKey;
  viewportZoom(alt ? 0 : 0.5);
};

export default Zoom;
