import { useCallback, useEffect, useState } from 'react';
import { showError } from 'hooks/useNotifications';

import Env from 'config/Env';
import EpisodeDetails from 'models/entities/EpisodeDetails';
import EntityMapper from 'mappers/EntityMapper';

type Hook = (episodeId: string) => [EpisodeDetails | null, boolean, () => void];

// useEpisodeDetails loads episode details from the API host
const useEpisodeDetails: Hook = (episodeId) => {
  const [ready, setReady] = useState(false);
  const [episodeDetails, setEpisodeDetails] = useState<EpisodeDetails | null>(
    null
  );

  const clearEpisodeDetails = useCallback(() => {
    setEpisodeDetails(null);
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    const loadEntityDetails = async () => {
      // Skip if episodeId is empty
      if (episodeId === '') {
        setReady(true);
        return;
      }

      // Load episode data from url
      const url =
        Env.apiHost + 'episode/details?id=' + encodeURIComponent(episodeId);
      try {
        const response = await fetch(url, { signal: controller.signal });
        const json = await response.json();
        if (json && typeof json == 'object' && json._source) {
          setEpisodeDetails(EntityMapper.episodeDetails(json._source));
          setReady(true);
        } else {
          showError('EpisodeDetails not found, ' + episodeId);
        }
      } catch (err) {
        console.debug(err);
        showError('Could not load EpisodeDetails, ' + episodeId);
      }
    };
    setReady(false);
    loadEntityDetails();

    return () => {
      controller.abort();
    };
  }, [episodeId]);

  // Unmount/cleanup
  useEffect(() => {
    setReady(false);
    setEpisodeDetails(null);
  }, []);

  return [episodeDetails, ready, clearEpisodeDetails];
};

export default useEpisodeDetails;
