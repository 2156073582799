import Maker from './entities/Maker';
import Speaker from './entities/Speaker';
import Tag from './entities/Tag';
import Theme from './entities/Theme';
import Location from './entities/Location';
import Episode from './entities/Episode';
import Year from './entities/Year';

export enum EntityType {
  EPISODE = 'episode',
  LOCATION = 'location',
  MAKER = 'maker',
  SPEAKER = 'speaker',
  TAG = 'tag',
  THEME = 'theme',
  YEAR = 'year',
}

export type Entity = {
  id: string;
  name: string;
  type: string;
};

export interface EntitiesKeys {
  episodes: Episode[];
  tags: Tag[];
  locations: Location[];
  makers: Maker[];
  speakers: Speaker[];
  themes: Theme[];
  years: Year[];
}

export type AnyEntity =
  | Episode
  | Tag
  | Maker
  | Location
  | Speaker
  | Theme
  | Year;

export default class Entities implements EntitiesKeys {
  episodes: Episode[] = [];
  tags: Tag[] = [];
  locations: Location[] = [];
  makers: Maker[] = [];
  speakers: Speaker[] = [];
  themes: Theme[] = [];
  years: Year[] = [];

  getByTypeAndId(type: string, id: string): Entity | null {
    if (!Object.keys(this).includes(type)) {
      return null;
    }
    const entity = this[type as keyof EntitiesKeys].find(
      (entity: Entity) => entity.id === id
    );
    return (entity as Entity) || null;
  }

  getByTypeAndIds(type: string, ids: string[]): Entity[] {
    if (!Object.keys(this).includes(type)) {
      return [];
    }
    return ids
      .map((id) => this.getByTypeAndId(type, id))
      .filter((e) => e) as Entity[];
  }
}
