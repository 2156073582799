import React, { FunctionComponent, useRef } from 'react';
import './ToolTip.scss';
import ToolTipContent from './ToolTipContent';

interface Props {
  className?: string;
  title?: string;
  style?: React.CSSProperties;
}

// ToolTip is the main ToolTip wrapper
const ToolTip: FunctionComponent<Props> = ({
  children,
  className = '',
  title = undefined,
  style = undefined,
}) => {
  const ToolTipRef = useRef<HTMLDivElement | null>(null);

  const onMouseEnter = (e: React.MouseEvent | React.TouchEvent) => {
    const elem = ToolTipRef.current;
    if (!elem) {
      return;
    }

    elem.classList.remove('hide');

    // Get ToolTipContent and keep it within the screen boundaries
    const content = elem.querySelector('.ToolTipContent') as HTMLDivElement;
    if (!content) {
      return;
    }

    content.style.transform = '';

    const translate = { x: 0, y: 0 };
    const rect = content.getBoundingClientRect();
    if (rect.x < 0) {
      translate.x = rect.x;
    }
    if (rect.right > window.innerWidth) {
      translate.x = window.innerWidth - rect.right;
    }
    if (rect.y < 0) {
      translate.y = rect.y;
    }
    if (rect.bottom > window.innerHeight) {
      translate.y = window.innerHeight - rect.bottom;
    }

    content.style.transform = `translate(${translate.x}px, ${translate.y}px)`;
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    setTimeout(() => {
      const elem = ToolTipRef.current;
      if (!elem) {
        return;
      }

      elem.classList.add('hide');
    }, 10);
  };

  return (
    <div
      className={'ToolTip ' + className}
      ref={ToolTipRef}
      onMouseEnter={onMouseEnter}
      onTouchStart={onMouseEnter}
      onTouchEnd={onTouchEnd}
    >
      {children}
      {title !== undefined && <ToolTipContent title={title} style={style} />}
    </div>
  );
};

export default ToolTip;
