import { useEffect, useState } from 'react';
import { Entity } from 'models/Entities';
import { EVENT_HIGHLIGHT_ENTITY } from 'config/constants';
import { EventHighlightEntity } from 'events/highlightEntity';

type Hook = (entity: Entity) => boolean;

// useHighlightEntity returns the show-state for the given entity
const useHighlightEntity: Hook = (entity) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    // Handle highlight event
    const onHighlight = ((e: CustomEvent<EventHighlightEntity>) => {
      if (e.detail.ids) {
        // Hover
        setShow(e.detail.ids.includes(entity.id));
      } else {
        // Unhover
        setShow(true);
      }
    }) as EventListener;

    window.addEventListener(EVENT_HIGHLIGHT_ENTITY, onHighlight);

    return () => {
      window.removeEventListener(EVENT_HIGHLIGHT_ENTITY, onHighlight);
    };
  }, [entity]);

  return show;
};

export default useHighlightEntity;
