import Annotation, { AnnotationType } from './Annotation';

export type AnnotationsKeys = Pick<Annotations, 'speech' | 'image'>;

export default class Annotations {
  static keys = [
    AnnotationType.SPEECH as keyof AnnotationsKeys,
    AnnotationType.IMAGE as keyof AnnotationsKeys,
  ];

  speech: Annotation[] = [];
  image: Annotation[] = [];

  isEmpty() {
    return this.speech.length === 0 && this.image.length === 0;
  }
}
