import { useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';
import PIXIApp from 'models/PIXIApp';
import Viewport from 'models/visual/Viewport';

type PIXIBase = {
  app: PIXIApp | null;
  viewport: Viewport | null;
  container: PIXI.Container | null;
};

type Hook = (props: { domContainer: HTMLDivElement | null }) => PIXIBase;

const emptyBase = { app: null, viewport: null, container: null };

// usePIXIBase create the main PIXIApp
const usePIXIBase: Hook = ({ domContainer }) => {
  const [base, setBase] = useState<PIXIBase>(emptyBase);

  useEffect(() => {
    // Require a container
    if (!domContainer) {
      setBase(emptyBase);
      return;
    }

    // Create application
    const app = new PIXIApp(domContainer);

    // Viewport
    const viewport = new Viewport(app);
    app.stage.addChild(viewport);

    // Container
    const container = getContainer();
    viewport.addChild(container);

    setBase({ app, viewport, container });

    return () => {
      // App
      app.removeListeners();
      app.stop();
      app.ticker.stop();
      PIXI.Ticker.shared.stop();
      app.destroy();

      // Viewport
      viewport.onDestroy();

      // container
      container.destroy();
    };
  }, [domContainer]);

  return base;
};

const getContainer = () => {
  const container = new PIXI.Container();
  container.pivot.set(0.5);
  container.x = 0;
  container.y = 0;
  return container;
};

export default usePIXIBase;
