import VideoSegment from 'models/VideoSegment';

// VideoSegmentsMapper maps objects to VideoSegments
export default class VideoSegmentsMapper {
  static videoSegments(times: Array<Array<number>>): VideoSegment[] {
    const videoSegments: VideoSegment[] = [];

    times.forEach((t, index) => {
      const videoSegment = new VideoSegment();
      videoSegment.index = index;
      videoSegment.start = t[0];
      videoSegment.end = t[1];
      videoSegments.push(videoSegment);
    });
    return videoSegments;
  }
}
