import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './EpisodePlayer.scss';
import EpisodeDetails from 'models/entities/EpisodeDetails';
import Env from 'config/Env';
import classnames from 'clsx';
import VimeoPlayer from 'models/VimeoPlayer';
import useImageReady from 'hooks/useImageReady';
import { themeColors } from 'config/themes';
import { padHex } from 'util/pad';

interface Props {
  episode: EpisodeDetails;
}

// EpisodePlayer shows the episode video player
const EpisodePlayer: FunctionComponent<Props> = ({ episode }) => {
  const imageReady = useImageReady(Env.tilesHost + episode.getImage());

  const player = useRef<VimeoPlayer | null>(null);
  const playerContainer = useRef<HTMLDivElement | null>(null);
  const [showOverlay, setShowOverlay] = useState(true);

  // Hide overlay on new episode
  useEffect(() => {
    setShowOverlay(true);
  }, [episode]);

  const onPlay = useCallback(() => {
    setShowOverlay(false);
  }, []);

  // Create player
  useEffect(() => {
    if (!episode.vimeoUrl || !playerContainer.current) {
      return;
    }

    player.current?.destroy();

    const themeId = episode.getTheme()?.id;
    const color =
      themeId && themeId in themeColors
        ? padHex(themeColors[themeId].toString(16))
        : '0033cc';

    const vimeoPlayer = new VimeoPlayer({
      onPlay,
      element: playerContainer.current,
      options: {
        dnt: true,
        url: episode.vimeoUrl,
        width: Math.min(window.innerWidth, playerContainer.current.offsetWidth),
        height: playerContainer.current.offsetHeight,
        maxheight: window.innerHeight < 700 ? 300 : 400,
        texttrack: 'nl',
        color,
      },
    });

    player.current = vimeoPlayer;

    return () => {
      vimeoPlayer.unload();
      vimeoPlayer.destroy();
      player.current = null;
    };
  }, [onPlay, episode]);

  const onPlayVideo = useCallback(() => {
    player.current?.start();
  }, []);

  return (
    <div
      className={classnames(
        'EpisodePlayer',
        'theme-' + (episode.getTheme()?.id || ''),
        { missing: !episode.vimeoUrl }
      )}
    >
      {/* Background */}
      {imageReady && (
        <div
          className={classnames('background-image', { fade: !showOverlay })}
          style={{
            backgroundImage:
              'url("' + Env.tilesHost + episode.getImage() + '")',
          }}
        ></div>
      )}

      {/* Vimeo */}
      <div className="vimeo-player">
        <div
          className={classnames('player', { active: !showOverlay })}
          ref={playerContainer}
          key={episode.uid}
        />
      </div>

      {/* Overlay */}
      {showOverlay && (
        <div className="overlay" onClick={onPlayVideo}>
          <div
            className="background-image"
            style={{
              backgroundImage: imageReady
                ? 'url("' + Env.tilesHost + episode.getImage() + '")'
                : undefined,
            }}
          />
          {/* Play-button */}
          <div
            className={classnames(
              'play-button',
              'theme-' + episode.getTheme()?.id
            )}
          />
        </div>
      )}
    </div>
  );
};

export default EpisodePlayer;
