import { showError } from 'hooks/useNotifications';
import Tag from 'models/entities/Tag';
import Location from 'models/entities/Location';
import Filter from 'models/Filter';
import Episode from 'models/entities/Episode';
import Maker from 'models/entities/Maker';
import Speaker from 'models/entities/Speaker';
import Year from 'models/entities/Year';
import Theme from 'models/entities/Theme';
import Entities from 'models/Entities';

// FilterMapper contains mappers that create Filter instances
export default class FilterMapper {
  entities: Entities;
  constructor(entities: Entities) {
    this.entities = entities;
  }

  // fromString maps a json string to a Filter instance
  fromJSONString(json: string): Filter {
    if (!json) {
      return new Filter();
    }

    try {
      const obj = JSON.parse(json);
      return this.fromObject(obj);
    } catch (e) {
      console.error(e);
      showError('error_filter_json_invalid');
    }
    return new Filter();
  }

  // fromObject maps an object to a Filter instance
  fromObject(obj: Record<string, string[]>): Filter {
    const filter = new Filter();

    // Episodes
    filter.episodes =
      obj.episodes && Array.isArray(obj.episodes)
        ? (this.entities.getByTypeAndIds('episodes', obj.episodes) as Episode[])
        : filter.episodes;

    // Tags
    filter.tags =
      obj.tags && Array.isArray(obj.tags)
        ? (this.entities.getByTypeAndIds('tags', obj.tags) as Tag[])
        : filter.tags;

    // Locations
    filter.locations =
      obj.locations && Array.isArray(obj.locations)
        ? (this.entities.getByTypeAndIds(
            'locations',
            obj.locations
          ) as Location[])
        : filter.locations;

    // Makers
    filter.makers =
      obj.makers && Array.isArray(obj.makers)
        ? (this.entities.getByTypeAndIds('makers', obj.makers) as Maker[])
        : filter.makers;

    // Speakers
    filter.speakers =
      obj.speakers && Array.isArray(obj.speakers)
        ? (this.entities.getByTypeAndIds('speakers', obj.speakers) as Speaker[])
        : filter.speakers;

    // Themes
    filter.themes =
      obj.themes && Array.isArray(obj.themes)
        ? (this.entities.getByTypeAndIds('themes', obj.themes) as Theme[])
        : filter.themes;

    // Years
    filter.years =
      obj.years && Array.isArray(obj.years)
        ? (this.entities.getByTypeAndIds('years', obj.years) as Year[])
        : filter.years;
    return filter;
  }
}
