import useQuery from 'hooks/useQuery';
import { FunctionComponent } from 'react';
import './BrowserPage.scss';
import Page from 'components/Page/Page';
import useMode from 'hooks/useMode';
import usePreload from 'hooks/usePreload';
import Preloader from 'components/Preloader/Preloader';
import Browser from 'components/Browser/Browser';
import useSearchResult from 'hooks/useSearchResult';
import useFilterResult from 'hooks/useFilterResult';
import useDistances from 'hooks/useDistances';
import useFilter from 'hooks/useFilter';
import useSearchQuery from 'hooks/useSearchQuery';

interface Props {
  home?: boolean;
}

// BrowserPage collects all data necessary to render the browser page
const BrowserPage: FunctionComponent<Props> = ({ home = false }) => {
  // Get browser query
  const query = useQuery();

  useMode(query.mode);

  const [store, preloadReady] = usePreload();

  useDistances({ query, store });

  const searchQuery = useSearchQuery({ query: query.search });

  const [searchResult, searchResultReady] = useSearchResult({
    searchQuery,
    store,
  });

  const filter = useFilter({ query, store });

  const [result, resultAllThemes] = useFilterResult({
    filter,
    searchResult,
    store,
  });

  const segmentQuery = useSearchQuery({
    query: query.segment ? query.segment : query.search,
  });

  return (
    <Page
      mode={query.mode}
      info={query.info}
      query={query}
      showHeader={preloadReady}
      searchQuery={searchQuery}
    >
      <div className="BrowserPage">
        <Browser
          home={home}
          ready={preloadReady && searchResultReady}
          store={store}
          query={query}
          segmentQuery={segmentQuery}
          filter={filter}
          result={result}
          resultAllThemes={resultAllThemes}
        />
        <Preloader active={!preloadReady} />
      </div>
    </Page>
  );
};

export default BrowserPage;
