import { AnyEntity, EntitiesKeys, Entity } from './Entities';
import Episode from './entities/Episode';
import Maker from './entities/Maker';
import Speaker from './entities/Speaker';
import Location from './entities/Location';
import Tag from './entities/Tag';
import Theme from './entities/Theme';
import Year from './entities/Year';
import Result from './Result';

export default class Filter implements EntitiesKeys {
  static readonly KEYS: (keyof EntitiesKeys)[] = [
    'themes',
    'years',
    'episodes',
    'tags',
    'locations',
    'makers',
    'speakers',
  ] as (keyof EntitiesKeys)[];

  episodes: Episode[] = [];
  tags: Tag[] = [];
  locations: Location[] = [];
  makers: Maker[] = [];
  speakers: Speaker[] = [];
  themes: Theme[] = [];
  years: Year[] = [];

  isEmpty(): boolean {
    return !(
      this.episodes.length ||
      this.tags.length ||
      this.locations.length ||
      this.makers.length ||
      this.speakers.length ||
      this.themes.length ||
      this.years.length
    );
  }

  // applyBaseFilters returns a filtered Result from the given Result
  // this function excludes the themes filters
  applyBaseFilters(result: Result): Result {
    const filteredResult = new Result();

    // Reset filterscore
    result.episodes.forEach((episode) => {
      episode.filterScore = 0;
      episode.score = episode.baseScore;
    });

    // Skip when filter is empty
    const isFilterEmpty = !(
      this.episodes.length ||
      this.tags.length ||
      this.locations.length ||
      this.makers.length ||
      this.speakers.length ||
      this.years.length
    );
    if (isFilterEmpty) {
      filteredResult.episodes = [...result.episodes];
      return filteredResult;
    }
    // Filter episodes
    filteredResult.episodes = result.episodes.filter(
      (episode) =>
        // years
        (!this.years.length ||
          this.years.some((year) => episode.year === year.year)) &&
        // other filters
        (!this.locations.length ||
          this.locations.some((location) =>
            episode.location.includes(location.id)
          )) &&
        (!this.tags.length ||
          this.tags.some((tag) => episode.tag.includes(tag.id))) &&
        (!this.makers.length ||
          this.makers.some((maker) => episode.maker.includes(maker.id))) &&
        (!this.speakers.length ||
          this.speakers.some((speaker) =>
            episode.speaker.includes(speaker.id)
          )) &&
        (!this.episodes.length ||
          this.episodes.some((relatedEpisode, index) => {
            const score = Math.min(30, episode.getRelatedness(relatedEpisode));
            if (score < 3) {
              return false;
            }
            if (index === 0) {
              episode.score = 0;
            }
            episode.filterScore += (score * score) / 20;
            return true;
          }))
    );
    return filteredResult;
  }

  // applyThemeFilter returns a filtered Result from the given Result
  // this function only applies the theme filter
  applyThemeFilter(result: Result): Result {
    const filteredResult = new Result();

    if (this.themes.length === 0) {
      filteredResult.episodes = [...result.episodes];
      return filteredResult;
    }
    // Filter episodes on theme
    filteredResult.episodes = result.episodes.filter((episode) =>
      this.themes.some((theme) => episode.theme.includes(theme.id))
    );
    return filteredResult;
  }

  removeFilter(key: keyof EntitiesKeys, entity: any) {
    const index = this[key].indexOf(entity);
    if (index > -1) {
      this[key].splice(index, 1);
    }
  }

  addFilter(key: keyof EntitiesKeys, entity: AnyEntity) {
    const a = this[key] as AnyEntity[];
    a.push(entity);
  }

  toggleFilter(key: keyof EntitiesKeys, entity: any) {
    const active = this[key].includes(entity);
    if (active) {
      this.removeFilter(key, entity);
    } else {
      this.addFilter(key, entity);
    }
  }

  getQueryValue() {
    const json = JSON.stringify(this);
    return json === '{}' ? '' : json;
  }

  toJSON() {
    const activeFilters: Partial<Record<keyof EntitiesKeys, any>> = {};
    Filter.KEYS.forEach((key) => {
      if (this[key].length) {
        activeFilters[key] = this[key].map(
          (entity: Entity | null) => entity && entity.id
        );
      }
    });

    return activeFilters;
  }
}
