import QueryMapper from 'mappers/QueryMapper';
import Query from 'models/Query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useNav from './useNav';

type Hook = () => Query;

// useQuery
const useQuery: Hook = () => {
  const location = useLocation();

  const [query, setQuery] = useState<Query>(
    QueryMapper.fromQueryString(location.search)
  );

  // Update query on query string change
  useEffect(() => {
    setQuery(QueryMapper.fromQueryString(location.search));
  }, [location.search]);

  // Enable browser navigation
  useNav(query);

  return query;
};

export default useQuery;
