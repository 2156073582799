import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BrowserPage from 'components/BrowserPage/BrowserPage';
import useAppHeight from 'hooks/useAppHeight';

// App is the root component of the application
const App = () => {
  useAppHeight();

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={'/browse'} element={<BrowserPage home={false} />} />
          <Route path="/" element={<BrowserPage home={true} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
