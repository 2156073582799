import { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import Query from 'models/Query';
import { getEpisodeViewWidth } from 'components/EpisodeView/EpisodeView';
import { WINDOW_WIDTH_FULL_SCREEN_EPISODE_VIEW } from 'config/constants';

type Hook = (props: { app: PIXI.Application | null; query: Query }) => void;

// useStage
const useStage: Hook = ({ app, query }) => {
  // Update stage on ticker
  useEffect(() => {
    // Only on large screens
    if (window.innerWidth < WINDOW_WIDTH_FULL_SCREEN_EPISODE_VIEW) {
      return;
    }
    // Require app
    if (!app || !app.stage) {
      return;
    }

    // Require ticker
    const ticker = PIXI.Ticker.shared;
    if (!ticker) {
      return;
    }

    // Update handler
    const onUpdate = (delta: number) => {
      // Move stage center to left, to compensate for the EpisodeView component
      let dx = 0;
      if (query.episode) {
        const halfEpisodeViewWidth = getEpisodeViewWidth() / 2;
        dx = app.stage.position.x + halfEpisodeViewWidth;
      } else {
        dx = app.stage.position.x;
      }
      app.stage.position.x -= (delta / 10) * dx;
    };

    ticker.add(onUpdate);
    return () => {
      ticker.remove(onUpdate);
    };
  }, [app, query.episode]);
};

export default useStage;
