import Entities from 'models/Entities';
import EntityMapper from './EntityMapper';

// EntitiesMapper maps objects to Entities
export default class EntitiesMapper {
  static entities(obj: Partial<Entities>): Entities {
    const entities = new Entities();
    // Episodes
    entities.episodes =
      obj.episodes && Array.isArray(obj.episodes)
        ? obj.episodes.map((obj) => EntityMapper.episode(obj))
        : entities.episodes;

    // Tags
    entities.tags =
      obj.tags && Array.isArray(obj.tags)
        ? obj.tags.map((obj) => EntityMapper.tag(obj))
        : entities.tags;

    // Locations
    entities.locations =
      obj.locations && Array.isArray(obj.locations)
        ? obj.locations.map((obj) => EntityMapper.location(obj))
        : entities.locations;

    // Makers
    entities.makers =
      obj.makers && Array.isArray(obj.makers)
        ? obj.makers.map((obj) => EntityMapper.maker(obj))
        : entities.makers;

    // Speakers
    entities.speakers =
      obj.speakers && Array.isArray(obj.speakers)
        ? obj.speakers.map((obj) => EntityMapper.speaker(obj))
        : entities.speakers;

    // Themes
    entities.themes =
      obj.themes && Array.isArray(obj.themes)
        ? obj.themes.map((obj) => EntityMapper.theme(obj))
        : entities.themes;

    // Years
    entities.years =
      obj.years && Array.isArray(obj.years)
        ? obj.years.map((obj) => EntityMapper.year(obj))
        : entities.years;

    return entities;
  }
}
