import { Query } from '@testing-library/react';
import { EVENT_NAVIGATE_REPLACE, EVENT_NAVIGATE_PATCH } from 'config/constants';
import FilterMapper from 'mappers/FilterMapper';
import QueryMapper from 'mappers/QueryMapper';
import SearchQueryMapper from 'mappers/SearchQueryMapper';
import { EntitiesKeys } from 'models/Entities';
import { Info } from 'models/Info';
import { Mode, PartialQuery } from 'models/Query';
import SearchQuery from 'models/SearchQuery';
import Store from 'models/Store';

// replaceQuery emits the event to replace the current query
export const replaceQuery = (query: Query) => {
  const event = new CustomEvent<{ query: Query }>(EVENT_NAVIGATE_REPLACE, {
    detail: { query },
  } as CustomEventInit);
  window.dispatchEvent(event);
};

// patchQuery emits the event to path the current query
export const patchQuery = (query: PartialQuery) => {
  const event = new CustomEvent<{
    query: PartialQuery;
  }>(EVENT_NAVIGATE_PATCH, {
    detail: { query },
  } as CustomEventInit);
  window.dispatchEvent(event);
};

// patchSearch patches the search param
export const patchSearch = (params: Partial<SearchQuery>) => {
  const searchQuery = SearchQueryMapper.fromObject(params);
  searchQuery.parseVarsFromAll();
  const search = searchQuery.getQueryValue();
  patchQuery({ search, filter: '', episode: '', segment: '' });
};

// patchSearch patches the search param
export const patchEpisode = (episode: string) => {
  patchQuery({ episode });
};

// patchFilter patches the filter param
export const patchFilter = (filter: string, resetEpisode: boolean) => {
  const options: { episode?: string; segment?: string } = {};
  if (resetEpisode) {
    options.episode = '';
    options.segment = '';
  }
  patchQuery(Object.assign({ filter }, options));
};

// patchFilter patches the filter param
export const toggleFilter = (
  key: keyof EntitiesKeys,
  value: string,
  resetEpisode: boolean
) => {
  const query = QueryMapper.fromQueryString(window.location.search);
  const filterMapper = new FilterMapper(Store._entities);
  const filter = filterMapper.fromJSONString(query.filter);
  const entity = Store._entities.getByTypeAndId(key, value);
  filter.toggleFilter(key, entity);
  patchFilter(filter.getQueryValue(), resetEpisode);
};

// patchSegment patches the segment param
export const patchSegment = (params: Partial<SearchQuery>) => {
  const searchQuery = SearchQueryMapper.fromObject(params);
  const segment = JSON.stringify(searchQuery.getActiveAnnotationFields());
  patchQuery({ segment });
};

// patchMode patches the mode
export const patchMode = (mode: Mode) => {
  patchQuery({ mode });
};

// patchInfo patches the info
export const patchInfo = (info: Info | string) => {
  patchQuery({ info });
};
