import { useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';
import { FONT_NAME } from 'config/constants';
import { waitFor } from 'util/waitfor';

type Hook = () => boolean;

// useBitmapFont loads the bitmap fonts into PIXI
const useBitmapFont: Hook = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const loadFonts = async () => {
      // Load Bitmap font
      try {
        const response = await fetch(
          '/images/textures/simplistic_sans_bold.txt',
          { signal: controller.signal }
        );
        const data = await response.text();
        const texture = await PIXI.Texture.fromURL(
          '/images/textures/simplistic_sans_bold.png'
        );
        PIXI.BitmapFont.install(data, texture);

        // Wait until loaded
        while (!PIXI.BitmapFont.available[FONT_NAME]) {
          await waitFor(10);
        }
      } catch (e) {
        console.error(e);
      }

      setReady(true);
    };
    loadFonts();

    return () => {
      controller.abort();
    };
  }, []);

  return ready;
};

export default useBitmapFont;
