import {
  useCallback,
  useState,
  forwardRef,
  ForwardedRef,
  MutableRefObject,
  useEffect,
} from 'react';
import './Input.scss';

interface Props {
  ref: ForwardedRef<HTMLInputElement>;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  readonly?: boolean;
  onChange?: () => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const emptyRef = {
  current: null,
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      placeholder,
      defaultValue,
      readonly,
      onChange,
      onKeyPress,
      onKeyDown,
      onBlur,
      onFocus,
      value,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [showClear, setShowClear] = useState(defaultValue !== '');

    const inputRef =
      (ref as MutableRefObject<HTMLInputElement | null>) || emptyRef;

    const onFocusLocal = useCallback(() => {
      if (!inputRef.current) {
        return;
      }
      inputRef.current.select();

      onFocus && onFocus();
    }, [inputRef, onFocus]);

    const onChangeLocal = useCallback(() => {
      setShowClear(inputRef.current?.value !== '');
      onChange && onChange();
    }, [inputRef, onChange]);

    const onClear = useCallback(() => {
      if (!inputRef.current) {
        return;
      }
      inputRef.current.value = '';
      setShowClear(false);
      inputRef.current.focus();
      onChange && onChange();
    }, [inputRef, onChange]);

    useEffect(() => {
      if (value === '') {
        setShowClear(false);
      }
    }, [value]);

    // Listen to external change event and update clear button
    // useEffect(() => {
    //   const input = inputRef.current;
    //   if (!input) {
    //     return;
    //   }

    //   const onInputChanged = () => {
    //     setShowClear(inputRef.current?.value !== '');
    //   };

    //   input.addEventListener(EVENT_INPUT_CHANGED, onInputChanged);

    //   return () => {
    //     input.removeEventListener(EVENT_INPUT_CHANGED, onInputChanged);
    //   };
    // }, [inputRef, onChangeLocal]);

    return (
      <div className="Input">
        <input
          ref={inputRef}
          type="text"
          name={name}
          value={value}
          autoComplete="off"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChangeLocal}
          onFocus={onFocusLocal}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          readOnly={readonly}
        />
        {showClear && <div className="clear" onClick={onClear} />}
      </div>
    );
  }
);

// Set value and trigger change event on input
// https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
export const changeInputValue = (input: HTMLInputElement, value: string) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  )?.set;
  nativeInputValueSetter?.call(input, value);
  var inputEvent = new Event('input', { bubbles: true });
  input.dispatchEvent(inputEvent);
};

export default Input;
