import { useEffect, useState } from 'react';

import Annotations, { AnnotationsKeys } from 'models/Annotations';

type Hook = (props: {
  annotations: Annotations | null;
  time: number | null;
}) => Annotations | null;

// useActiveAnnotations returns the active annotations for a given time
const useActiveAnnotations: Hook = ({ annotations, time }) => {
  const [activeAnnotations, setActiveAnnotations] =
    useState<Annotations | null>(null);

  // set active segment
  useEffect(() => {
    if (!annotations || time === null) {
      return;
    }

    const activeAnnotations = new Annotations();

    Annotations.keys.forEach((key: keyof AnnotationsKeys) => {
      activeAnnotations[key] = annotations[key].filter(
        (annotation) =>
          Math.floor(annotation.start) <= time &&
          Math.ceil(annotation.end) > time
      );
    });
    setActiveAnnotations(activeAnnotations);
  }, [annotations, time]);

  return activeAnnotations;
};

export default useActiveAnnotations;
