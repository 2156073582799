import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import './Themes.scss';
import Store from 'models/Store';
import Result from 'models/Result';
import Filter from 'models/Filter';
import Theme from 'models/entities/Theme';
import { patchFilter, patchInfo } from 'events/navigation';
import FilterButton from 'components/FilterButton/FilterButton';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import InfoToolTip from 'components/InfoToolTip/InfoToolTip';
import { Info } from 'models/Info';

interface Props {
  store: Store;
  filter: Filter;
  result: Result;
}

// Themes shows the list of available and active themes
const Themes: FunctionComponent<Props> = ({ store, filter, result }) => {
  // Sorted list of Theme objects
  const themes = useMemo(() => {
    // Unique themes from episodes
    const uniqueThemes = new Set<string>();
    result.episodes.forEach((episode) => {
      uniqueThemes.add(episode.getTheme());
    });

    // Themes from filter
    filter.themes.forEach((theme) => uniqueThemes.add(theme.id));

    // Create themes list
    const themes: Theme[] = store.entities.getByTypeAndIds(
      'themes',
      Array.from(uniqueThemes)
    ) as Theme[];

    themes.sort((a, b) => a.name.localeCompare(b.name));

    return themes;
  }, [result, store, filter.themes]);

  const onThemeClick = useCallback(
    (theme: Theme, alt?: boolean) => {
      return (e?: React.MouseEvent<HTMLElement>) => {
        const altMode = e?.altKey || e?.ctrlKey || e?.shiftKey;
        if (altMode) {
          if (filter.themes.includes(theme)) {
            if (filter.themes.length === 1) {
              filter.themes = [];
            } else {
              filter.themes = [theme];
            }
          } else {
            filter.themes = [theme];
          }
        } else {
          filter.toggleFilter('themes', theme);
        }
        patchFilter(filter.getQueryValue(), true);
      };
    },
    [filter]
  );

  if (themes.length === 0) {
    return null;
  }

  return (
    <div className="Themes">
      <h4
        onClick={() => {
          patchInfo(Info.THEME);
        }}
      >
        thema's{' '}
        <InfoToolTip title="Meer informatie over de 20 Tegenlicht thema's" />
      </h4>
      <TransitionGroup>
        {themes.map((theme) => (
          <ThemeButton
            key={theme.id}
            theme={theme}
            onClick={onThemeClick(theme)}
            showDelete={filter.themes.includes(theme)}
            blur={filter.themes.length > 0 && !filter.themes.includes(theme)}
          />
        ))}
      </TransitionGroup>
    </div>
  );
};

interface ThemeButtonProps {
  in?: boolean;
  enter?: boolean;
  exit?: boolean;
  showDelete: boolean;
  blur: boolean;
  theme: Theme;
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
}

const ThemeButton = (props: ThemeButtonProps) => {
  const { showDelete, blur, theme, onClick } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <CSSTransition
      in={props.in}
      enter={props.enter}
      exit={props.exit}
      timeout={1000}
      classNames="transition"
      unmountOnExit
      key={theme.id}
      nodeRef={ref}
    >
      <div className="button-wrapper" ref={ref}>
        <FilterButton
          entity={theme}
          onClick={onClick}
          showDelete={showDelete}
          blur={blur}
        />
      </div>
    </CSSTransition>
  );
};

export default Themes;
