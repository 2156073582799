import './ExtraSearch.scss';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { patchSearch } from 'events/navigation';
import SearchQuery from 'models/SearchQuery';
import {
  FunctionComponent,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import AdvancedInput from 'components/AdvancedInput/AdvancedInput';
import InfoToolTip from 'components/InfoToolTip/InfoToolTip';
import ToolTip from 'components/Tooltip/ToolTip';

interface Props {
  searchQuery: SearchQuery;
  onClose: () => void;
  advanced?: boolean;
}

// ExtraSearch shows the search input and handles the search action
const ExtraSearch: FunctionComponent<Props> = ({
  searchQuery,
  onClose,
  advanced,
}) => {
  const scrollRef = useRef<Scrollbars>(null);
  const form = useRef<HTMLFormElement | null>(null);
  const [annotationPlaceholder, setAnnotationPlaceholder] = useState(
    getAnnotationPlaceholder(
      searchQuery.annotationSpeech,
      searchQuery.annotationImage
    )
  );

  // Focus first input
  useEffect(() => {
    form.current &&
      (form.current.elements.namedItem('episode') as HTMLInputElement)?.focus();
  }, []);

  // Handle search
  const onSearch = useCallback(() => {
    if (!form.current) {
      return;
    }
    const searchParams = {
      episode:
        '' +
        (form.current.elements.namedItem('episode') as HTMLInputElement).value,
      annotation:
        '' +
        (form.current.elements.namedItem('annotation') as HTMLInputElement)
          .value,
      annotationSpeech: (
        form.current.elements.namedItem('annotation-speech') as HTMLInputElement
      ).checked,
      annotationImage: (
        form.current.elements.namedItem('annotation-image') as HTMLInputElement
      ).checked,
    };

    patchSearch(searchParams);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSearch();
      return false;
    },
    [onSearch]
  );

  const hiddenInputForEnterSubmit = <input type="submit" hidden />;
  const updateAnnotationPlaceholder = () => {
    if (!form.current) {
      return;
    }
    const speech = (
      form.current.elements.namedItem('annotation-speech') as HTMLInputElement
    ).checked;
    const image = (
      form.current.elements.namedItem('annotation-image') as HTMLInputElement
    ).checked;

    setAnnotationPlaceholder(getAnnotationPlaceholder(speech, image));
  };

  return (
    <div className="ExtraSearch">
      <Scrollbars
        ref={scrollRef}
        className="Scrollbar"
        autoHeight
        autoHeightMax="calc(var(--app-height) - 80px)"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div className="content">
          <form ref={form} onSubmit={onSubmit}>
            {/* <h3>zoek afleveringen...</h3> */}
            <div>
              <label htmlFor="episode">
                afleveringen{' '}
                <InfoToolTip
                  title="Zoek afleveringen op basis van de titel, beschrijving, of een gerelateerd onderwerp, locatie, spreker of maker."
                  style={{ minWidth: 300 }}
                />
              </label>
              <AdvancedInput
                name="episode"
                placeholder="bijv. met onderwerp, locatie, spreker of maker"
                defaultValue={searchQuery.episode}
                showAdvanced={advanced || false}
              />
            </div>
            <div>
              <label htmlFor="annotation">
                met een fragment{' '}
                <InfoToolTip
                  title="Zoek afleveringen met een fragment waarin iets wordt gezegd (spraak) of iets wordt getoond (beeld)."
                  style={{ minWidth: 300 }}
                />
              </label>
              <AdvancedInput
                name="annotation"
                placeholder={annotationPlaceholder}
                defaultValue={searchQuery.annotation}
                showAdvanced={advanced || false}
              />
              <div className="annotation-type">
                <label>
                  <input
                    type="checkbox"
                    name="annotation-speech"
                    defaultChecked={searchQuery.annotationSpeech}
                    onChange={updateAnnotationPlaceholder}
                  />
                  spraak
                </label>
                <ToolTip
                  title="Let op: AI beeldherkenning kent nog grote foutmarges"
                  style={{ width: 190 }}
                >
                  <label>
                    <input
                      type="checkbox"
                      name="annotation-image"
                      defaultChecked={searchQuery.annotationImage}
                      onChange={updateAnnotationPlaceholder}
                    />
                    beeld
                  </label>
                </ToolTip>
              </div>
            </div>

            {hiddenInputForEnterSubmit}

            <div className="actions">
              <div className="search-button" onClick={onSearch}>
                zoeken
              </div>
            </div>
          </form>
        </div>
      </Scrollbars>
    </div>
  );
};

const getAnnotationPlaceholder = (speech: boolean, image: boolean) => {
  let placeholder = [];
  // speech
  if (speech) {
    placeholder.push('gezegd');
  }
  // image
  if (image) {
    placeholder.push('getoond in beeld');
  }

  return placeholder.length > 0
    ? 'bijv. wat wordt ' + placeholder.join(' / ')
    : 'kies minstens één bron';
};

export default ExtraSearch;
