import './TabAbout.scss';

// TabAbout shows the about tab
const TabAbout = () => {
  return (
    <div className="TabAbout tab">
      <div className="content">
        <p>
          Op deze website kun je zoeken en ontdekken in het afleveringenarchief
          van VPRO Tegenlicht. Met behulp van de thema's, zoekopdrachten,
          filters en de visuele weergave kun je relevante afleveringen vinden.
          De afleveringen zijn te bekijken en voorzien van informatie, links en
          relaties.
        </p>
        <p>
          Met behulp van uit spraak- en beeldanalyse verkregen data, kan dieper
          binnen een aflevering worden gezocht. Zo vind je snel het fragment
          waar een bepaald onderwerp aan bod of in beeld komt.
        </p>
        <p>
          Voor ervaren gebruikers is er een geavanceerde mode beschikbaar. Met
          de extra zoek- en weergave mogelijkheden kan het archief zowel breder
          als dieper worden ontsloten.
        </p>
        <p>
          Deze website is onderdeel van het project 'Archief van de Toekomst'.
          Binnen dit kader zijn ook een boek en installatie ontwikkeld. Wil je
          meer weten over de achtergronden en totstandkoming hiervan? Bezoek de
          uitgebreide website.
        </p>
        <div className="actions">
          <a
            className="button"
            href="https://www.vpro.nl/tegenlicht/avdt"
            target="_blank"
            rel="noreferrer noopener"
          >
            Meer informatie
          </a>
        </div>

        <div className="privacy">
          Deze website gebruikt analytische cookies voor het bijhouden van
          anonieme gebruiksstatistieken.{' '}
          <a
            href="https://www.vpro.nl/over-de-vpro/privacy-statement.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy statement
          </a>
        </div>

        <div className="context">
          <p>Mede mogelijk gemaakt door subsidie van:</p>
          <div className="organisations">
            <a
              className="npo"
              href="https://innovatie.npo.nl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              NPO innovatie
            </a>
            <a
              className="svdj"
              href="https://www.svdj.nl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Stimuleringsfonds voor de Journalistiek
            </a>
            <a
              className="ebu"
              href="https://www.ebu.ch"
              target="_blank"
              rel="noreferrer noopener"
            >
              European Broadcasting Union
            </a>
            <a
              className="mondriaan"
              href="https://www.mondriaanfonds.nl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Mondriaan fonds
            </a>
          </div>
        </div>

        <div className="context">
          <p>Een project van:</p>
          <div className="organisations">
            <a
              className="vpro"
              href="https://www.vpro.nl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              VPRO
            </a>
            <a
              className="nisv"
              href="https://www.beeldengeluid.nl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Nederlands Instituut voor Beeld en Geluid
            </a>
            <a
              className="sudox"
              href="https://www.sudox.nl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              SUDOX
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabAbout;
