import { EVENT_HIGHLIGHT_EPISODE } from 'config/constants';

export interface EventHighlightEpisode {
  id?: string;
}

// highlightEpisode emits a new highlight episode event
export const highlightEpisode = (id?: string) => {
  const event = new CustomEvent<EventHighlightEpisode>(
    EVENT_HIGHLIGHT_EPISODE,
    {
      detail: { id },
    } as CustomEventInit
  );
  window.dispatchEvent(event);
};
