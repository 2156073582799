import colors from './colors';

// Theme colors, based on Airtable ID
export const themeColors: { [key: string]: number } = {
  recb9NXzkE9YOQqqE: colors.Red, // Media, communicatie & beeldvorming
  recxWvNCQupoHz35j: colors.Darkgreen, // Klimaat, duurzaamheid
  recMMUYZXtBdYJOQH: colors.Lightgold, // Energie: van fossiel naar hernieuwbaar
  recvfL4IDqNNNKWAQ: colors.Darkpink, // Wonen, bouwen en samenleven
  rec7Fz4z58zQOXTCR: colors.Pink, // Protest, activisme & locale alternatieven
  recc48kDNVJa5pkm1: colors.Turquoise, // Data & privacy, computers, netwerken
  recs2ORNvocoGZup3: colors.Darkred, // Het onderwijs & de universiteiten
  rec7G3rQjiRRfvMio: colors.Violet, // Europa
  reclSnE9iuW5LyQfI: colors.Darkgold, // Trek, migratie & vluchtelingen
  recn2aD155CkGnAYR: colors.Lightgreen, // Finance: munten markten & crises
  recH4FV6ug8hEUAQX: colors.Lightorange, // Religie, geloof en radicalisme
  rec0cRSpzCGexzHlB: colors.Plum, // DNA, genetica & de biotech revolutie
  recCRw4hGduVVctjj: colors.Green, // Voedsel
  reciseUc3b4n28D6j: colors.Blue, // Politiek: bestuur, democratie & dictatuur
  recQbLdsi7mAUUI9N: colors.Lightroyal, // Maatschappij: denkers en pionier
  recm7kxtUrgDX5xY1: colors.Darkpink, // Economie
  recekWTnZGGF7YM47: colors.Lightpink, // Identiteiten, culturen & seksualiteit
  recrylKUyRBdYSXz0: colors.Darkturquoise, // Geopolitiek, oorlogen, conflicten & terreur
  recGPAyRerLApgxf5: colors.Lightred, // Gezondheid, zorg & medicijnen
  recc4qgXAqiDoC7B4: colors.Lightviolet, // Werk, banen, basisinkomen, flexwerk
};
