export const translateEntityType = (type: string) => {
  switch (type) {
    case 'tag':
      return 'onderwerp';
    case 'episode':
      return 'aflevering';
    case 'theme':
      return 'thema';
    case 'location':
      return 'locatie';
    case 'speaker':
      return 'spreker';
    case 'maker':
      return 'maker';
    case 'keywords':
      return 'huidige zoekopdracht';
    case 'date':
      return 'datum';
    default:
      return type;
  }
};
