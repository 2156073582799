import React, { FunctionComponent, useState } from 'react';
import './EpisodeMeta.scss';
import EpisodeDetails from 'models/entities/EpisodeDetails';
import { patchFilter, patchQuery, toggleFilter } from 'events/navigation';
import FilterButton from 'components/FilterButton/FilterButton';
import { EntitiesKeys, Entity } from 'models/Entities';
import Filter from 'models/Filter';
import useDevice from 'hooks/useDevice';
import classnames from 'clsx';

interface Props {
  episode: EpisodeDetails;
  filter: Filter;
}

// EpisodeView shows the episode meta data details
const EpisodeMeta: FunctionComponent<Props> = ({ episode, filter }) => {
  const { IS_MOBILE } = useDevice();

  // Description collapsed state
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);

  const toggleDescriptionCollapsed = () => {
    setIsDescriptionCollapsed((c) => !c);
  };
  const enableReadMore = IS_MOBILE && episode.description.length > 600;

  const readMoreButton = (
    <div className="read-more" onClick={toggleDescriptionCollapsed}>
      {isDescriptionCollapsed ? 'meer lezen' : 'minder lezen'}
    </div>
  );
  return (
    <div className="EpisodeMeta">
      <div
        className={classnames('description', {
          collapsed: enableReadMore && isDescriptionCollapsed,
        })}
      >
        <p>{episode.description}</p>

        {enableReadMore && readMoreButton}
      </div>

      <div className="links">
        <ul>
          {episode.getTheme() !== null && (
            <li className={'theme theme-' + (episode.getTheme()?.id || '')}>
              <div
                onClick={() => {
                  const theme = episode.getTheme();
                  theme &&
                    patchQuery({
                      search: '',
                      filter: JSON.stringify({ themes: [theme.id] }),
                      episode: '',
                    });
                }}
              >
                thema {episode.getTheme()?.name || '-'}
              </div>
            </li>
          )}
          <li>
            <div
              onClick={() => {
                patchQuery({
                  search: '',
                  filter: JSON.stringify({ years: [episode.year.toString()] }),
                  episode: '',
                });
              }}
            >
              afleveringen uit {episode.year}
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                patchQuery({
                  search: '',
                  filter: JSON.stringify({ episodes: [episode.id] }),
                  episode: '',
                });
              }}
            >
              gerelateerde afleveringen
            </div>
          </li>
        </ul>

        <ul>
          {episode.websiteUrl && (
            <li>
              <a
                href={episode.websiteUrl}
                rel="noreferrer noopener"
                target="_blank"
              >
                bekijk afleveringspagina
              </a>
            </li>
          )}
          {episode.englishVersion && episode.englishVersionUrl && (
            <li>
              <a
                href={episode.englishVersionUrl}
                rel="noreferrer noopener"
                target="_blank"
              >
                engelse versie
              </a>
            </li>
          )}
        </ul>
      </div>

      <div className="relations">
        <Relations
          title={'onderwerp' + (episode.tag.length !== 1 ? 'en' : '')}
          filterKey="tags"
          entities={episode.tag}
          filter={filter}
          draggable={!IS_MOBILE}
        />
        <Relations
          title={'locatie' + (episode.location.length !== 1 ? 's' : '')}
          filterKey="locations"
          entities={episode.location}
          filter={filter}
          draggable={!IS_MOBILE}
        />
        <Relations
          title={'spreker' + (episode.speaker.length !== 1 ? 's' : '')}
          filterKey="speakers"
          entities={episode.speaker}
          filter={filter}
          draggable={!IS_MOBILE}
        />
        <Relations
          title="regie"
          filterKey="makers"
          entities={episode.director}
          filter={filter}
          draggable={!IS_MOBILE}
        />
        <Relations
          title="research"
          filterKey="makers"
          entities={episode.researcher}
          filter={filter}
          draggable={!IS_MOBILE}
        />
        <Relations
          title="productie"
          filterKey="makers"
          entities={episode.producer}
          filter={filter}
          draggable={!IS_MOBILE}
        />
        <Relations
          title="eindredactie"
          filterKey="makers"
          entities={episode.chiefEditor}
          filter={filter}
          draggable={!IS_MOBILE}
        />
      </div>
    </div>
  );
};

type RelationEntity = Entity & { description?: string };

interface RelationsProps {
  title: string;
  filterKey: keyof EntitiesKeys;
  entities: RelationEntity[];
  filter: Filter;
  draggable: boolean;
}

const Relations = ({
  title,
  filterKey,
  entities,
  filter,
  draggable,
}: RelationsProps) => {
  // Don't render empty list
  if (entities.length === 0) {
    return null;
  }

  return (
    <>
      <h5>{title}</h5>
      {entities.map((entity) => {
        // Exclude empty
        if (!entity.name) {
          return null;
        }

        // Active state
        const isActive = filter[filterKey].some(
          (filterEntity) => filterEntity.id === entity.id
        );

        return (
          <FilterButton
            title={entity.description || ''}
            entity={entity}
            key={entity.id}
            draggable={draggable}
            onClick={(e?: React.MouseEvent<HTMLElement>) => {
              const soloFilter = e && (e.altKey || e.ctrlKey) && e.shiftKey;
              if (soloFilter) {
                patchFilter(JSON.stringify({ [filterKey]: [entity.id] }), true);
                return;
              }
              const altKey = e && (e.altKey || e.ctrlKey || e.shiftKey);
              toggleFilter(filterKey, entity.id, !altKey);
            }}
            grey
            primary={isActive}
            showDelete={isActive}
          />
        );
      })}
    </>
  );
};

export default EpisodeMeta;
