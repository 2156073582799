import {
  EVENT_VIEWPORT_TOUCH_EMPTY,
  EVENT_VIEWPORT_TOUCH_EPISODE_NODE,
  MAX_CLICK_TIME,
  MAX_CLICK_DISTANCE,
} from 'config/constants';
import { isMobile, isTablet } from 'hooks/useDevice';
import Episode from 'models/entities/Episode';
import * as PIXI from 'pixi.js';
import Viewport from './Viewport';

export default class Node extends PIXI.Container {
  remove: boolean = false;
  baseScale: number = 1;
  targetScale: number = 1;
  targetAlpha: number = 1;
  isFaded: boolean = false;
  episode?: Episode | null;

  protected hover = false;
  protected active = false;
  protected pointerDownTime = 0;
  protected pointerDownPos: PIXI.Point | null = null;
  protected touchClick = false;

  init() {
    // Bind
    this.onBlur = this.onBlur.bind(this);

    // Interaction
    this.addInteraction();
  }

  // Mark node for removal
  markRemove(remove: boolean = true) {
    this.remove = remove;
    this.targetAlpha = remove ? 0 : 1;
    this.targetScale = remove ? 0 : 1;
  }

  // Animate alpha for given container
  animateAlpha = (
    container: PIXI.Container,
    target: number,
    factor: number,
    factorDown = 1
  ) => {
    // alpha
    if (container.alpha < target) {
      container.alpha = Math.min(target, container.alpha + factor);
    } else {
      container.alpha = Math.max(target, container.alpha - factor * factorDown);
    }
  };

  // Animate scale for given container
  animateScale = (
    container: PIXI.Container,
    target: number,
    factor: number,
    factorDown = 1
  ) => {
    if (container.scale.x < target) {
      container.scale.set(
        Math.min(
          target,
          container.scale.x + (target - container.scale.x) * factor
        )
      );
    } else {
      container.scale.set(
        Math.max(target, container.scale.x - factor * factorDown)
      );
    }
  };

  // Animate alpha and scale
  animate(delta: number) {
    // Alpha
    if (this.isFaded) {
      this.animateAlpha(this, 0.1, delta * 0.04);
    } else {
      this.animateAlpha(this, this.targetAlpha, delta * 0.04);
    }

    // Scale
    this.animateScale(this, this.targetScale, delta * 0.02);

    // Remove from viewport
    this.removeHidden();
  }

  // Remove nodes flagged for removal and scale and alpha are near 0
  removeHidden() {
    if (
      this.remove &&
      Math.abs(this.scale.x - this.targetScale) < 0.01 &&
      Math.abs(this.alpha - this.targetAlpha) < 0.01
    ) {
      this.parent.removeChild(this);
      this.onRemove();
    }
  }

  // onRemove abstract function
  onRemove() {}

  // Get viewport from parent
  getViewport(): Viewport | null {
    // - parent: layer
    // -- parent: container
    // --- parent: viewport
    return this.parent?.parent?.parent as Viewport;
  }

  // Node interaction
  addInteraction() {
    // Make node interactive
    this.interactive = true;
    this.buttonMode = !(isMobile() || isTablet());

    // Mouse
    this.on('mousedown', this.onMouseDown.bind(this));
    this.on('mouseup', this.onMouseUp.bind(this));
    this.on('mouseover', this.onMouseOver.bind(this));
    this.on('mouseout', this.onMouseOut.bind(this));

    // Touch
    this.on('touchstart', this.onTouchStart.bind(this));
    this.on('touchend', this.onTouchEnd.bind(this));
    this.on('touchmove', this.onTouchMove.bind(this));
  }

  // ===============================
  // Mouse events
  // ===============================

  // Node click abstract function
  protected onMouseClick(alt: boolean) {}

  protected onMouseDown(e: PIXI.InteractionEvent) {
    if (e.data.button !== 0) {
      return;
    }

    const pos = new PIXI.Point(
      (e.data.originalEvent as MouseEvent).pageX,
      (e.data.originalEvent as MouseEvent).pageY
    );
    this.pointerDownPos = pos;
    this.pointerDownTime = performance.now();
  }

  protected onMouseUp(e: PIXI.InteractionEvent) {
    // Check if click is valid
    if (e.data.button !== 0) {
      return;
    }

    // 1. Click was fast enough
    const time = performance.now();
    const viewportPos = this.getViewport()?.position || null;
    const isFastClick = time - this.pointerDownTime < MAX_CLICK_TIME;

    if (isFastClick && viewportPos && this.pointerDownPos) {
      // Delta pointer + viewport
      const pos = new PIXI.Point(
        (e.data.originalEvent as MouseEvent).pageX,
        (e.data.originalEvent as MouseEvent).pageY
      );
      const dx = pos.x - this.pointerDownPos.x;
      const dy = pos.y - this.pointerDownPos.y;

      // 2. Pointer has not moved
      const hasMoved = Math.sqrt(dx * dx + dy * dy) > MAX_CLICK_DISTANCE;
      if (!hasMoved) {
        // Click is valid!
        // Get alt key
        const altKey =
          e.data.originalEvent &&
          (e.data.originalEvent.shiftKey ||
            e.data.originalEvent.ctrlKey ||
            e.data.originalEvent.metaKey);

        this.onMouseClick(altKey);
      }
    }

    // reset
    this.pointerDownPos = null;
  }

  protected onMouseOver() {
    this.startHover();
  }

  protected onMouseOut() {
    this.stopHover();
  }

  // ===============================
  // Hover
  // ===============================

  protected startHover() {
    if (this.remove) {
      return;
    }

    this.hover = true;

    // To top
    if (this.parent) {
      // Move to top
      const parent = this.parent;
      parent.removeChild(this);
      parent.addChild(this);
    }
    // Hover node scale
    this.targetScale = this.baseScale * 1.25;
  }

  protected stopHover() {
    // Restore node scale
    if (this.remove) {
      return;
    }
    this.targetScale = this.baseScale;
    this.hover = false;
  }

  // ===============================
  // Touch events
  // ===============================

  protected onTouchStart(e: PIXI.InteractionEvent) {
    // Allow only single touch
    if ((e.data.originalEvent as TouchEvent).touches?.length > 1) {
      this.touchClick = false;
      return;
    }

    // Store touch start info
    this.touchClick = true;
    this.pointerDownTime = performance.now();
    const pos = new PIXI.Point(
      (e.data.originalEvent as TouchEvent).touches[0]?.pageX,
      (e.data.originalEvent as TouchEvent).touches[0]?.pageY
    );
    this.pointerDownPos = pos;
  }

  protected cancelTouchClick() {
    this.pointerDownPos = null;
    this.touchClick = false;
  }

  protected onTouchMove(e: PIXI.InteractionEvent) {
    // Cancel touch click on multi touch
    if (
      this.touchClick &&
      (e.data.originalEvent as TouchEvent).touches?.length > 1
    ) {
      this.cancelTouchClick();
    }
  }

  protected onTouchEnd(e: PIXI.InteractionEvent) {
    // Only allow single touch
    if (
      !this.touchClick ||
      (e.data.originalEvent as TouchEvent).touches?.length > 1
    ) {
      this.cancelTouchClick();
      return;
    }

    const viewportPos = this.getViewport()?.position || null;

    // Required vars
    if (!viewportPos || !this.pointerDownPos) {
      return;
    }

    // Check if click is valid
    // 1: distance

    // Delta pointer + viewport
    const pos = new PIXI.Point(
      (e.data.originalEvent as TouchEvent).touches[0]?.pageX,
      (e.data.originalEvent as TouchEvent).touches[0]?.pageY
    );
    const dx = pos.x - this.pointerDownPos.x;
    const dy = pos.y - this.pointerDownPos.y;
    const hasMoved = Math.sqrt(dx * dx + dy * dy) > MAX_CLICK_DISTANCE;
    if (true || !hasMoved) {
      this.onTouchClick();
    }

    // Reset
    this.cancelTouchClick();
  }

  protected onTouchClick() {
    // Toggle hover mode
    if (!this.hover) {
      // Start hover mode
      this.startHover();

      // Blur listeners
      this.startBlurListeners();
    }
  }

  protected startBlurListeners() {
    const viewport = this.getViewport();
    if (!viewport) {
      return;
    }
    viewport.on(EVENT_VIEWPORT_TOUCH_EMPTY, this.onBlur);
    viewport.on(EVENT_VIEWPORT_TOUCH_EPISODE_NODE, this.onBlur);
  }

  protected stopBlurListeners = () => {
    const viewport = this.getViewport();
    if (!viewport) {
      return;
    }
    viewport.off(EVENT_VIEWPORT_TOUCH_EMPTY, this.onBlur);
    viewport.off(EVENT_VIEWPORT_TOUCH_EPISODE_NODE, this.onBlur);
  };

  protected onBlur(e: PIXI.InteractionEvent, node?: Node) {
    // Stop hover
    if (this.hover) {
      if (!node || node !== this) {
        this.stopHover();
        this.stopBlurListeners();
      }
    }
  }
}
