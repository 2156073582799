import { DistanceKey } from 'models/entities/Episode';
import { Info } from 'models/Info';
import { Layout } from 'models/Query';
import { Mode } from 'models/Query';
import { SuggestionType, SuggestionTypeOptions } from 'models/Suggestion';
import { TimelineSort } from 'models/visual/EpisodeTimelineLayout';

export const parseLayout = (
  value: string | null,
  defaultValue: Layout
): Layout =>
  value && Object.values(Layout).includes(value as Layout)
    ? (value as Layout)
    : defaultValue;

export const parseMode = (value: string | null, defaultValue: Mode): Mode =>
  value && Object.values(Mode).includes(value as Mode)
    ? (value as Mode)
    : defaultValue;

export const parseDistanceKey = (
  value: string | null,
  defaultValue: DistanceKey
): DistanceKey =>
  value && Object.values(DistanceKey).includes(value as DistanceKey)
    ? (value as DistanceKey)
    : defaultValue;

export const parseString = (
  value: string | null,
  defaultValue: string
): string => (value !== null ? value : defaultValue);

export const parseSuggestionType = (
  value: string | null,
  defaultValue: SuggestionType
): SuggestionType =>
  value &&
  Object.values(SuggestionTypeOptions).includes(value as SuggestionType)
    ? (value as SuggestionType)
    : defaultValue;

export const parseTimelineSort = (
  value: string | null,
  defaultValue: TimelineSort
): TimelineSort =>
  value && Object.values(TimelineSort).includes(value as TimelineSort)
    ? (value as TimelineSort)
    : defaultValue;

export const parseInfo = (
  value: string | null,
  defaultValue: Info | null
): Info | null =>
  value && Object.values(Info).includes(value as Info)
    ? (value as Info)
    : defaultValue;
