import { useEffect, useState } from 'react';

import Env from 'config/Env';
import VideoSegment from 'models/VideoSegment';
import VideoSegmentsMapper from 'mappers/VideoSegmentsMapper';

type Hook = (props: { episodeUID: string }) => [VideoSegment[], boolean];

// useVideoSegments loads the episode video segments
const useVideoSegments: Hook = ({ episodeUID }) => {
  const [ready, setReady] = useState(false);
  const [videoSegments, setVideoSegments] = useState<VideoSegment[]>([]);

  // Load video segments
  useEffect(() => {
    const controller = new AbortController();

    const loadVideoSegments = async () => {
      const url = Env.framesHost + `${episodeUID}/frames.json`;
      try {
        const response = await fetch(url, { signal: controller.signal });
        const json = await response.json();
        if (json && typeof json == 'object') {
          setVideoSegments(VideoSegmentsMapper.videoSegments(json));
          setReady(true);
        } else {
          console.error('Video segments not found');
        }
      } catch (err) {
        console.debug(err);
        console.error('Could not load video segments');
      }
    };

    loadVideoSegments();

    return () => {
      controller.abort();
    };
  }, [episodeUID]);

  return [videoSegments, ready];
};

export default useVideoSegments;
