import { useEffect, useRef, useState } from 'react';
import EpisodeNode from 'models/visual/EpisodeNode';
import Query, { Layout } from 'models/Query';
import BaseLayout from 'models/visual/BaseLayout';
import SimulationNode from 'models/visual/SimulationNode';
import EpisodeClusterLayout from 'models/visual/EpisodeClusterLayout';
import { DistanceKey } from 'models/entities/Episode';
import { parseDistanceKey, parseTimelineSort } from 'util/enumParser';
import Store from 'models/Store';
import EpisodeTimelineLayout, {
  TimelineSort,
} from 'models/visual/EpisodeTimelineLayout';
import Viewport from 'models/visual/Viewport';

type Hook = (props: {
  query: Query;
  episodeNodes: EpisodeNode[];
  viewport: Viewport | null;
}) => void;

// useLayout creates a layout that handles the positioning of EpisodeNodes in the viewport
const useLayout: Hook = ({ query, episodeNodes, viewport }) => {
  const [activeLayout, setActiveLayout] = useState<BaseLayout | null>(null);

  const lastLayout = useRef<BaseLayout | null>(null);

  // Update layout
  useEffect(() => {
    // Require viewport
    if (!viewport || viewport.destroyed) {
      return;
    }

    // Destroy previous
    if (lastLayout.current) {
      lastLayout.current.destroy();
    }

    // Update layout, based on the query.layout parameter
    let activeLayout: BaseLayout | null = null;
    switch (query.layout) {
      case Layout.EPISODE_CLUSTER:
        const key = parseDistanceKey(query.layoutParam, DistanceKey.THEME);
        activeLayout = new EpisodeClusterLayout(viewport, key);
        break;
      case Layout.EPISODE_TIMELINE:
        const timelineSort = parseTimelineSort(
          query.layoutParam,
          TimelineSort.THEME
        );
        activeLayout = new EpisodeTimelineLayout(viewport, timelineSort);
        break;
      default:
        activeLayout = new BaseLayout();
    }

    lastLayout.current = activeLayout;

    setActiveLayout(activeLayout);

    return () => {
      activeLayout && activeLayout.destroy();
    };
  }, [query.layout, query.layoutParam, viewport]);

  useEffect(() => {
    // Require viewport
    if (!viewport || viewport.destroyed) {
      return;
    }
    const validEpisodeNodes = !episodeNodes[0]?.destroyed;
    if (!activeLayout || !validEpisodeNodes) {
      return;
    }

    // Create nodes
    const nodes: SimulationNode[] = episodeNodes.map((episodeNode, index) => {
      const node = new SimulationNode(index, episodeNode);
      return node;
    });

    // Update layout
    const allEpisodesInResult =
      nodes.length === Store._entities.episodes.length;

    activeLayout.updateNodes(nodes, allEpisodesInResult);
  }, [activeLayout, episodeNodes, viewport]);
};

export default useLayout;
