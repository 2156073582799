import * as PIXI from 'pixi.js';
import { IViewportOptions, Viewport as PIXIViewport } from 'pixi-viewport';
import {
  EVENT_VIEWPORT_DOUBLE_CLICK,
  EVENT_VIEWPORT_TOUCH_EMPTY,
  EVENT_VIEWPORT_TOUCH_EPISODE_NODE,
  EVENT_VIEWPORT_MIDDLE_MOUSE_CLICK,
  MAX_MIDDLE_MOUSE_CLICK_TIME,
  HEADER_HEIGHT,
  EVENT_VIEWPORT_ZOOM,
} from 'config/constants';
import EpisodeNode from './EpisodeNode';
import { patchEpisode } from 'events/navigation';
import debounce from 'debounce';
import { getPixelDeviceRatio, isMobile, isTablet } from 'hooks/useDevice';
import { EventViewportZoom } from 'events/viewport';

export default class Viewport extends PIXIViewport {
  static readonly CLAMP_MARGIN = 150;

  minScale = 0.1;
  maxScale = 1.5;
  shouldCenterOnMinScale = false;
  isAnimating = false;
  restrictDraggingToX = false;

  private app: PIXI.Application;
  private targetCenter = new PIXI.Point(0, 0);
  private resetScale = 0;
  private targetScale = 0;
  private debouncedCenterOnMinScale = debounce(this.centerOnMinScale, 100);
  private debouncedCenterVertically = debounce(this.centerVertically, 100);
  private debouncedClampWorld = debounce(this.clampWorld, 100);
  private pointerDownPos: PIXI.Point | null = null;
  private pointerDownTime: number = 0;
  private canDoubleClick = false;
  private zoomToCenter = true;

  constructor(app: PIXI.Application) {
    super({
      screenWidth: window.innerWidth,
      screenHeight: app.view.offsetHeight,
      worldWidth: window.innerWidth,
      worldHeight: window.innerHeight,
      interaction: app.renderer.plugins.interaction,
      threshold: isMobile() || isTablet() ? 20 : 5,
      divWheel: app.view,
    } as IViewportOptions);

    this.app = app;
    this.init();
    this.setZoom(this.maxScale);
    // @ts-ignore
    window._viewport = this;
  }

  updateClampZoom() {
    this.clampZoom({
      minScale: this.minScale,
      maxScale: this.maxScale,
    });
  }

  init() {
    this.updateClampZoom();

    // Activate plugins
    this.drag()
      .pinch()
      .wheel({ smooth: 15, percent: 1.0 })
      .decelerate({ friction: 0.85 });

    // Start at position 0
    this.moveCenter(0, 0);

    // Interaction listeners
    this.on('drag-start', this.startModifyViewport);
    this.on('drag-end', this.stopModifyViewport);
    this.on('pinch-start', this.startModifyViewport);
    this.on('pinch-end', this.stopModifyViewport);
    this.on('zoomed', this.onZoomed);
    this.on('moved', this.onMoved);
    this.on('pointerdown', this.onPointerDown);
    this.on('pointerup', this.onPointerUp);
    this.on(EVENT_VIEWPORT_DOUBLE_CLICK, this.onClickZoom);
    this.on(EVENT_VIEWPORT_MIDDLE_MOUSE_CLICK, this.onClickZoom);

    // Ticker
    PIXI.Ticker.shared.add(this.onUpdate);

    // Events
    window.addEventListener('resize', this.onResize);
    window.addEventListener(EVENT_VIEWPORT_ZOOM, this.onZoom as EventListener);
  }

  onZoom = (e: CustomEvent<EventViewportZoom>) => {
    // If e.detail.factor has:
    // - Positive value: multiplier of target scale
    // - Negative valid: absolute value
    const newZoom =
      e.detail.amount > 0
        ? this.targetScale * e.detail.amount
        : -e.detail.amount;

    this.targetScale = Math.max(
      this.minScale,
      Math.min(this.maxScale, newZoom)
    );

    if (this.targetScale !== this.scale.x) {
      this.targetCenter = this.center;
      this.isAnimating = true;
    }
  };

  setTargetCenter(center: PIXI.Point, zoomToCenter: boolean) {
    this.zoomToCenter = zoomToCenter;
    const maxX = this.getMaxCenterX();
    const maxY = this.getMaxCenterY();
    Math.min(maxX, Math.max(-maxX, center.x));
    Math.min(maxY, Math.max(-maxY, center.y));
    this.targetCenter = center;
  }

  onUpdate = (delta: number) => {
    if (!this.isAnimating) {
      return;
    }

    // Scale 1
    const prevScale = this.scale.x;
    const ds = this.targetScale - this.scale.x;
    const zoomingOut = ds > 0;

    // Delta center
    const cdx = this.targetCenter.x - this.center.x;
    const cdy =
      (zoomingOut && this.restrictDraggingToX
        ? this.targetCenter.y / 10
        : this.targetCenter.y) - this.center.y;

    // Scale 2

    const nextScale = this.scale.x + delta * ds * 0.035;
    const oldPoint = this.toGlobal(this.targetCenter);
    this.setZoom(nextScale, this.zoomToCenter);
    const newPoint = this.toGlobal(this.targetCenter);
    const deltaScale = nextScale - prevScale;

    // Center
    const f = delta * 0.035;
    this.moveCenter(
      this.center.x +
        (cdx * f +
          (Math.sign(deltaScale) * (newPoint.x - oldPoint.x)) / this.scale.x),
      zoomingOut && this.restrictDraggingToX
        ? this.center.y * 0.95 * Math.min(1, delta)
        : this.center.y +
            (cdy * f +
              (Math.sign(deltaScale) * (newPoint.y - oldPoint.y)) /
                this.scale.x)
    );

    // Stop animating when near target
    const centerDist = Math.sqrt(cdx * cdx + cdy * cdy);
    const minDist = 1 / this.scale.x;
    const nearTarget =
      centerDist < minDist && Math.abs(ds) < this.minScale / 100;

    if (nearTarget) {
      this.isAnimating = false;
      this.clampWorld();
    }
  };

  reset(scale: number, targetCenter?: PIXI.Point) {
    this.minScale = scale;
    this.updateClampZoom();
    this.resetScale = scale;
    this.targetScale = scale;
    this.setTargetCenter(targetCenter || new PIXI.Point(0, 0), true);
    this.isAnimating = true;
  }

  centerTo(center = new PIXI.Point(0, 0)) {
    this.targetScale = this.scale.x;
    this.setTargetCenter(center, true);
    this.isAnimating = true;
  }

  focusTo(position: PIXI.Point, scale: number) {
    this.setTargetCenter(position, true);
    this.targetScale = scale;
    this.isAnimating = true;
  }

  scaleTo(scale: number) {
    this.focusTo(this.center, scale);
  }

  startModifyViewport = () => {
    this.interactiveChildren = false;
    this.isAnimating = false;
  };

  stopModifyViewport = () => {
    this.isAnimating = false;
    this.interactiveChildren = true;
    this.clampWorld();
  };

  centerOnMinScale() {
    if (this.shouldCenterOnMinScale && this.scale.x <= this.minScale * 1.3) {
      this.centerTo();
    }
  }

  centerVertically() {
    if (
      this.restrictDraggingToX &&
      !this.isAnimating &&
      Math.abs(this.position.y) > 0.2
    ) {
      this.centerTo(new PIXI.Point(this.center.x, 0));
    }
  }

  getMaxCenterX() {
    const width = this.getWidth();
    const contentWidth = this._bounds.maxX - this._bounds.minX;
    return (
      (width / 2 + contentWidth - Viewport.CLAMP_MARGIN) / this.scale.x / 2
    );
  }

  getMaxCenterY() {
    const height = this.getHeight();
    const contentHeight = this._bounds.maxY - this._bounds.minY;
    return (
      (height / 2 + contentHeight - Viewport.CLAMP_MARGIN) / this.scale.y / 2
    );
  }

  clampWorld() {
    this.calculateBounds();
    let clampedX: number | null = null;
    let clampedY: number | null = null;
    // X
    const maxX = this.getMaxCenterX();
    if (this.center.x >= maxX) {
      clampedX = maxX;
    }
    if (this.center.x <= -maxX) {
      clampedX = -maxX;
    }
    // Y
    const maxY = this.getMaxCenterY();
    if (this.center.y >= maxY) {
      clampedY = maxY;
    }
    if (this.center.y <= -maxY) {
      clampedY = -maxY;
    }

    // Apply
    if (clampedX !== null || clampedY !== null) {
      this.centerTo(
        new PIXI.Point(clampedX || this.center.x, clampedY || this.center.y)
      );
    }

    this.centerVertically();
  }

  onZoomed = () => {
    this.isAnimating = false;
    this.targetScale = this.scale.x;

    this.debouncedCenterOnMinScale();
    this.debouncedCenterVertically();
  };

  onMoved = () => {
    this.debouncedClampWorld();
  };

  onClickZoom = (e: PIXI.InteractionEvent) => {
    e.stopPropagation();
    if (this.targetScale <= this.resetScale + 0.01) {
      this.targetScale = 0.5;

      const isTouch = e.data.pointerType === 'touch';

      if (isTouch) {
        this.setTargetCenter(this.center, false);
      } else {
        this.setTargetCenter(
          new PIXI.Point(
            (e.data.global.x - this.position.x - this.app.stage.position.x) /
              this.scale.x,
            (e.data.global.y - this.position.y) / this.scale.y
          ),
          true
        );
      }

      this.isAnimating = true;
    } else {
      this.setTargetCenter(new PIXI.Point(0, 0), false);
      this.targetScale = this.resetScale;
      this.isAnimating = true;
    }
  };

  onPointerDown = (e: PIXI.InteractionEvent) => {
    // Single touch
    const isTouch = e.data.pointerType === 'touch';
    const singleTouch =
      isTouch && (e.data.originalEvent as TouchEvent).touches?.length === 1;
    if (isTouch && !singleTouch) {
      return;
    }

    const pos = new PIXI.Point(
      (e.data.originalEvent as MouseEvent).pageX,
      (e.data.originalEvent as MouseEvent).pageY
    );

    // Double click
    const downOnEmptySpace = e.target === this;
    const isMouse = e.data.pointerType === 'mouse';
    const isLeftMouseButton = isMouse && e.data.button === 0;
    const validDoubleClickIntent =
      downOnEmptySpace && (!isMouse || isLeftMouseButton);
    const now = performance.now();
    if (
      validDoubleClickIntent &&
      this.canDoubleClick &&
      this.pointerDownPos &&
      this.pointerDownTime
    ) {
      if (now - this.pointerDownTime < 200) {
        const dx = pos.x - this.pointerDownPos.x;
        const dy = pos.y - this.pointerDownPos.y;
        const hasMoved = Math.sqrt(dx * dx + dy * dy) > 20;
        if (!hasMoved) {
          this.canDoubleClick = false;
          this.emit(EVENT_VIEWPORT_DOUBLE_CLICK, e);
        }
      }
    } else {
      this.canDoubleClick = validDoubleClickIntent;
    }

    // Store pointer values
    this.pointerDownTime = now;
    this.pointerDownPos = new PIXI.Point(
      (e.data.originalEvent as MouseEvent).pageX,
      (e.data.originalEvent as MouseEvent).pageY
    );
  };

  onPointerUp = (e: PIXI.InteractionEvent) => {
    const isMouse = e.data.pointerType === 'mouse';

    // Middle mouse click
    const isMiddleMouseButton = isMouse && e.data.button === 1;
    const isLeftMouseButton = isMouse && e.data.button === 0;

    if (!this.pointerDownPos) {
      return;
    }

    // Only continue if the pointer has not moved too much
    const pos = new PIXI.Point(
      (e.data.originalEvent as MouseEvent).pageX,
      (e.data.originalEvent as MouseEvent).pageY
    );
    const dx = pos.x - this.pointerDownPos.x;
    const dy = pos.y - this.pointerDownPos.y;
    const hasMoved = Math.sqrt(dx * dx + dy * dy) > 20;

    if (!hasMoved && isMiddleMouseButton) {
      // 1. Click was fast enough
      const time = performance.now();
      const isFastClick =
        this.pointerDownTime &&
        time - this.pointerDownTime < MAX_MIDDLE_MOUSE_CLICK_TIME;
      if (isFastClick) {
        this.emit(EVENT_VIEWPORT_MIDDLE_MOUSE_CLICK, e);
      }
    }
    if (!hasMoved && (!isMouse || isLeftMouseButton)) {
      switch (true) {
        case e.target === this:
          this.emit(EVENT_VIEWPORT_TOUCH_EMPTY, this, null);
          patchEpisode('');
          break;
        case e.target && e.target.constructor?.name === EpisodeNode.name:
          this.emit(EVENT_VIEWPORT_TOUCH_EPISODE_NODE, this, e.target);
          break;
        default:
      }
    }
  };

  onResize = () => {
    const originalDimensions = {
      width: this.getWidth(),
      height: this.getHeight(),
    };

    // Wait for virtual keyboard to come up
    setTimeout(() => {
      const width = this.getWidth();
      const height = this.getHeight();

      // Resize viewport
      this.resize(
        width / getPixelDeviceRatio(),
        height / getPixelDeviceRatio()
      );

      // Keep viewport centered
      this.moveCenter(
        (originalDimensions.width - width) / getPixelDeviceRatio(),
        (originalDimensions.height - height) / getPixelDeviceRatio()
      );
    }, 100);
  };

  onDestroy() {
    PIXI.Ticker.shared.remove(this.onUpdate);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener(
      EVENT_VIEWPORT_ZOOM,
      this.onZoom as EventListener
    );
    this.destroy({ children: true });
  }

  getWidth() {
    return window.innerWidth * getPixelDeviceRatio();
  }

  getHeight() {
    return (window.innerHeight - HEADER_HEIGHT) * getPixelDeviceRatio();
  }

  getTargetScale() {
    return this.targetScale;
  }
}
