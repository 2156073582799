import './Wave.scss';
import classnames from 'clsx';

type Position = 'left' | 'right';

interface Props {
  position: Position;
}

// Wave shows waves
const Wave = ({ position }: Props) => (
  <div className={classnames('Wave', position)} />
);

export default Wave;
