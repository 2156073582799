import Episode from 'models/entities/Episode';
import Node from './Node';
import * as PIXI from 'pixi.js';
import type { TextStyleAlign } from 'pixi.js';
import {
  DISABLE_PREVIEW_VIDEO,
  EVENT_HIGHLIGHT_EPISODE,
  FONT_NAME,
  TILE_ARROW,
  TILE_PLACEHOLDER_IMAGE,
  TILE_SIZE,
} from 'config/constants';
import Env from 'config/Env';
import debounce from 'debounce';
import { patchEpisode } from 'events/navigation';
import { highlightEntity } from 'events/highlightEntity';
import { EventHighlightEpisode } from 'events/highlightEpisode';
import { isMobile, isTablet } from 'hooks/useDevice';

export default class EpisodeNode extends Node {
  static HIRES_IMAGE_MIN_SCALE = 0.45;
  static VIDEO_MIN_SCALE = 0.35;
  static CLICK_MIN_SCALE = 0.35;
  static ARROW_BASE_SCALE = 0.25;
  static START_TOUCH_SCALE = 0.03;
  static FOCUS_IN_SCALE = 0.6;
  static FOCUS_OUT_SCALE = 0.37;

  episode: Episode;

  private hasTileImage = false;
  private hasHiResImage = false;
  private lowResImage: PIXI.Sprite | null = null;
  private hiResImage: PIXI.Sprite | null = null;
  private video: PIXI.Sprite | null = null;
  private hasVideo = false;
  private videoVisible = false;
  private imageContainer: PIXI.Container = new PIXI.Container();
  private baseContainer: PIXI.Container = new PIXI.Container();
  private arrow: PIXI.Sprite | null = null;
  private arrowAlpha = 0;
  private highlightEntityTimeout = 0;

  debouncedShowVideo = debounce(this.showVideo, 300);
  debouncedShowVideoDelayed = debounce(this.showVideo, 1100);

  constructor(episode: Episode) {
    super();
    this.episode = episode;
    this.init();
  }

  init() {
    super.init();
    this.addChild(this.imageContainer, this.baseContainer);
    this.addLowResImage();
    this.addNameLabel();
    this.addYearLabel();
    //this.showBoundingBox();

    if (isMobile() || isTablet()) {
      this.baseContainer.cacheAsBitmap = true;
    }

    // Future
    if (this.episode.inFuture) {
      this.addImageMask();
    }

    // Prevent ghost touches on baseContainer
    this.interactiveChildren = false;
    this.hitArea = this.getBounds();
  }

  reuse() {
    this.stopActive();
    this.markRemove(false);
    this.targetScale = this.episode.getScale();
    this.baseScale = this.targetScale;
    this.isFaded = false;
    this.addHighlightListener();
  }

  onHighlight = ((e: CustomEvent<EventHighlightEpisode>) => {
    if (e.detail.id) {
      // Hover
      this.isFaded = !this.episode.getRelations().includes(e.detail.id);
    } else {
      // Unhover
      this.isFaded = false;
    }
  }) as EventListener;

  addHighlightListener() {
    !isMobile() &&
      !isTablet() &&
      window.addEventListener(EVENT_HIGHLIGHT_EPISODE, this.onHighlight);
  }

  removeHighlightListener() {
    !isMobile() &&
      !isTablet() &&
      window.removeEventListener(EVENT_HIGHLIGHT_EPISODE, this.onHighlight);
  }

  onRemove() {
    this.removeHighlightListener();
  }

  showBoundingBox() {
    let rect = this.getBounds();
    let box = new PIXI.Graphics();
    box.lineStyle(2, 0x00ff00);
    box.drawRect(rect.x, rect.y, rect.width, rect.height);
    this.addChild(box);
  }

  showVideo() {
    if (DISABLE_PREVIEW_VIDEO || isMobile()) {
      return;
    }
    if (this.videoVisible) {
      return;
    }
    this.videoVisible = true;
    this.addVideo();
  }

  hideVideo() {
    this.videoVisible = false;
    this.debouncedShowVideo.clear();
    this.debouncedShowVideoDelayed.clear();
  }

  removeVideo() {
    if (!this.video) {
      return;
    }
    // Pause video
    (this.video.texture.baseTexture.resource as PIXI.VideoResource).source // @ts-ignore
      .pause();
    this.video.destroy();
    this.removeChild(this.video);
    this.hasVideo = false;
    this.video = null;
  }

  // Add preview video
  addVideo = async () => {
    // Reuse existing video
    if (this.hasVideo) {
      if (this.video) {
        this.addChildAt(this.video, 1);
        this.video.alpha = 0;

        // Play video
        (this.video.texture.baseTexture.resource as PIXI.VideoResource).source // @ts-ignore
          .play();
      }
      return;
    }

    // Create new video
    this.hasVideo = true;

    try {
      const texture = await PIXI.Texture.fromURL(
        Env.previewHost + this.episode.uid + '.mp4'
      );
      // @ts-ignore
      (texture.baseTexture.resource as PIXI.VideoResource).source.loop = true;

      // Create video
      const video = new PIXI.Sprite(texture);
      video.name = 'video';
      video.alpha = 0;
      video.anchor.set(0.5);

      this.video = video;
      // Play video
      (this.video.texture.baseTexture.resource as PIXI.VideoResource).source // @ts-ignore
        .play();

      // Add to container if (still) visible
      if (this.videoVisible) {
        this.addChildAt(video, 1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  addImageMask() {
    const mask = new PIXI.Sprite(PIXI.Texture.WHITE);
    mask.position.set(-128, -128);
    // Prevent image and label overlapping vertically, so scale.y = 14.8
    mask.scale.set(16, 14.8);
    this.imageContainer.mask = mask;
    this.imageContainer.addChild(mask);
  }

  showLowResImage() {
    // Show the low res image
    if (this.lowResImage && !this.lowResImage.visible) {
      this.lowResImage.visible = true;
    }

    // Hide hi res image
    if (this.hasTileImage && this.hiResImage?.visible) {
      this.hiResImage.visible = false;
    }
  }

  // Add low res episode image from spritesheet
  addLowResImage() {
    // Image
    const image = new PIXI.Sprite();
    image.name = 'lowres-image';
    image.anchor.set(0.5);

    const texture = this.getTexture();
    if (texture) {
      image.texture = texture;
      this.hasTileImage = true;
      image.scale.set(2.0);
    } else {
      image.texture =
        PIXI.Loader.shared.resources[TILE_PLACEHOLDER_IMAGE].texture ||
        PIXI.Texture.WHITE;
      image.scale.set(0.5);
    }
    this.imageContainer.addChild(image);
    this.lowResImage = image;
  }

  // Show hi res image
  showHiResImage() {
    if (!this.hiResImage) {
      this.addHiResImage();
      return;
    }

    // Show the hi res image
    if (this.hiResImage && !this.hiResImage.visible) {
      this.hiResImage.visible = true;
    }

    // Hide low res image
    if (this.lowResImage?.visible) {
      this.lowResImage.visible = false;
    }
  }

  canShowHiResImage = () => {
    const completeHiddenHiResImage =
      this.hasHiResImage && this.hiResImage && !this.hiResImage.visible;

    return !this.hasHiResImage || completeHiddenHiResImage;
  };

  // Add hi res episode image from tile
  addHiResImage = async () => {
    if (this.hasHiResImage) {
      return;
    }

    this.hasHiResImage = true;
    if (!this.hasTileImage) {
      return;
    }

    // Image
    try {
      const texture = await PIXI.Texture.fromURL(
        Env.tilesHost + this.episode.getTile(512)
      );
      const image = PIXI.Sprite.from(texture);
      image.name = 'hires-image';
      image.anchor.set(0.5);
      image.scale.set(0.5);
      this.imageContainer.addChildAt(image, 0);
      this.hiResImage = image;
      this.showHiResImage();
    } catch (e) {
      console.error(e);
    }
  };

  // Add name label with theme background
  addNameLabel() {
    if (!PIXI.BitmapFont.available[FONT_NAME]) {
      return;
    }
    const textSize = TILE_SIZE / 405;
    const maxWidth = TILE_SIZE * 1.75;
    const paddingX = TILE_SIZE * 0.1;
    const paddingY = TILE_SIZE * 0.14;
    const textOffset = TILE_SIZE * 0.5 - 1;

    const style = {
      fontName: FONT_NAME,
      maxWidth,
      align: 'center' as TextStyleAlign,
    };

    //const text = new PIXI.Sprite(PIXI.Texture.WHITE);
    const text = new PIXI.BitmapText(this.episode.name, style);
    text.anchor.x = 0.5;
    text.y = textOffset;
    text.scale.set(textSize);

    const label = new PIXI.Sprite(PIXI.Texture.WHITE);
    label.tint = this.episode.getColor();
    label.anchor.x = 0.5;
    label.y = text.y - paddingY / 2;
    label.width = Math.max(TILE_SIZE, text.width + paddingX);
    label.height = text.height + paddingY;
    this.baseContainer.addChild(label, text);
  }

  // Add year label
  addYearLabel() {
    if (!PIXI.BitmapFont.available[FONT_NAME]) {
      return;
    }

    const textSize = TILE_SIZE / 720;
    const paddingY = TILE_SIZE * 0.04;
    const textOffset = -TILE_SIZE / 2;

    const style = {
      fontName: FONT_NAME,
      TILE_SIZE,
      align: 'center' as TextStyleAlign,
    };
    const text = new PIXI.BitmapText(this.episode.year.toString(), style);
    text.anchor.x = 0.5;
    text.scale.set(textSize);
    text.y = textOffset - text.height / 2 - paddingY * 1.2;

    const label = new PIXI.Sprite(PIXI.Texture.WHITE);
    label.tint = this.episode.getColor();
    label.tint = 0;
    label.alpha = 0.2;
    label.anchor.x = 0.5;
    label.y = text.y - paddingY / 2;
    label.width = TILE_SIZE;
    label.height = text.height + paddingY;

    this.baseContainer.addChild(label, text);
  }

  showArrow() {
    // Only add arrow once
    if (this.arrow) {
      this.arrowAlpha = 1;
      return;
    }
    const loader = PIXI.Loader.shared;
    const arrow = new PIXI.Sprite(loader.resources[TILE_ARROW].texture);
    arrow.name = 'arrow';
    arrow.anchor.set(0.5);
    arrow.scale.set(EpisodeNode.ARROW_BASE_SCALE);
    arrow.position.set(
      (TILE_SIZE - arrow.width) / 2,
      (-TILE_SIZE + arrow.height) / 2
    );
    arrow.alpha = -0.5;

    this.arrowAlpha = 1;
    this.arrow = arrow;
    this.addChild(arrow);
  }

  hideArrow() {
    this.arrowAlpha = 0;
  }

  // Get episode texture
  getTexture(): PIXI.Texture | null {
    const loader = PIXI.Loader.shared;

    // Get texture from loaded spritesheets
    let sheet = 1;
    let texture: PIXI.Texture | null = null;
    while (texture == null && loader.resources['episodes_' + sheet]) {
      const textures = loader.resources['episodes_' + sheet].textures;
      if (textures && this.episode.getTextureFile() in textures) {
        texture = textures[this.episode.getTextureFile()];
      }
      sheet++;
    }

    return texture;
  }

  // Animate override
  animate(delta: number) {
    super.animate(delta);

    // Video animation
    if (this.hasVideo && this.video) {
      // Video alpha
      if (this.videoVisible) {
        this.animateAlpha(this.video, 1, delta * 0.05);
      } else if (this.video.parent) {
        this.animateAlpha(this.video, 0, delta * 0.2);
      }

      // Remove from container after fadeout
      if (this.video.parent && this.video.alpha < 0.01) {
        this.removeVideo();
      }
    }

    // Arrow
    if (this.arrow && this.arrow.parent) {
      this.animateAlpha(this.arrow, this.arrowAlpha, delta * 0.1);

      // Scale animation based on alpha
      const arrowScale =
        this.arrowAlpha > 0
          ? Math.max(1, 3 - Math.max(0, this.arrow.alpha) * 3)
          : this.arrow.alpha;
      this.arrow.scale.set(EpisodeNode.ARROW_BASE_SCALE * arrowScale);

      // Remove from container after fadeout
      if (!this.arrowAlpha && this.arrow.alpha < 0.01) {
        this.removeChild(this.arrow);
        this.arrow = null;
      }
    }

    // inFuture
    if (this.episode.inFuture) {
      this.targetAlpha = 0.4;
    }
  }

  // ===============================
  // Mouse events
  // ===============================

  // Node mouse click
  onMouseClick = (alt: boolean) => {
    const viewport = this.getViewport();

    const scale = viewport ? viewport.getTargetScale() : 1;
    if (alt || scale > EpisodeNode.CLICK_MIN_SCALE) {
      // Show episode
      patchEpisode(this.episode.id);
    }

    // Add UI elements
    this.showArrow();
    this.showVideo();

    // Make Active
    this.active = true;
    this.startBlurListeners();

    // Zoom to episode
    viewport && viewport.focusTo(this.position, this.getViewportFocusScale());
  };

  // Mouse over event handler
  onMouseOver = () => {
    super.onMouseOver();

    if (this.remove) {
      return;
    }
    const viewport = this.getViewport();
    // Show arrow
    if (viewport && viewport.scale.x > EpisodeNode.CLICK_MIN_SCALE) {
      this.showArrow();
    }

    // Show video
    if (viewport && viewport.scale.x > EpisodeNode.VIDEO_MIN_SCALE) {
      this.showVideo();
    }

    highlightEntity(this.episode.getRelations());
  };

  // Mouse out event handler
  onMouseOut = () => {
    highlightEntity();

    if (!this.active) {
      this.hideVideo();
      super.onMouseOut();
      this.hideArrow();
    }
  };

  // ===============================
  // Touch events
  // ===============================

  // Tap event handler
  protected onTouchClick = () => {
    if (this.remove) {
      return;
    }

    if (this.active) {
      // show episode
      patchEpisode(this.episode.id);
    } else {
      const viewport = this.getViewport();
      if (!viewport) {
        return;
      }
      if (viewport.scale.x >= EpisodeNode.START_TOUCH_SCALE) {
        // start hover mode
        this.startTouchActive();
      } else {
        viewport.focusTo(this.position, this.getViewportFocusScale());
      }
    }

    super.onTouchClick();
  };

  // Handle touches on parent or other nodes
  protected onBlur(e: PIXI.InteractionEvent, node?: Node) {
    // Deselect
    if (this.active && node !== this) {
      this.stopTouchActive(!node);
    }
    super.onBlur(e, node);
  }

  stopActive() {
    this.active = false;
    this.hover = false;
    this.targetScale = this.baseScale;
    this.hideVideo();
    this.hideArrow();
    this.stopBlurListeners();
    this.debouncedShowVideo.clear();
    this.debouncedShowVideoDelayed.clear();
  }

  // ===============================
  // Touch active
  // ===============================

  startTouchActive() {
    this.active = true;

    // Focus on episode
    const viewport = this.getViewport();
    viewport && viewport.focusTo(this.position, this.getViewportFocusScale());

    // Show Arrow
    this.showArrow();

    // Start video
    this.debouncedShowVideoDelayed();

    this.highlightEntityShort();
  }

  highlightEntityShort() {
    this.highlightEntityShortStop();

    this.episode.getRelations && highlightEntity(this.episode.getRelations());

    this.highlightEntityTimeout = window.setTimeout(() => {
      highlightEntity();
    }, 2000);
  }

  highlightEntityShortStop() {
    clearTimeout(this.highlightEntityTimeout);
  }

  getViewportFocusScale() {
    return EpisodeNode.FOCUS_IN_SCALE + Math.max(0, 1 - this.scale.x);
  }

  stopTouchActive(zoom: boolean) {
    this.stopActive();

    // Zoom out
    if (zoom) {
      const viewport = this.getViewport();
      viewport &&
        viewport.scaleTo(
          Math.min(
            viewport.scale.x,
            EpisodeNode.FOCUS_OUT_SCALE + Math.max(0, 1 - this.scale.x)
          )
        );

      // unhover
      highlightEntity();
    }
  }
}
