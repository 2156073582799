import { STORE_MODE } from 'config/constants';
import { Mode } from 'models/Query';
import { useEffect } from 'react';

type Hook = (mode: Mode) => void;

// useMode handles toggling between the simple and advanced mode
const useMode: Hook = (mode) => {
  // Store mode in localstorage
  useEffect(() => {
    window.localStorage.setItem(STORE_MODE, mode);
  }, [mode]);
};

export default useMode;
