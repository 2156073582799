import './TabCredits.scss';

// TabCredits shows the credits tab
const TabCredits = () => {
  return (
    <div className="TabCredits tab">
      <div className="content">
        <CreditBlock
          title="Creative director & projectmanagement"
          lines={['Geert-Jan Strengholt, VPRO I&DM / Medialab']}
        />
        <CreditBlock
          title="Ontwerp & ontwikkeling"
          lines={['Werner Helmich, SUDOX']}
        />
        <CreditBlock
          title="Redactie / research"
          lines={['William de Bruijn', 'Jeroen Beumer', 'Koos van de Merbel']}
        />
        <CreditBlock
          title="Spraakanalyse data"
          lines={[
            <>
              Rana Klein, Nederlands Instituut voor Beeld en Geluid{' '}
              <i>(t/m jun 2022)</i>
            </>,
            <>
              Werner Helmich, SUDOX <i>(vanaf jul 2022)</i>
            </>,
          ]}
        />
        <CreditBlock
          title="Beeldanalyse data"
          lines={[
            <>
              Richard Vijgen, Studio Richard Vijgen <i>(t/m dec 2021)</i>
            </>,
            <>
              Werner Helmich, SUDOX <i>(vanaf 2022)</i>
            </>,
          ]}
        />
        <CreditBlock
          title="Eindredactie"
          lines={['Geert Rozinga', 'Bregtje van der Haak', 'Doke Romeijn']}
        />
        <CreditBlock title="Uitvoerend producent" lines={['Zoë de Wilde']} />
        <CreditBlock
          title="Met dank aan"
          lines={[
            'Johan Oomen, Nederlands Instituut voor Beeld en Geluid',
            'Gertjan Kuiper, VPRO',
            'Mathijn Elhorst, Rico Jansen, VPRO I&DM',
            'Stijn Nieuwendijk, Niel van Middelkoop, Liza Berseneva, Valsplat',
            'Michiel Greuter, Anchormen',
          ]}
        />
      </div>
    </div>
  );
};

interface CreditBlockProps {
  title: string;
  lines: Array<JSX.Element | string>;
}

const CreditBlock = ({ title, lines }: CreditBlockProps) => (
  <>
    <h4>{title}</h4>
    <ul>
      {lines.map((line, index) => (
        <li key={index}>{line}</li>
      ))}
    </ul>
  </>
);

export default TabCredits;
