import { STORE_HINT_NAVIGATION } from 'config/constants';
import { FunctionComponent, useCallback, useState } from 'react';
import { isTouchDevice } from 'util/touch';
import './NavigationHint.scss';

const HIDE = 'hide';

// NavigationHint shows a hint on mouse/touch navigation on first use when device is not a touch device
const NavigationHint: FunctionComponent = () => {
  const [showHint, setShowHint] = useState(
    !isTouchDevice() &&
      window.localStorage.getItem(STORE_HINT_NAVIGATION) !== HIDE
  );

  const hideHint = useCallback(() => {
    window.localStorage.setItem(STORE_HINT_NAVIGATION, HIDE);
    setShowHint(false);
  }, []);

  if (!showHint) {
    return null;
  }

  return (
    <div className="NavigationHint" onClick={hideHint}>
      <div className="mouse" />
      <div>
        Navigeer in de lijst met afleveringen door te slepen of scrollen met je
        muis.
      </div>
    </div>
  );
};

export default NavigationHint;
