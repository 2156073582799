import { useEffect, useState } from 'react';

import VideoSegment from 'models/VideoSegment';
import Annotations, { AnnotationsKeys } from 'models/Annotations';

type Hook = (props: {
  annotations: Annotations | null;
  videoSegment: VideoSegment | null;
}) => Annotations | null;

// useActiveAnnotationsForSegment returns the active annotations for a videosegment
const useActiveAnnotationsForSegment: Hook = ({
  annotations,
  videoSegment,
}) => {
  const [activeAnnotations, setActiveAnnotations] =
    useState<Annotations | null>(null);

  // set active segment
  useEffect(() => {
    if (!annotations || !videoSegment) {
      return;
    }

    const activeAnnotations = new Annotations();
    const start = Math.ceil(videoSegment.start);
    const end = Math.floor(videoSegment.end);
    Annotations.keys.forEach((key: keyof AnnotationsKeys) => {
      activeAnnotations[key] = annotations[key].filter(
        (annotation) => annotation.end >= start && annotation.start < end
      );
    });

    setActiveAnnotations(activeAnnotations);
  }, [annotations, videoSegment]);

  return activeAnnotations;
};

export default useActiveAnnotationsForSegment;
