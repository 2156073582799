import { useEffect, useState } from 'react';

type Hook = () => boolean;

// useMounted updates once after it is mounted
const useMounted: Hook = () => {
  const [mounted, setMounted] = useState(false);

  // Trigger update after mount
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return mounted;
};

export default useMounted;
