import { STORE_INFO_TAB, STORE_MODE } from 'config/constants';
import { parseInfo, parseMode } from 'util/enumParser';
import { Info } from './Info';

export enum Layout {
  NONE = 'none',
  EPISODE_CLUSTER = 'episode_cluster',
  EPISODE_TIMELINE = 'episode_timeline',
}

export enum Mode {
  SIMPLE = 'simple',
  ADVANCED = 'advanced',
}

export type PartialQuery = Partial<Record<keyof Query, string>>;

export default class Query {
  // Retrieve default mode from localstorage
  mode: Mode = parseMode(window.localStorage.getItem(STORE_MODE), Mode.SIMPLE);
  layout: Layout = Layout.EPISODE_TIMELINE;
  layoutParam: string = '';
  search: string = '';
  filter: string = '';
  episode: string = '';
  segment: string = '';
  info: Info | null = parseInfo(
    window.sessionStorage.getItem(STORE_INFO_TAB),
    null
  );
}
