import './AdvancedInputs.scss';

import { FunctionComponent, useRef, useCallback, useEffect } from 'react';
import AdvancedQuery, { AdvancedQueryFields } from 'models/AdvancedQuery';
import Input from 'components/Input/Input';

interface Props {
  onChange: (value: string) => void;
}

// AdvancedInputs shows the advanced search inputs and handles its search action
const AdvancedInputs: FunctionComponent<Props> = ({ onChange }) => {
  const inputAnd = useRef<HTMLInputElement | null>(null);
  const inputOr = useRef<HTMLInputElement | null>(null);
  const inputExact = useRef<HTMLInputElement | null>(null);
  const inputNot = useRef<HTMLInputElement | null>(null);

  // Focus first input
  useEffect(() => {
    inputAnd.current && inputAnd.current.focus();
  }, []);

  // Handle search
  const onSearch = useCallback(() => {
    const fields: AdvancedQueryFields = {
      and: '' + inputAnd.current?.value,
      or: '' + inputOr.current?.value,
      exact: '' + inputExact.current?.value,
      not: '' + inputNot.current?.value,
    };

    const queryString = new AdvancedQuery(fields).toSearchString();

    onChange(queryString);
  }, [onChange]);

  return (
    <div className="AdvancedInputs">
      <div>
        <label>
          al deze woorden
          <Input
            name="and"
            placeholder=" "
            defaultValue=""
            onChange={onSearch}
            ref={inputAnd}
          />
        </label>
      </div>
      <div>
        <label>
          één of meer van deze woorden
          <Input
            name="or"
            placeholder=" "
            defaultValue=""
            onChange={onSearch}
            ref={inputOr}
          />
        </label>
      </div>
      <div>
        <label>
          exact dit woord of deze zin
          <Input
            name="exact"
            placeholder=" "
            defaultValue=""
            onChange={onSearch}
            ref={inputExact}
          />
        </label>
      </div>
      <div>
        <label>
          geen van deze woorden
          <Input
            name="not"
            placeholder=" "
            defaultValue=""
            onChange={onSearch}
            ref={inputNot}
          />
        </label>
      </div>
    </div>
  );
};

export default AdvancedInputs;
