// TabData shows the data tab
const TabData = () => {
  return (
    <div className="TabData tab">
      <div className="content">
        <p>
          Het Archief van de Toekomst gebruikt de volgende databronnen en
          algoritmes:
        </p>
        <ul>
          <li>
            <h3>Redactionele metadata</h3>
            <p>
              De redactionele metadata bestaat uit de standaard uitzend-gegevens
              uit de (elektronische) programmagidsen van de Publieke Omroep,
              aangevuld met extra redactionele informatie in de vorm van
              thema's, onderwerpen, locaties, sprekers en makers. Deze metadata
              is aangevuld met informatie uit DAAN, de centrale archiefdatabase
              van het Nederlands Instituut voor Beeld en Geluid.
            </p>
          </li>
          <li>
            <h3>Transcripties</h3>
            <p>
              Om gedetailleerd binnen Tegenlicht afleveringen te kunnen zoeken
              en ontdekken worden transcripties gebruikt. Deze bestaan uit
              ondertitel-bestanden, Teletekst 888 ondertiteling voor doven en
              slechthorenden, aangevuld met de resultaten van ASR (automatische
              spraakherkenning met behulp van kunstmatige intelligentie).
              Doordat de transcripties ook tijdcodes bevatten, geven
              zoekresultaten altijd direct toegang tot het gevonden fragment in
              een aflevering. In het archief worden zoekresultaten uit deze
              bronnen aangeduid met SPRAAK.
            </p>
          </li>
          <li>
            <h3>Named Entity Recognition</h3>
            <p>
              De experts van het Nederlands Instituut voor Beeld en Geluid
              hebben op de transcripties een Named Entity Recognition (NER)
              uitgevoerd om relevante entiteiten in de teksten op te sporen.
              Bijvoorbeeld namen, locaties, organisaties en datums. Voor deze
              analyse wordt gebruik gemaakt van de software spaCy.
              <br />
              Naast deze entiteiten herkent het Archief van de Toekomst ook alle
              Tegenlicht thema's, onderwerpen, locaties en sprekers die in de
              transcripties voorkomen. Hiervoor wordt een SUDOX algoritme
              gebruikt dat sinds eind 2022 ook de spaCy NER verwerkt.
            </p>
          </li>
          <li>
            <h3>Object herkenning</h3>
            <p>
              Afleveringen <i>van 2002 tot 2021</i> zijn door Studio Richard
              Vijgen geanalyseerd met YOLO 9000. Dat is een open source object
              detectie model dat 9000 classificaties kan herkennen. De dataset
              is oorspronkelijk gemaakt voor de installatie 'De toekomst door
              kunstmatige ogen'.
              <br />
              <br />
              Afleveringen <i>vanaf 2022</i> worden door Tegenlicht zelfstandig
              geanalyseerd met YOLOv5 (large). Dat is een open source object
              detectie model dat 80 classificaties kan herkennen. Ook eerdere
              afleveringen <i>De Chinezen komen (2/2) (2005)</i>,{' '}
              <i>Energy War (2/2) - de groene race (2006)</i> en{' '}
              <i>De WikiLeaks code (2011)</i> zijn hiermee geanalyseerd.
              <br />
              <br />
              Deze detectie modellen zijn onderdeel van een experimenteel proces
              waarbij kunstmatige intelligentie wordt gebruikt om objecten in
              beeld te herkennen. In het archief worden de resultaten hieruit
              aangeduid met BEELD.
              <br />
            </p>
          </li>
        </ul>
        <p>
          Zoals uit{' '}
          <a href='/browse?layout=episode_timeline&layoutParam=theme&search=%7B"all"%3A"AI"%7D&episode=recWOlGpJyVG3lWgx&mode=simple'>
            afleveringen
          </a>{' '}
          van Tegenlicht al blijkt is kunstmatige intelligentie op het gebied
          van beeldherkenning nog sterk in ontwikkeling. Er is sprake van bias,
          beperkte datasets en onvolkomenheden in de training van de AI
          beeldherkenning. Meer informatie over AI en beeldherkenning vind je
          bij het{' '}
          <a
            href="https://www.vpro.nl/medialab.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            Medialab van de VPRO
          </a>{' '}
          en{' '}
          <a
            href="https://www.vpro.nl/programmas/tegenlicht/lees/artikelen/2022/kijken-naar-de-toekomst.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            dit artikel
          </a>
          . Hoewel er in sommige gevallen frictie bestaat tussen beeld en de
          duiding in de beelddata, vond de redactie het belangrijk om het
          potentieel te tonen van het zoeken op beeldniveau.
          <br />
          <br />
          De redactie houdt zich aanbevolen voor alle mogelijke feedback over
          het gebruik van (meta)data. Mail ons op{' '}
          <a href="mailto:tegenlicht@vpro.nl">tegenlicht@vpro.nl</a>.
        </p>

        {/* <div className="actions">
          <a
            className="button"
            href="https://www.vpro.nl/tegenlicht/avdt"
            target="_blank"
            rel="noreferrer noopener"
          >
            Meer informatie
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default TabData;
