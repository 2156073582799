import useEpisodeDetails from 'hooks/useEpisodeDetails';
import { FunctionComponent, useEffect, useRef } from 'react';
import './EpisodeView.scss';
import classnames from 'clsx';
import EpisodeHeader from './EpisodeHeader';
import EpisodePlayer from './EpisodePlayer';
import EpisodeMeta from './EpisodeMeta';
import { CSSTransition } from 'react-transition-group';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WINDOW_WIDTH_FULL_SCREEN_EPISODE_VIEW } from 'config/constants';
import Filter from 'models/Filter';
import EpisodeTimeline from './EpisodeTimeline';
import SearchQuery from 'models/SearchQuery';
import useDevice from 'hooks/useDevice';
import { Mode } from 'models/Query';

interface Props {
  episodeId: string;
  filter: Filter;
  segmentQuery: SearchQuery;
  mode: Mode;
}

// Should correspondent with the dimensions in EpisodeView.scss
export const getEpisodeViewWidth = () =>
  window.innerWidth < WINDOW_WIDTH_FULL_SCREEN_EPISODE_VIEW
    ? window.innerWidth
    : Math.max(800, Math.min(1300, window.innerWidth * 0.66));

// EpisodeView shows the details of an episode
const EpisodeView: FunctionComponent<Props> = ({
  episodeId,
  filter,
  segmentQuery,
  mode,
}) => {
  const { IS_MOBILE } = useDevice();

  const scrollRef = useRef<Scrollbars>(null);
  // Scroll to top on new episode
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, [episodeId]);

  const episodeViewRef = useRef<HTMLDivElement>(null);
  const [episode, ready, clearEpisodeDetails] = useEpisodeDetails(episodeId);

  const showTimeline = !IS_MOBILE && episode && episode.vimeoUrl;

  return (
    <CSSTransition
      nodeRef={episodeViewRef}
      appear={true}
      in={episodeId !== ''}
      timeout={300}
      classNames="transition"
      unmountOnExit
      onExited={clearEpisodeDetails}
    >
      <div className="EpisodeView" ref={episodeViewRef}>
        {!ready && <div className="bar"></div>}

        {episode && (
          <div className="container">
            <EpisodeHeader episode={episode} ready={ready} />
            <div className={classnames('content', { loading: !ready })}>
              <Scrollbars
                ref={scrollRef}
                className={'Scrollbar mode-' + mode}
                autoHeight
                autoHeightMax="100%"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <EpisodePlayer episode={episode} />
                {showTimeline && (
                  <EpisodeTimeline
                    key={episode.uid}
                    episode={episode}
                    segmentQuery={segmentQuery}
                    ready={episodeId !== '' && ready}
                  />
                )}
                <EpisodeMeta episode={episode} filter={filter} />
              </Scrollbars>
            </div>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default EpisodeView;
