import * as PIXI from 'pixi.js';
import { getPixelDeviceRatio, isMobile } from 'hooks/useDevice';
import { HEADER_HEIGHT } from 'config/constants';

export default class PIXIApp extends PIXI.Application {
  constructor(domContainer: HTMLDivElement) {
    super({
      resolution: getPixelDeviceRatio(),
      width: window.innerWidth,
      height: window.innerHeight,
      antialias: true,
      resizeTo: domContainer,
      autoDensity: isMobile() ? false : true,
      backgroundAlpha: 0,
      sharedTicker: true,
    });

    this.onResize();

    domContainer.appendChild(this.view);

    this.addListeners();

    this.stage.position.y = HEADER_HEIGHT / 2;
  }

  // WebGLContextLost handler
  onWebGLContextLost() {
    alert(
      'De grafisch weergave (webgl) is gecrasht. De pagina wordt opnieuw geladen.'
    );
    window.location.reload();
  }

  onContextMenu(e: MouseEvent) {
    e.preventDefault();
  }

  onResize = () => {
    if (!isMobile()) {
      return;
    }
    this.view.style.width = window.innerWidth + 'px';
    this.view.style.height = window.innerHeight + 'px';
  };

  // Add listeners
  addListeners() {
    this.view.addEventListener('webglcontextlost', this.onWebGLContextLost);
    this.view.addEventListener('contextmenu', this.onContextMenu);
    window.addEventListener('resize', this.onResize);
  }

  // Remove listeners
  removeListeners() {
    this.view.removeEventListener('webglcontextlost', this.onWebGLContextLost);
    this.view.removeEventListener('contextmenu', this.onContextMenu);
    window.removeEventListener('resize', this.onResize);
  }

  destroy() {
    this.removeListeners();
    super.destroy(true, { children: true });
  }
}
