import { useEffect, useState } from 'react';

import Result from 'models/Result';
import SearchQuery from 'models/SearchQuery';
import Store from 'models/Store';
import Env from 'config/Env';
import { showError } from './useNotifications';
import Episode from 'models/entities/Episode';
import { patchEpisode } from 'events/navigation';

type Hook = (props: {
  searchQuery: SearchQuery;
  store: Store;
}) => [Result, boolean];

const emptyResult = new Result();

// useSearchResult loads search result for the given search query
const useSearchResult: Hook = ({ searchQuery, store }) => {
  const [ready, setReady] = useState(false);
  const [result, setResult] = useState<Result>(emptyResult);

  // Search episodes, or return all episodes from the store when there is not valid query
  useEffect(() => {
    // Empty search, show all episodes
    if (searchQuery.isEmpty() || store.entities.episodes.length === 0) {
      store.entities.episodes.forEach((episode) => episode.resetScore());
      const episodes = store.entities.episodes;
      setResult(new Result(episodes));
      setReady(true);
      return;
    }

    const controller = new AbortController();

    // Fetch result from search api
    const searchEpisodes = async () => {
      const params = new URLSearchParams(searchQuery.toUrlSearchParams());
      const url = Env.apiHost + 'search/episodes?' + params.toString();
      try {
        const response = await fetch(url, { signal: controller.signal });
        const json = await response.json();
        if (json && typeof json == 'object' && json.hits?.hits) {
          const episodes = (
            json.hits.hits.map((hit: any) => {
              const episode = store.entities.episodes.find(
                (episode) => episode.id === hit._id
              );
              if (!episode) {
                console.error('Could not find episode for id', hit._id);
                return null;
              }
              episode.resetScore(hit._score);
              return episode;
            }) as Array<Episode | null>
          ).filter((e) => e !== null) as Episode[];
          setResult(new Result(episodes));
          setReady(true);

          // Show episode if searched on EpisodeUID and only 1 result
          // keep hash to include player time
          if (searchQuery.isEpisodeUID() && episodes.length === 1) {
            const hash = window.location.hash;
            patchEpisode(episodes[0].id);
            window.location.hash = hash;
          }
        } else {
          showError('Invalid search results');
          setResult(emptyResult);
          setReady(true);
        }
      } catch (err) {
        console.debug(err);
        showError('Error while searching');
        if (!controller.signal.aborted) {
          setResult(emptyResult);
          setReady(true);
        }
      }
    };

    setReady(false);
    searchEpisodes();
    return () => {
      controller.abort();
    };
  }, [searchQuery, store.entities.episodes]);

  return [result, ready];
};

export default useSearchResult;
