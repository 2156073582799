import { EntityType } from 'models/Entities';
import Maker from './Maker';
import Tag from './Tag';
import Theme from './Theme';
import Location from './Location';

export default class EpisodeDetails {
  type: string = EntityType.EPISODE;
  id: string = '';
  name: string = '';
  uid: string = '';
  poms: string = '';
  isPurchase: boolean = false;
  description: string = '';
  episode: number = 0;
  season: number = 0;
  websiteUrl: string = '';
  imageUrl: string = '';
  thumbnailUrl: string = '';
  vimeoId: number = 0;
  vimeoUrl: string = '';
  englishVersion: boolean = false;
  englishVersionUrl: string = '';
  speaker: Maker[] = [];
  tag: Tag[] = [];
  location: Location[] = [];
  theme: Theme[] = [];
  director: Maker[] = [];
  producer: Maker[] = [];
  researcher: Maker[] = [];
  chiefEditor: Maker[] = [];
  maker: Maker[] = [];

  // Date
  private dateString: string = '';
  set date(date: string) {
    this.year = date ? parseInt(date.substring(0, 4)) : 0;
    this.dateString = date;
  }
  get date(): string {
    return this.dateString;
  }

  year: number = 0;

  getTheme(): Theme | null {
    return this.theme && Array.isArray(this.theme) && this.theme.length > 0
      ? this.theme[0]
      : null;
  }

  getImage() {
    return '900o/' + this.uid + '.webp';
  }
}
