import { useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';

type Hook = (props: { container: PIXI.Container | null }) => PIXI.Sprite | null;

// useDevSprite adds a white sprite to the viewport
const useDevSprite: Hook = ({ container }) => {
  const [devSprite, setDevSprite] = useState<PIXI.Sprite | null>(null);

  // Add sprite
  useEffect(() => {
    // Require a container
    if (!container || container.destroyed) {
      return;
    }

    // Create devSprite
    const devSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    devSprite.anchor.set(0.5);
    devSprite.alpha = 0.1;
    devSprite.scale.set(0.3);

    container.addChild(devSprite);

    setDevSprite(devSprite);
  }, [container]);

  return devSprite;
};

export default useDevSprite;
