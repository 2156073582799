import { useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import Episode from 'models/entities/Episode';
import EpisodeNode from 'models/visual/EpisodeNode';
import Viewport from 'models/visual/Viewport';
import { NODE_SPREAD } from 'config/constants';
import { getPixelDeviceRatio, isMobile } from 'hooks/useDevice';

type Hook = (props: {
  ready: boolean;
  viewport: Viewport | null;
  container: PIXI.Container | null;
  episodes: Episode[];
}) => [EpisodeNode[]];

// useLayerEpisodes create a layer (PIXI Container) that contains EpisodeNodes for each Episode
const useLayerEpisodes: Hook = ({ ready, viewport, container, episodes }) => {
  const [layer, setLayer] = useState<PIXI.Container | null>(null);
  const [nodes, setNodes] = useState<EpisodeNode[]>([]);
  const prevNodes = useRef<EpisodeNode[]>([]);
  const nodeStore = useRef<Record<string, EpisodeNode>>({});

  // Create layer
  useEffect(() => {
    // Require a container
    if (!container || container.destroyed) {
      return;
    }
    const layer = new PIXI.Container();
    layer.pivot.set(0.5);
    layer.x = 0;
    layer.y = 0;

    container.addChild(layer);

    setLayer(layer);

    return () => {
      prevNodes.current = [];
      nodeStore.current = {};
      layer.destroy();
    };
  }, [container]);

  // Create EpisodeNodes
  useEffect(() => {
    // Require loader ready and layer
    if (!ready || !layer || layer.destroyed || layer.parent.parent.destroyed) {
      return;
    }

    // Mark all nodes for removal
    prevNodes.current.forEach((node) => node.markRemove(true));

    // Create EpisodeNodes
    const nodes = episodes.map((episode) => {
      // Find existing node
      const existingNode: EpisodeNode | undefined = prevNodes.current
        ? prevNodes.current?.find((node) => node.episode.id === episode.id)
        : undefined;

      // Reuse and update existing node
      if (existingNode) {
        existingNode.reuse();
        return existingNode;
      }

      const nodeInStore = episode.id in nodeStore.current;

      // Reuse or create new episode node
      const node = nodeInStore
        ? nodeStore.current[episode.id]
        : new EpisodeNode(episode);

      node.addHighlightListener();
      node.markRemove(false);
      node.position.set(
        Math.random() * (NODE_SPREAD * 2) - NODE_SPREAD,
        Math.random() * (NODE_SPREAD * 2) - NODE_SPREAD
      );
      node.scale.set(0);
      node.targetScale = episode.getScale();
      node.baseScale = node.targetScale;
      node.alpha = -0.1;
      node.targetAlpha = 1;
      node.isFaded = false;
      layer.addChild(node);

      nodeStore.current[episode.id] = node;

      return node;
    });

    // Store nodes
    setNodes(nodes);
    prevNodes.current = nodes;

    return () => {};
  }, [ready, layer, episodes]);

  const firstNodesTime = useRef(-1);

  // Update nodes
  useEffect(() => {
    if (isMobile()) {
      return;
    }
    const validNodes = nodes.length > 0 && !nodes[0].destroyed;
    if (!viewport || viewport.destroyed || !validNodes || !ready) {
      return;
    }

    // Require ticker
    const ticker = PIXI.Ticker.shared;
    if (!ticker) {
      return;
    }

    // Store first time new nodes are received
    if (firstNodesTime.current === -1) {
      firstNodesTime.current = performance.now();
    }

    // Update handler
    const onUpdate = () => {
      // Only run when app is running for more than 3sec;
      // So the intro zoom animation can finish first
      if (performance.now() - firstNodesTime.current < 3000) {
        return;
      }

      // Only show nodes in viewport range
      const zero = new PIXI.Point(0, 0);
      const width = viewport.getWidth();
      const height = viewport.getHeight();
      const margin = 256 * viewport.scale.x;
      let requestedHires = false;

      nodes.forEach((node) => {
        // Update visibility
        const pos = node.toGlobal(zero);
        const visible =
          pos.x > -margin &&
          pos.y > -margin &&
          pos.x < width + margin &&
          pos.y < height + margin;

        // Toggle low/hi res images on node
        if (!visible || requestedHires) {
          return;
        }
        if (
          viewport.scale.x * getPixelDeviceRatio() >
          EpisodeNode.HIRES_IMAGE_MIN_SCALE
        ) {
          if (node.canShowHiResImage()) {
            node.showHiResImage();
            requestedHires = true;
          }
        } else {
          node.showLowResImage();
        }
      });
    };

    ticker.add(onUpdate);

    return () => {
      ticker.remove(onUpdate);
    };
  }, [nodes, viewport, ready]);

  // Node update animation
  useEffect(() => {
    // Require layer
    if (!layer) {
      return;
    }

    // Require ticker
    const ticker = PIXI.Ticker.shared;
    if (!ticker) {
      return;
    }

    const nodes = layer.children as EpisodeNode[];

    // Update node animations
    const onUpdate = (delta: number) => {
      nodes.forEach((node) => {
        node.animate(delta);
      });
    };

    ticker.add(onUpdate);

    return () => {
      ticker.remove(onUpdate);
    };
  }, [layer]);

  return [nodes];
};

export default useLayerEpisodes;
