import { FunctionComponent, useCallback } from 'react';
import './ActiveFilters.scss';
import Filter from 'models/Filter';
import { patchFilter } from 'events/navigation';
import FilterButton from 'components/FilterButton/FilterButton';

import { Mode } from 'models/Query';
import ToolTip from 'components/Tooltip/ToolTip';
import { EntitiesKeys, Entity } from 'models/Entities';
import useDevice from 'hooks/useDevice';

interface Props {
  filter: Filter;
  episodeCount: number;
  mode: Mode;
}

// ActiveFilters shows the active filters
const ActiveFilters: FunctionComponent<Props> = ({
  filter,
  episodeCount,
  mode,
}) => {
  const { IS_MOBILE } = useDevice();

  let filterCount = 0;

  const onFilterButtonClick = useCallback(
    (key: keyof EntitiesKeys, entity: Entity) => {
      return (e: React.MouseEvent<HTMLElement>) => {
        const altMode = e.shiftKey || e.altKey || e.ctrlKey;
        if (altMode) {
          // Select only this filter
          const newFilter = new Filter();
          newFilter.addFilter(key, entity);
          patchFilter(newFilter.getQueryValue(), true);
        } else {
          // Remove the filter
          filter.removeFilter(key, entity);
          patchFilter(filter.getQueryValue(), true);
        }
      };
    },
    [filter]
  );

  return (
    <div className="ActiveFilters">
      {Filter.KEYS.filter(
        (key) => filter[key].length > 0 && (IS_MOBILE || key !== 'themes')
      ).map((key) => (
        <div className="group" key={key}>
          {filter[key].map((entity, index) => {
            filterCount++;
            return (
              <FilterButton
                key={key + '_' + entity.id}
                showDelete
                entity={entity}
                onClick={onFilterButtonClick(key, entity)}
                primary={key !== 'themes'}
                icon={index === 0}
              />
            );
          })}
        </div>
      ))}
      {filterCount > 0 && (
        <div className="clear" onClick={onClearFilter}>
          Filters wissen
        </div>
      )}

      {mode === Mode.ADVANCED && (
        <ToolTip
          title={'Aantal gevonden afleveringen'}
          style={{ whiteSpace: 'nowrap', left: 15 }}
        >
          <div className="episode-count">{episodeCount}</div>
        </ToolTip>
      )}
    </div>
  );
};

const onClearFilter = () => {
  patchFilter('', true);
};

export default ActiveFilters;
