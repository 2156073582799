import { FunctionComponent } from 'react';
import './Background.scss';

interface Props {
  backgroundImage: string;
}

// Background optionally shows a background image
const Background: FunctionComponent<Props> = ({ backgroundImage }) => {
  return (
    <div
      className="Background"
      style={{
        backgroundImage: backgroundImage ? `url('${backgroundImage}')` : 'none',
        opacity: backgroundImage ? 0.1 : 0,
      }}
    ></div>
  );
};

export default Background;
