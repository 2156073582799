import './InfoButton.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import React, { FunctionComponent } from 'react';

interface Props {
  active: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const InfoButton: FunctionComponent<Props> = ({ active, onClick }: Props) => {
  return (
    <ActionButton className="InfoButton" active={active} onClick={onClick}>
      <div className="close" />
      meer informatie
    </ActionButton>
  );
};

export default InfoButton;
