import * as d3 from 'd3';
import Node from 'models/visual/Node';

export default class SimulationNode implements d3.SimulationNodeDatum {
  index: number = 0;
  x: number = 0;
  y: number = 0;
  vx: number = 0;
  vy: number = 0;
  fx?: number | null | undefined;
  fy?: number | null | undefined;

  targetNode: Node;

  constructor(index: number, targetNode: Node) {
    this.index = index;
    this.targetNode = targetNode;
    this.x = targetNode.position.x;
    this.y = targetNode.position.y;
  }

  syncPosition() {
    this.targetNode.position.set(this.x, this.y);
  }
}
