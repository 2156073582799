import { Mode } from 'models/Query';
import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import Search from 'components/Search/Search';
import './Header.scss';
import SearchQuery from 'models/SearchQuery';
import { viewportZoom } from 'events/viewport';
import classnames from 'clsx';

interface Props {
  mode: Mode;
  searchQuery: SearchQuery;
  compactLogo: boolean;
}

// Header shows the main header on op of the page
const Header: FunctionComponent<Props> = ({
  children,
  searchQuery,
  mode,
  compactLogo,
}) => {
  return (
    <div className="Header">
      <div className="left">
        <NavLink
          to="/"
          className={classnames('logo', { compact: compactLogo })}
          onClick={resetViewportZoom}
        >
          Start
        </NavLink>
      </div>

      <Search searchQuery={searchQuery} mode={mode} />

      <div className="actions">{children}</div>
    </div>
  );
};

const resetViewportZoom = () => {
  viewportZoom(0);
};

export default Header;
