import { EntityType } from './Entities';

export enum BaseSuggestionType {
  EMPTY = 'empty',
  KEYWORDS = 'keywords',
}

export type SuggestionType = EntityType | BaseSuggestionType;

export const SuggestionTypeOptions = {
  ...EntityType,
  ...BaseSuggestionType,
};

export default class Suggestion {
  id: string = '';
  name: string = '';
  type: SuggestionType = BaseSuggestionType.EMPTY;
}
