import './TabThemes.scss';

import { FunctionComponent } from 'react';
import Store from 'models/Store';
import Theme from 'models/entities/Theme';
import { patchQuery } from 'events/navigation';
import Env from 'config/Env';

interface Props {
  onClose: () => void;
}

// TabThemes shows the Themes tab
const TabThemes: FunctionComponent<Props> = ({ onClose }) => {
  const themeCount = getThemeCount();

  return (
    <div className="TabThemes">
      <div className="tab">
        <div className="content">
          <p>
            De afleveringen zijn onderverdeeld in 20 Tegenlicht thema's. Omdat
            afleveringen vaak meerdere thema's verkennen, is voor de inkleuring
            gekozen voor het thema dat het meest/diepst wordt uitgewerkt in de
            desbetreffende aflevering.
          </p>
        </div>
      </div>
      <div className="themes">
        {Store._entities &&
          Store._entities.themes.map((theme) => (
            <ThemeBlock
              key={theme.id}
              theme={theme}
              count={themeCount.get(theme.id) || 0}
              onClick={() => {
                patchQuery({
                  search: '',
                  filter: JSON.stringify({ themes: [theme.id] }),
                  episode: '',
                });
              }}
            />
          ))}
      </div>
    </div>
  );
};

const getThemeCount = () => {
  const count: Map<string, number> = new Map();
  Store._entities &&
    Store._entities.episodes.forEach((episode) => {
      const theme = episode.getTheme();
      count.set(theme, (count.get(theme) || 0) + 1);
    });
  return count;
};

interface ThemeProps {
  theme: Theme;
  count: number;
  onClick: () => void;
}

const ThemeBlock = ({ theme, count, onClick }: ThemeProps) => {
  return (
    <div className="theme-block" onClick={onClick}>
      <div
        className="image"
        style={{ backgroundImage: 'url(' + getThemeImageUrl(theme) + ')' }}
      >
        <h2 className={'theme-' + theme.id}>{theme.name}</h2>
      </div>
      <div className="text">
        <p className="description">{theme.description}</p>
        <p className="subjects">{theme.subjects}</p>
      </div>
      <div className="button">Bekijk {count} afleveringen</div>
    </div>
  );
};

const getThemeImageUrl = (theme: Theme) =>
  `${Env.assetsHost}themes/${theme.id}.webp`;

export default TabThemes;
