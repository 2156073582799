import './Timeline.scss';
import VideoSegment from 'models/VideoSegment';
import React, { useEffect, useMemo, useRef } from 'react';
import classnames from 'clsx';
import Env from 'config/Env';
import { padString } from 'util/pad';
import useDevice from 'hooks/useDevice';

// pixels per second
const pps = 600;

interface Props {
  episodeUID: string;
  activeIndex: number | null;
  segments: VideoSegment[];
  activeSegments: VideoSegment[];
  playFrom: (t: number) => void;
}

const Timeline = ({
  episodeUID,
  segments,
  activeIndex,
  activeSegments,
  playFrom,
}: Props) => {
  const { IS_MOBILE, IS_TABLET } = useDevice();
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const imagesLoaded = useRef(new Set<number>());
  const imageLoadRequest = useRef(0);

  // set active segment
  useEffect(() => {
    if (!timelineRef.current || activeIndex === null) {
      return;
    }

    // Clear active
    const activeNodes = timelineRef.current.querySelectorAll('.active');
    activeNodes.forEach((elem) => {
      elem.classList.remove('active');
    });

    // Set active
    let elem = timelineRef.current.children[activeIndex];
    if (!elem) {
      return;
    }
    elem.classList.add('active');
  }, [activeIndex, activeSegments]);

  // create timeline
  const timeline = useMemo(() => {
    let visibleSegments: VideoSegment[] = [];

    if (activeSegments && activeSegments.length) {
      let lastEnd = 0;
      visibleSegments = activeSegments.map((segment) => {
        const diff = segment.start - lastEnd;
        const s = Object.assign({}, segment);
        s.start -= diff;
        s.end -= diff;
        lastEnd = s.end;
        return s;
      });
    }

    const basePadding = 70;
    let hoverSegmentIndex = -1;
    let hoverElem: HTMLDivElement | null = null;

    const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onHover(e.clientX);
    };

    const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
      if (e.touches.length !== 1) {
        return;
      }
      e.stopPropagation();
      onHover(e.touches[0].clientX);
    };

    const getSegmentIndexFromClientX = (clientX: number) => {
      const container = timelineRef.current;
      if (!container) {
        return -1;
      }

      const rect = container.getBoundingClientRect();
      const x = clientX - rect.left;
      const width = container.offsetWidth;
      const offset = -2 * basePadding * (x / width) + basePadding;
      const t = Math.max(
        0,
        ((x - offset) / width) * visibleSegments[visibleSegments.length - 1].end
      );

      // Get segment index based on time
      let segmentIndex = visibleSegments.findIndex(
        (segment) => segment.start <= t && segment.end > t
      );

      // Check for missing index (last element)
      if (segmentIndex === -1 && visibleSegments.length > 0) {
        const lastSegment = visibleSegments[visibleSegments.length - 1];
        if (lastSegment.end < t) {
          segmentIndex = visibleSegments.length - 1;
        }
      }

      return segmentIndex;
    };

    const onHover = (clientX: number) => {
      const container = timelineRef.current;
      if (!container) {
        return;
      }

      const segmentIndex = getSegmentIndexFromClientX(clientX);

      if (segmentIndex === hoverSegmentIndex || segmentIndex === -1) {
        return;
      }

      hoverSegmentIndex = segmentIndex;

      let elemA: HTMLDivElement | null = container.children[
        visibleSegments[segmentIndex].index
      ] as HTMLDivElement;

      if (hoverElem) {
        hoverElem.classList.remove('hover');
      }

      hoverElem = elemA;

      if (!elemA) {
        return;
      }

      for (let n = 0, len = container.children.length; n < len; n++) {
        if ((container.children[n] as HTMLDivElement).style.paddingLeft) {
          (container.children[n] as HTMLDivElement).style.paddingLeft = '';
        }
      }

      let elemB: HTMLDivElement | null = elemA;

      let padding = basePadding;
      elemA.style.paddingLeft = `${padding}px`;
      if (!elemA.classList.contains('hide')) {
        elemA.classList.add('hover');
      }
      while (padding > 1) {
        padding *= 0.35;
        elemA = (elemA && elemA.previousSibling) as HTMLDivElement | null;
        if (elemA && !elemA.classList.contains('hide')) {
          elemA.style.paddingLeft = `${padding}px`;
        }
        elemB = (elemB && elemB.nextSibling) as HTMLDivElement | null;
        if (elemB && !elemB.classList.contains('hide')) {
          elemB.style.paddingLeft = `${padding}px`;
        }
      }
    };

    const onMouseLeave = () => {
      stopHover();
    };

    const stopHover = () => {
      const container = timelineRef.current;
      if (!container) {
        return;
      }

      if (hoverElem) {
        hoverElem.classList.remove('hover');
      }

      hoverSegmentIndex = -1;
      for (let n = 0, len = container.children.length; n < len; n++) {
        if ((container.children[n] as HTMLDivElement).style.paddingLeft) {
          (container.children[n] as HTMLDivElement).style.paddingLeft = '';
        }
      }
    };

    const onClick = (e: React.MouseEvent) => {
      const segmentIndex = getSegmentIndexFromClientX(e.clientX);
      playSegment(segmentIndex);
    };

    const onTouchEnd = (e: React.TouchEvent) => {
      e.stopPropagation();
      playSegment(hoverSegmentIndex);
      stopHover();
    };

    const playSegment = (segmentIndex: number) => {
      if (segmentIndex === -1 || segmentIndex > activeSegments.length) {
        return;
      }
      const segment = activeSegments[segmentIndex];
      const segmentStart = segment.start + 0.1;
      playFrom && playFrom(segmentStart);
    };

    const correctedPPS =
      activeSegments.length > 0 ? pps / activeSegments.length : 0;

    // Load background images in batches
    const imagesToLoad: number[] = activeSegments
      .filter((segment) => !imagesLoaded.current.has(segment.index))
      .map((segment) => segment.index)
      .reverse();

    // Cancel any previous request
    window.cancelAnimationFrame(imageLoadRequest.current);

    const loadImageBatch = () => {
      if (!timelineRef.current || imagesToLoad.length === 0) {
        return;
      }

      let batch = 0;

      const onImageLoaded = (
        elem: HTMLDivElement,
        src: string,
        index: number
      ) => {
        elem.setAttribute('data-image', '');
        elem.style.backgroundImage = `url('${src}')`;
        imagesLoaded.current.add(index);
        batch--;
        if (batch === 0) {
          imageLoadRequest.current =
            window.requestAnimationFrame(loadImageBatch);
        }
      };

      for (let i = 0; i < 10; i++) {
        const index = imagesToLoad.pop();
        if (index === undefined) {
          return;
        }
        batch++;

        const elem = timelineRef.current.children[index] as HTMLDivElement;
        const src = elem.getAttribute('data-image');
        if (src) {
          const image = new Image();
          image.addEventListener(
            'load',
            onImageLoaded.bind(null, elem, src, index)
          );
          image.src = src;
        }
      }
    };
    imageLoadRequest.current = window.requestAnimationFrame(loadImageBatch);

    return segments.length ? (
      <div
        className="timeline"
        ref={timelineRef}
        onMouseMove={visibleSegments.length ? onMouseOver : undefined}
        onMouseLeave={visibleSegments.length ? onMouseLeave : undefined}
        onTouchMove={
          (IS_MOBILE || IS_TABLET) && visibleSegments.length
            ? onTouchMove
            : undefined
        }
        onTouchEnd={
          (IS_MOBILE || IS_TABLET) && visibleSegments.length
            ? onTouchEnd
            : undefined
        }
        onClick={visibleSegments.length ? onClick : undefined}
      >
        {segments.map((segment, index) => {
          const frameUrl = getFrameUrl(episodeUID, index + 1);
          return (
            <div
              className={segment.active ? '' : 'hide'}
              key={index}
              data-image={
                imagesLoaded.current.has(index) ? undefined : frameUrl
              }
              style={{
                width: (segment.end - segment.start) * correctedPPS,
                backgroundImage:
                  segment.active && imagesLoaded.current.has(index)
                    ? `url('${frameUrl}')`
                    : 'none',
              }}
            />
          );
        })}
      </div>
    ) : null;
  }, [
    timelineRef,
    episodeUID,
    playFrom,
    activeSegments,
    segments,
    IS_MOBILE,
    IS_TABLET,
  ]);

  return (
    <div className={classnames('Timeline', { loading: segments.length === 0 })}>
      {timeline}
      {segments.length && activeSegments.length === 0 && (
        <h4>Geen fragmenten gevonden</h4>
      )}
    </div>
  );
};

const getFrameUrl = (uid: string, index: number) =>
  `${Env.framesHost}${uid}/180/${uid}-Scene-${padString(
    index.toString(),
    '000',
    3
  )}-01.webp`;

export default Timeline;
