import Env from 'config/Env';
import SuggestionMapper from 'mappers/SuggestionMapper';
import Suggestion, { SuggestionTypeOptions } from 'models/Suggestion';
import { useEffect, useState } from 'react';
import { showError } from './useNotifications';

type Hook = (keywords: string) => [Suggestion[], boolean];

// useSuggestions loads suggestions for the given keywords
const useSuggestions: Hook = (keywords) => {
  const [ready, setReady] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  // Load suggestions on changing keywords
  useEffect(() => {
    const controller = new AbortController();

    const loadSuggestions = async () => {
      if (keywords === '') {
        setSuggestions([]);
        setReady(true);
        return;
      }

      let params = new URLSearchParams({ query: keywords });
      const url = Env.apiHost + 'search/entities?' + params.toString();

      const keywordSuggestion = new Suggestion();
      keywordSuggestion.id = 'keywords';
      keywordSuggestion.name = keywords;
      keywordSuggestion.type = SuggestionTypeOptions.KEYWORDS;

      const suggestions = [keywordSuggestion];
      try {
        const response = await fetch(url, { signal: controller.signal });
        const json = await response.json();
        if (json.hits?.total?.value) {
          suggestions.push(...SuggestionMapper.suggestions(json.hits.hits));
        }
        setSuggestions(suggestions);
        setReady(true);
      } catch (err) {
        console.debug(err);
        showError('Could not load suggestions');
      }
    };

    const debounceLoad = setTimeout(() => {
      setReady(false);
      loadSuggestions();
    }, 400);

    return () => {
      clearTimeout(debounceLoad);
      controller.abort();
    };
  }, [keywords]);

  return [suggestions, ready];
};

export default useSuggestions;
