import { useEffect, useState } from 'react';
import Env from 'config/Env';
import useAssetsLoader from './useAssetsLoader';
import useStore from './useStore';
import Store from 'models/Store';
import useFontsReady from './useFontsReady';
import useBitmapFont from './useBitmapFont';

type Hook = () => [Store, boolean];

const usePreload: Hook = () => {
  const [store, storeReady] = useStore();
  const [shortWait, setShortWait] = useState(false);
  const assetsReady = useAssetsLoader(store.entities.episodes);
  const fontsReady = useFontsReady();
  const bitmapFontReady = useBitmapFont();

  // Always shortly show the preloader
  useEffect(() => {
    const wait = setTimeout(
      () => {
        setShortWait(true);
      },
      Env.isDevelopment() ? 500 : 500
    );
    return () => {
      clearTimeout(wait);
    };
  }, []);

  return [
    store,
    bitmapFontReady && shortWait && storeReady && assetsReady && fontsReady,
  ];
};

export default usePreload;
