import { useMemo } from 'react';

import Result from 'models/Result';
import Filter from 'models/Filter';
import Store from 'models/Store';

type Hook = (props: {
  filter: Filter;
  searchResult: Result;
  store: Store;
}) => [Result, Result];

// useFilterResult creates a new Result set based on the filtered search results
const useFilterResult: Hook = ({ filter, searchResult, store }) => {
  // Result without theme filter
  const resultAllThemes = useMemo(() => {
    return filter.applyBaseFilters(searchResult);
  }, [filter, searchResult]);

  // Filter with theme filter
  const result = useMemo(() => {
    return filter.applyThemeFilter(resultAllThemes);
  }, [filter, resultAllThemes]);

  return [result, resultAllThemes];
};

export default useFilterResult;
