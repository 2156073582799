import { STORE_LAYOUT } from 'config/constants';
import { isMobile, isTablet } from 'hooks/useDevice';
import { DistanceKey } from 'models/entities/Episode';
import Query, { Layout, Mode } from 'models/Query';
import { TimelineSort } from 'models/visual/EpisodeTimelineLayout';
import {
  parseMode,
  parseLayout,
  parseString,
  parseDistanceKey,
  parseTimelineSort,
  parseInfo,
} from 'util/enumParser';

// QueryMapper contains mappers that create Query instances
export default class QueryMapper {
  // fromQueryString maps a query string to a Query instance
  static fromQueryString(queryString: string): Query {
    return QueryMapper.fromURLSearchParams(new URLSearchParams(queryString));
  }

  // fromURLSearchParams maps a URLSearchParams object to a Query instance
  static fromURLSearchParams(urlQuery: URLSearchParams): Query {
    const query = new Query();

    const mode =
      isTablet() || isMobile()
        ? Mode.SIMPLE
        : parseMode(urlQuery.get('mode'), query.mode);
    query.mode = mode;

    const layout = parseLayout(urlQuery.get('layout'), query.layout);
    query.layout = layout;

    query.layoutParam = QueryMapper.layoutParam(
      mode,
      layout,
      parseString(urlQuery.get('layoutParam'), query.layoutParam)
    );

    query.search = parseString(urlQuery.get('search'), query.search);
    query.filter = parseString(urlQuery.get('filter'), query.filter);
    query.episode = parseString(urlQuery.get('episode'), query.episode);
    query.segment = parseString(urlQuery.get('segment'), query.segment);
    query.info = parseInfo(urlQuery.get('info'), null);
    return query;
  }

  static layoutParam(mode: Mode, layout: Layout, param: string) {
    const storedParam = window.localStorage.getItem(
      STORE_LAYOUT + '_' + layout
    );
    switch (layout) {
      case Layout.EPISODE_CLUSTER:
        return mode === Mode.SIMPLE
          ? DistanceKey.THEME
          : storedParam || parseDistanceKey(param, DistanceKey.THEME);
      case Layout.EPISODE_TIMELINE:
        return mode === Mode.SIMPLE
          ? TimelineSort.THEME
          : storedParam || parseTimelineSort(param, TimelineSort.THEME);
    }
    return '';
  }
}
