import { useEffect, useState } from 'react';

import { TimeUpdate } from 'models/VimeoPlayer';
import {
  EVENT_PLAYER_SET_TIME,
  EVENT_PLAYER_TIME_UPDATE,
} from 'config/constants';

type Hook = () => number | null;

// usePlayerTime return player time
const usePlayerTime: Hook = () => {
  const [time, setTime] = useState<number | null>(null);

  // set active segment
  useEffect(() => {
    const onTimeUpdate = (e: CustomEvent<TimeUpdate>) => {
      setTime(e.detail.seconds);
    };

    // Listen for the events
    window.addEventListener(
      EVENT_PLAYER_TIME_UPDATE,
      onTimeUpdate as EventListener,
      false
    );

    const onSetTime = (e: CustomEvent<number>) => {
      setTime(e.detail);
    };

    window.addEventListener(
      EVENT_PLAYER_SET_TIME,
      onSetTime as EventListener,
      false
    );

    return () => {
      window.removeEventListener(
        EVENT_PLAYER_TIME_UPDATE,
        onTimeUpdate as EventListener
      );

      window.removeEventListener(
        EVENT_PLAYER_SET_TIME,
        onSetTime as EventListener
      );
    };
  }, []);

  return time;
};

export default usePlayerTime;
