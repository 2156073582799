import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import Header from 'components/Header/Header';
import './Page.scss';
import Query, { Mode } from 'models/Query';
import InfoPage from 'components/InfoPage/InfoPage';
import SearchQuery from 'models/SearchQuery';
import { CSSTransition } from 'react-transition-group';
import ModeButton from './ModeButton';
import InfoButton from './InfoButton';
import { Info } from 'models/Info';
import { patchInfo } from 'events/navigation';
import { STORE_INFO_TAB } from 'config/constants';
import { parseInfo } from 'util/enumParser';

interface Props {
  mode: Mode;
  info: Info | null;
  query: Query;
  showHeader: boolean;
  searchQuery: SearchQuery;
}

// Page wraps the page content and adds default elements like the header
const Page: FunctionComponent<Props> = ({
  mode,
  query,
  info,
  showHeader,
  searchQuery,
  children,
}) => {
  const infoRef = useRef<HTMLDivElement>(null);
  const lastTab = useRef<Info>(info || Info.ABOUT);

  useEffect(() => {
    if (!info) {
      return;
    }

    const closeInfo = (e: MouseEvent) => {
      const hideInfo = e.composedPath().find((elem) => {
        return (elem as Element).classList?.contains('Header');
      });
      hideInfo && patchInfo('');
    };
    document.addEventListener('click', closeInfo);
    return () => {
      document.removeEventListener('click', closeInfo);
    };
  }, [info]);

  const toggleInfoActive = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (info) {
        patchInfo('');
      } else {
        patchInfo(
          parseInfo(
            window.sessionStorage.getItem(STORE_INFO_TAB),
            Info.ABOUT
          ) as Info
        );
      }
    },
    [info]
  );

  const closeInfo = useCallback(() => {
    patchInfo('');
  }, []);

  useEffect(() => {
    if (info) {
      lastTab.current = info;
    }
  }, [info]);

  const infoActive = info !== null;

  return (
    <div className="Page">
      {showHeader && (
        <Header
          mode={mode}
          searchQuery={searchQuery}
          compactLogo={!!query.episode && !query.info}
        >
          <ModeButton mode={mode} />
          <InfoButton active={infoActive} onClick={toggleInfoActive} />
        </Header>
      )}
      <div className="content">{children}</div>

      <CSSTransition
        nodeRef={infoRef}
        appear={true}
        in={infoActive}
        timeout={500}
        classNames="transition"
        unmountOnExit
      >
        <div className="info-container" ref={infoRef}>
          <InfoPage tab={info || lastTab.current} onClose={closeInfo} />
        </div>
      </CSSTransition>
    </div>
  );
};

export default Page;
