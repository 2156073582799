import './Search.scss';

import { Mode } from 'models/Query';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import classnames from 'clsx';
import SearchQuery from 'models/SearchQuery';
import ExtraSearch from 'components/ExtraSearch/ExtraSearch';
import Suggestions from 'components/Suggestions/Suggestions';
import { patchSearch } from 'events/navigation';
import Input, { changeInputValue } from 'components/Input/Input';
import useDevice from 'hooks/useDevice';
import ToolTip from 'components/Tooltip/ToolTip';

interface Props {
  mode: Mode;
  searchQuery: SearchQuery;
}

// Search shows the search UI and handles the search action
const Search: FunctionComponent<Props> = ({ mode, searchQuery }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isAdvancedMode = mode === Mode.ADVANCED;
  const { IS_MOBILE } = useDevice();

  // Show Search
  const [showSearch, setShowSearch] = useState(!IS_MOBILE);
  const toggleSearch = useCallback(() => {
    setShowSearch((show) => !show);
  }, []);

  // Advanced mode
  const [showAdvanced, setShowAdvanced] = useState(false);
  const toggleAdvanced = useCallback(() => {
    setShowAdvanced((v) => !v);
  }, []);

  // Hide advanced search on document mousedown
  useEffect(() => {
    if (!showAdvanced) {
      return;
    }

    const hideAdvancedSearch = (e: MouseEvent) => {
      const hideAdvancedSearch = e.composedPath().find((elem) => {
        return (elem as Element).classList?.contains('Search');
      });

      if (!hideAdvancedSearch) {
        setTimeout(() => {
          setShowAdvanced(false);
        }, 200);
      }
    };
    document.addEventListener('mousedown', hideAdvancedSearch);

    return () => {
      document.removeEventListener('mousedown', hideAdvancedSearch);
    };
  }, [showAdvanced]);

  const onCloseAdvancedSearch = useCallback(() => {
    setShowAdvanced(false);
  }, []);

  const [searchString, setSearchString] = useState(
    searchQuery.compileFullQueryString()
  );
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Update search from query
  useEffect(() => {
    const searchString = searchQuery.compileFullQueryString();
    setSearchString(searchString);
    inputRef.current && changeInputValue(inputRef.current, searchString);
  }, [searchQuery]);

  const updateSearch = useCallback(() => {
    setSearchString(inputRef.current?.value || '');
  }, []);

  const onFocus = useCallback(() => {
    inputRef.current?.select();

    setShowSuggestions(true);

    if (showAdvanced) {
      setShowAdvanced(false);
      return;
    }

    setTimeout(() => {
      if (searchQuery.hasParams()) {
        setShowAdvanced(true);
      }
    }, 100);
  }, [searchQuery, showAdvanced]);

  const onBlur = useCallback(() => {
    // Add a delay so Suggestions events get processed
    setTimeout(() => {
      setShowSearch((show) => (IS_MOBILE ? false : show));
      setShowSuggestions(false);
    }, 150);
  }, [IS_MOBILE]);

  useEffect(() => {
    if (!IS_MOBILE) {
      return;
    }
    inputRef.current?.select();
  }, [showSearch, IS_MOBILE]);

  // Start search with shortkey
  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      const ctrlShiftF = e.ctrlKey && e.shiftKey && e.key === 'F';
      if (ctrlShiftF) {
        inputRef.current?.focus();
      }
    };
    document.addEventListener('keypress', onKeyPress);
    return () => {
      document.removeEventListener('keypress', onKeyPress);
    };
  }, []);

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        patchSearch({ all: searchString });
        inputRef.current?.blur();
      }

      if (e.key === 'Escape') {
        inputRef.current?.blur();
      }
    },
    [searchString]
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
    }
  }, []);

  const onSuggestionsClose = useCallback(
    (searchString?: string) => {
      if (searchString === undefined) {
        setSearchString(searchQuery.compileFullQueryString());
      } else {
        setSearchString(searchString);
      }
      inputRef.current?.blur();
    },
    [searchQuery]
  );

  // Focus input on mobile
  useEffect(() => {
    if (IS_MOBILE) {
      inputRef.current?.focus();
    }
  }, [IS_MOBILE]);

  // Hide search on mobile
  useEffect(() => {
    setShowSearch(!IS_MOBILE);
  }, [IS_MOBILE]);

  return (
    <div className="Search">
      <ActionButton
        active={showSearch}
        className={classnames('MobileSearchButton', { close: showSearch })}
        onClick={toggleSearch}
      >
        zoeken
      </ActionButton>

      {showSearch && (
        <>
          <div className="input-container">
            <Input
              ref={inputRef}
              name="search"
              value={searchString}
              onChange={updateSearch}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="zoek in alle afleveringen"
            />

            {!IS_MOBILE && (
              <ToolTip
                className="advanced"
                title={
                  (showAdvanced ? 'Verberg' : 'Toon') +
                  ' uitgebreide zoekopties'
                }
              >
                <div
                  className={classnames('advanced-button', mode)}
                  onClick={toggleAdvanced}
                />
              </ToolTip>
            )}

            {showSuggestions &&
              !SearchQuery.stringContainsParams(searchString) && (
                <Suggestions
                  onClose={onSuggestionsClose}
                  searchString={searchString}
                  showToggle={mode === Mode.ADVANCED}
                />
              )}

            {!IS_MOBILE && showAdvanced && (
              <ExtraSearch
                searchQuery={searchQuery}
                onClose={onCloseAdvancedSearch}
                advanced={isAdvancedMode}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Search;
