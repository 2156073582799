import './ModeButton.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import Tooltip from 'components/Tooltip/ToolTip';
import { patchMode } from 'events/navigation';
import { Mode } from 'models/Query';
import React, { FunctionComponent } from 'react';
import Switch from 'react-switch';
import debounce from 'debounce';

interface Props {
  mode: Mode;
}

const ModeButton: FunctionComponent<Props> = ({ mode }: Props) => {
  const isAdvancedMode = mode === Mode.ADVANCED;

  const setMode = debounce((mode: Mode) => {
    patchMode(mode);
  }, 10);

  const toggleMode = () => {
    setMode(isAdvancedMode ? Mode.SIMPLE : Mode.ADVANCED);
  };

  const toggleModeSwitch = (checked: boolean) => {
    setMode(checked ? Mode.ADVANCED : Mode.SIMPLE);
  };

  const modeSwitch = (
    <Switch
      onColor="#0066ff"
      handleDiameter={15}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
      height={15}
      width={30}
      checked={isAdvancedMode}
      onChange={toggleModeSwitch}
    />
  );

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Tooltip
      title={'De geavanceerde modus biedt extra zoek- en weergavemogelijkheden'}
      style={{ marginTop: -10, width: 200 }}
    >
      <ActionButton
        className="ModeButton"
        active={isAdvancedMode}
        onClick={toggleMode}
      >
        <div className="switch-wrapper" onClick={stopPropagation}>
          {modeSwitch}
        </div>
        geavanceerd
      </ActionButton>
    </Tooltip>
  );
};

export default ModeButton;
