import Annotation, { AnnotationAssetArray } from 'models/Annotation';

// AnnotationMapper maps objects to Annotations
export default class AnnotationMapper {
  static annotations(annotationArrays: AnnotationAssetArray[]): Annotation[] {
    return annotationArrays.map((t) => {
      const annotation = new Annotation();
      annotation.start = t[0];
      annotation.duration = t[1];
      annotation.confidence = t[2];
      annotation.label = t[3];
      annotation.category = t[4] || '';
      return annotation;
    });
  }
}
