import './FilterButton.scss';
import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'clsx';
import { Entity, EntityType } from 'models/Entities';
import ToolTip from 'components/Tooltip/ToolTip';
import useHighlightEntity from 'hooks/useHighlightEntity';
import { highlightEpisode } from 'events/highlightEpisode';
import { AnnotationType } from 'models/Annotation';
import { isMobile, isTablet } from 'hooks/useDevice';

interface Props {
  entity: Entity;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  showDelete?: boolean;
  blur?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  icon?: boolean;
  primary?: boolean;
  grey?: boolean;
  title?: string;
  draggable?: boolean;
}

// Filters shows all filters
const FilterButton: FunctionComponent<Props> = ({
  entity,
  onClick,
  showDelete,
  blur,
  ref = undefined,
  icon,
  primary,
  grey,
  draggable = undefined,
  title = '',
}) => {
  const show = useHighlightEntity(entity);

  const onMouseEnter = useCallback(() => {
    !isMobile() && !isTablet() && highlightEpisode(entity.id);
  }, [entity]);

  const onMouseOut = useCallback(() => {
    !isMobile() && !isTablet() && highlightEpisode();
  }, []);

  const filterButton = (
    <div
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      ref={ref}
      className={classnames(
        'FilterButton',
        icon ? 'icon-' + entity.type : undefined,
        entity.type === EntityType.THEME
          ? 'theme theme-' + entity.id
          : undefined,
        { delete: showDelete, blur, icon, primary, grey, show }
      )}
      onClick={onClick}
      draggable={draggable}
      onDragStart={
        draggable
          ? (e) => {
              e.dataTransfer.setData('text', entity.name);
              e.dataTransfer.setData('target', AnnotationType.SPEECH);
            }
          : undefined
      }
    >
      {entity.name}
    </div>
  );

  if (title) {
    return (
      <ToolTip title={title} style={{ whiteSpace: 'nowrap', left: 15 }}>
        {filterButton}
      </ToolTip>
    );
  }

  return filterButton;
};

export default FilterButton;
