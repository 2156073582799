import { FunctionComponent, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Preloader.scss';

interface Props {
  active: boolean;
}

// Preloader shows an animated preloader screen
const Preloader: FunctionComponent<Props> = ({ active }) => {
  const preloaderRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      nodeRef={preloaderRef}
      appear={true}
      in={active}
      timeout={{
        appear: 500,
        enter: 500,
        exit: 1000,
      }}
      classNames="transition"
      unmountOnExit
    >
      <div className="Preloader" ref={preloaderRef}>
        <div className="bar"></div>
        <div className="logo"></div>
      </div>
    </CSSTransition>
  );
};

export default Preloader;
