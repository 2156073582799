export enum NotificationType {
  NOTICE = 0,
  ERROR = 1,
}

interface NoticationProps {
  id: number;
  type: NotificationType;
  message: string;
}

export default class Notification {
  id: number;
  type: NotificationType;
  message: string = '';

  constructor({ id, type, message }: NoticationProps) {
    this.id = id;
    this.type = type;
    this.message = message;
  }
}
