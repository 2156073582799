type ActiveQueryFields = Partial<
  Pick<
    SearchQuery,
    'all' | 'episode' | 'annotation' | 'annotationSpeech' | 'annotationImage'
  >
>;

export default class SearchQuery {
  all: string = '';
  episode: string = '';
  annotation: string = '';
  annotationSpeech: boolean = true;
  annotationImage: boolean = false;

  static stringContainsParams(s: string) {
    return (
      s.indexOf('aflevering:') > -1 ||
      s.indexOf('fragment:') > -1 ||
      s.indexOf('in:') > -1
    );
  }

  isEmpty() {
    return this.all === '' && this.episode === '' && this.isAnnotationEmpty();
  }

  isAnnotationEmpty() {
    return (
      this.annotation === '' ||
      (!this.annotationSpeech && !this.annotationImage)
    );
  }

  hasParams() {
    return this.episode !== '' || this.annotation !== '';
  }

  parseVarsFromAll() {
    // EN: aaaaa episode:    bbbbb annotation: ccccc in: speech image
    // NL: aaaaa aflevering: bbbbb fragment:  ccccc in: spraak beeld
    if (!this.all || this.episode || this.annotation) {
      return;
    }

    const parts = this.all.split(' ').filter((s) => s);

    // AnnotationSpeech defaults to true; but is set to false in case the in: part is set explicitly
    this.annotationSpeech = this.all.indexOf('in:') === -1 ? true : false;
    this.annotationImage = false;
    this.all = '';

    let target:
      | keyof Pick<SearchQuery, 'all' | 'episode' | 'annotation'>
      | null = 'all';

    parts.forEach((s) => {
      switch (true) {
        case s.startsWith('aflevering:'):
          target = 'episode';
          s = s.replace(/^aflevering:/, '');
          break;
        case s.startsWith('fragment:'):
          target = 'annotation';
          s = s.replace(/^fragment:/, '');
          break;
        case s.startsWith('in:'):
          target = null;
          s = s.replace(/^in:/, '');
          break;
      }

      if (!target) {
        // in - mode
        if (s.startsWith('spraak')) {
          this.annotationSpeech = true;
        }
        if (s.startsWith('beeld')) {
          this.annotationImage = true;
        }
      } else {
        // append
        this[target] += ' ' + s;
      }
    });

    this.trimFields();
  }

  compileFullQueryString = () => {
    let s = '';
    if (this.all) {
      s += this.all;
    }

    if (this.episode) {
      s += ' aflevering: ' + this.episode;
    }

    if (this.annotation) {
      s += ' fragment: ' + this.annotation;

      s +=
        ' in: ' +
        (this.annotationSpeech ? 'spraak ' : '') +
        (this.annotationImage ? 'beeld' : '');
    }

    s = s.trim();
    return s;
  };

  trimFields() {
    this.all = this.all.trim().replace(/\s\s+/g, ' ');
    this.episode = this.episode.trim().replace(/\s\s+/g, ' ');
    this.annotation = this.annotation.trim().replace(/\s\s+/g, ' ');
  }

  getQueryValue() {
    const json = JSON.stringify(this);
    return json === '{}' ? '' : json;
  }

  toJSON() {
    return this.getActiveFields();
  }

  getActiveFields() {
    const activeFields: ActiveQueryFields = {};
    if (this.all) {
      activeFields.all = this.all;
    }
    if (this.episode) {
      activeFields.episode = this.episode;
    }
    if (this.annotation) {
      activeFields.annotation = this.annotation;
      activeFields.annotationSpeech = this.annotationSpeech;
      activeFields.annotationImage = this.annotationImage;
    }
    return activeFields;
  }

  getActiveAnnotationFields() {
    const activeFields: ActiveQueryFields = {};
    activeFields.annotation = this.annotation;
    activeFields.annotationSpeech = this.annotationSpeech;
    activeFields.annotationImage = this.annotationImage;
    return activeFields;
  }

  toUrlSearchParams() {
    const activeFields = this.getActiveFields();
    const params: Record<string, string> = {};
    Object.keys(activeFields).forEach((key) => {
      const k = key as keyof ActiveQueryFields;
      switch (typeof activeFields[k]) {
        case 'string':
          params[k] = activeFields[k] as string;
          break;
        case 'boolean':
          if (activeFields[k]) {
            params[k] = 'true';
          }
          break;
      }
    });

    return params;
  }

  isEpisodeUID() {
    if (!this.all) {
      return false;
    }
    const regex = new RegExp(/S\d{1,2}E\d{1,2}-\d{8}/);
    return regex.test(this.all);
  }

  getORAnnotations() {
    const annotations = this.annotation
      .replace(/\s\s+/g, ' ')
      .replaceAll(' |', '|')
      .replaceAll('| ', '|');

    const parts: string[] = [];
    let part = '';
    let inQuote = false;
    for (let i = 0; i < annotations.length; i++) {
      const c = annotations.charAt(i);
      // Quote
      if (isQuote(c)) {
        if (inQuote) {
          part += c;
          if (part) {
            parts.push(part);
            part = '';
          }
          inQuote = false;
        } else {
          if (part) {
            parts.push(part);
          }
          part = c;
          inQuote = true;
        }
      } else {
        // Non quote
        part += c;
      }
    }
    if (part) {
      parts.push(part.trim());
    }

    const spaceRegex = /([a-zA-z-()]) ([a-zA-z-()])/g;

    return (
      parts
        .filter((part) => part)
        .map((part) =>
          isQuote(part.charAt(0)) ? part : part.replace(spaceRegex, '$1 | $2')
        )
        .map((part) => (part.endsWith('|') ? part.slice(undefined, -1) : part))
        // Use %%% as temp join string here so we can clean up some edge cases
        .join(' %%% ')
        .replaceAll('%%% -', '-')
        .replaceAll('%%% &', '&')
        .replaceAll('%%%', '|')
    );
  }
}

const isQuote = (s: string) => {
  return s === '"' || s === "'";
};
