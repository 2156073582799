import Query from 'models/Query';
import useLayerEpisodes from 'hooks/visual/useLayerEpisodes';
import usePIXIBase from 'hooks/visual/usePIXIBase';
import Result from 'models/Result';
import { FunctionComponent, useRef } from 'react';
import './Visualization.scss';
import useLayout from 'hooks/visual/useLayout';
import useDevSprite from 'hooks/visual/useDevSprite';
import useMounted from 'hooks/useMounted';
import useStage from 'hooks/visual/useStage';
import usePauseVisual from 'hooks/visual/usePauseVisual';
import useHomeReset from 'hooks/useHomeReset';

interface Props {
  ready: boolean;
  home: boolean;
  result: Result;
  query: Query;
}

// Visualization builds and handles the visualization
const Visualization: FunctionComponent<Props> = ({
  ready,
  home,
  result,
  query,
}) => {
  // eslint-disable-next-line
  const mounted = useMounted();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { app, viewport, container } = usePIXIBase({
    domContainer: containerRef.current,
  });

  useStage({ app, query });

  useDevSprite({ container });

  usePauseVisual({ app, query });

  useHomeReset({ home, viewport });

  const [episodeNodes] = useLayerEpisodes({
    ready,
    viewport,
    container,
    episodes: result.episodes,
  });

  useLayout({
    query,
    episodeNodes,
    viewport,
  });

  return <div className="Visualization" ref={containerRef} />;
};

export default Visualization;
