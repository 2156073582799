import { themeColors } from 'config/themes';
import { EntityType } from 'models/Entities';

export enum DistanceKey {
  THEME = 'theme',
  LOCATION = 'location',
  TAG = 'tag',
}

export type Distances = Partial<Record<DistanceKey, number[]>>;

export default class Episode {
  type: string = EntityType.EPISODE;
  id: string = '';
  name: string = '';
  uid: string = '';
  speaker: string[] = [];
  tag: string[] = [];
  location: string[] = [];
  maker: string[] = [];
  theme: string[] = [];
  relations: string[] | undefined;
  baseScore = 1.0;
  score = 1.0;
  filterScore = 0;

  // Date
  year: number = 0;
  time: number = 0;
  inFuture: boolean = false;

  private dateString: string = '';
  set date(date: string) {
    this.year = date ? parseInt(date.substring(0, 4)) : 0;
    this.dateString = date;
    this.time = new Date(date).getTime();
    this.inFuture = this.time > new Date().getTime();
  }

  get date(): string {
    return this.dateString;
  }

  // Distances for calculating tSNE
  distances: Distances = {};
  storeIndex: number = 0;

  getTile(size: 128 | 512) {
    return size + '/' + this.getTextureFile();
  }

  getTextureFile(): string {
    return this.uid + '.webp';
  }

  getImage() {
    return '900o/' + this.uid + '.webp';
  }

  getScale() {
    const score = this.score + this.filterScore;

    switch (true) {
      case score < 1:
        return 0.45;
      default:
        return 1 + (Math.min(60, score) / 60) * 0.3;
    }
  }

  resetScore(baseScore = 1) {
    this.score = baseScore;
    this.baseScore = baseScore;
    this.filterScore = 0;
  }

  getTheme() {
    return this.theme && Array.isArray(this.theme) && this.theme.length > 0
      ? this.theme[0]
      : '';
  }

  getColor() {
    const theme = this.getTheme();
    if (theme && theme in themeColors) {
      return themeColors[theme];
    }
    return 0x000000;
  }

  getRelations() {
    if (!this.relations) {
      this.relations = [
        ...this.theme,
        ...this.tag,
        ...this.location,
        ...this.speaker,
        ...this.maker,
      ];
    }
    return this.relations;
  }

  isRelatedTo(entityId: string) {
    return this.getRelations().includes(entityId);
  }

  getRelatedness(episode: Episode) {
    let score =
      episode.theme.filter((id) => this.theme.includes(id)).length * 1 +
      episode.tag.filter((id) => this.tag.includes(id)).length * 2 +
      episode.location.filter((id) => this.location.includes(id)).length * 1 +
      episode.speaker.filter((id) => this.speaker.includes(id)).length * 2;
    return score;
  }
}
