// Env provides easy access to environment variables
class Env {
  static readonly assetsHost: string =
    process.env.REACT_APP_ASSETS_HOST || '/assets/';

  static readonly framesHost: string = Env.assetsHost + 'frames/';
  static readonly previewHost: string = Env.assetsHost + 'preview/';
  static readonly tilesHost: string = Env.assetsHost + 'tiles/';
  static readonly annotationsHost: string = Env.assetsHost + 'annotations/';
  static readonly dataHost: string = Env.assetsHost + 'data/';

  static readonly apiHost: string =
    process.env.REACT_APP_API_HOST || '/api/v1/';
  static readonly nodeEnv: string = process.env.NODE_ENV || 'production';
  static readonly forceHDPI: boolean =
    !!process.env.REACT_APP_FORCE_HDPI || false;

  static isDevelopment() {
    return Env.nodeEnv === 'development';
  }

  static isProduction() {
    return Env.nodeEnv === 'production';
  }
}

export default Env;
