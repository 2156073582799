import './AdvancedInput.scss';

import { FunctionComponent, useRef, useState } from 'react';
import Input, { changeInputValue } from 'components/Input/Input';
import AdvancedInputs from './AdvancedInputs';
import ToolTip from 'components/Tooltip/ToolTip';

interface Props {
  name: string;
  placeholder: string;
  defaultValue: string;
  showAdvanced: boolean;
}

const AdvancedInput: FunctionComponent<Props> = ({
  name,
  placeholder,
  defaultValue,
  showAdvanced,
}) => {
  const [showAdvancedInputs, setShowAdvancedInputs] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeInputValue = (value: string) => {
    if (!inputRef.current) {
      return;
    }
    changeInputValue(inputRef.current, value);
  };

  return (
    <div className="AdvancedInput">
      <div className="input-wrapper">
        <Input
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          readonly={showAdvancedInputs}
        />
        {showAdvanced && (
          <ToolTip
            className="advanced"
            title="uitgebreide invoervelden voor booleans zoeken"
          >
            <div
              className="advanced-button"
              onClick={() => {
                setShowAdvancedInputs((v) => !v);
              }}
            ></div>
          </ToolTip>
        )}
      </div>
      {showAdvancedInputs && (
        <div className="advanced-inputs">
          <AdvancedInputs onChange={onChangeInputValue} />
        </div>
      )}
    </div>
  );
};

export default AdvancedInput;
