import { useEffect, useState } from 'react';

import SearchQuery from 'models/SearchQuery';
import Env from 'config/Env';
import { showError } from './useNotifications';

type Hook = (props: {
  episodeUID: string;
  segmentQuery: SearchQuery | null;
  doUpdate: boolean;
}) => number[] | null;

// useSegmentSearch loads annotation search result for the given search query
const useSegmentSearch: Hook = ({ episodeUID, segmentQuery, doUpdate }) => {
  const [segmentResult, setSegmentResult] = useState<number[] | null>([]);

  // Search episodes, or return all episodes from the store when there is not valid query
  useEffect(() => {
    if (!doUpdate) {
      return;
    }
    // Empty search, show all episodes
    if (!segmentQuery || !segmentQuery.annotation) {
      setSegmentResult(null);
      return;
    }
    const controller = new AbortController();

    // Fetch result from search api
    const searchAnnotations = async () => {
      let params = new URLSearchParams(segmentQuery.toUrlSearchParams());
      params.set('episodeId', episodeUID);
      params.set('annotation', segmentQuery.getORAnnotations());

      const url = Env.apiHost + 'search/segments?' + params.toString();
      try {
        const response = await fetch(url, { signal: controller.signal });

        const json = await response.json();
        if (json && typeof json == 'object' && json.hits?.hits) {
          const segmentResults = json.hits.hits.map(
            (hit: { _source: { segment: number } }) => hit._source.segment
          );
          setSegmentResult(segmentResults);
        } else {
          showError('Invalid search results');
          setSegmentResult([]);
        }
      } catch (err) {
        console.debug(err);
        showError('Error while searching');
        if (!controller.signal.aborted) {
          setSegmentResult([]);
        }
      }
    };
    searchAnnotations();
    return () => {
      controller.abort();
    };
  }, [episodeUID, segmentQuery, doUpdate]);

  return segmentResult;
};

export default useSegmentSearch;
