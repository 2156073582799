import useEntities from './useEntities';
import Store from 'models/Store';
import { useState, useEffect } from 'react';

type Hook = () => [Store, boolean];

// useStore creates a store with all episodes and entities
const useStore: Hook = () => {
  const [store, setStore] = useState<Store>(new Store());
  const [entities, entitiesReady] = useEntities();

  // Create store
  useEffect(() => {
    setStore(new Store(entities));
  }, [entities]);

  return [store, entitiesReady];
};

export default useStore;
