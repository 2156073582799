import { FunctionComponent } from 'react';
import classnames from 'clsx';
import './ActionButton.scss';

interface ActionButtonProps {
  className: string;
  active: boolean;
  onClick: (e: React.MouseEvent) => void;
}

// ActionButton displays a button in the Header
const ActionButton: FunctionComponent<ActionButtonProps> = ({
  children,
  className,
  active,
  onClick,
}) => {
  return (
    <div
      className={classnames('ActionButton', className, { active })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ActionButton;
