import { useEffect, useState, useRef } from 'react';
import { EVENT_NOTIFICATION_SHOW } from 'config/constants';
import Notification, { NotificationType } from 'models/Notification';

type Hook = () => Notification[];

interface EventNotificationShow {
  type: NotificationType;
  message: string;
}

// useNotifications handles showing of notifications
const useNotifications: Hook = () => {
  const notificationCount = useRef(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Notification listener
  useEffect(() => {
    // Handle notification event
    const onNotification = ((e: CustomEvent<EventNotificationShow>) => {
      notificationCount.current++;
      setNotifications((notifications) => [
        ...notifications,
        new Notification({
          id: notificationCount.current,
          type: e.detail.type,
          message: e.detail.message,
        }),
      ]);
    }) as EventListener;

    window.addEventListener(EVENT_NOTIFICATION_SHOW, onNotification);

    return () => {
      window.removeEventListener(EVENT_NOTIFICATION_SHOW, onNotification);
    };
  }, [notifications]);

  return notifications;
};

export default useNotifications;

// showNotice emits a new notice event
export const showNotice = (message: string) => {
  const event = new CustomEvent<EventNotificationShow>(
    EVENT_NOTIFICATION_SHOW,
    {
      detail: { type: NotificationType.NOTICE, message },
    } as CustomEventInit
  );
  window.dispatchEvent(event);
};

// showError emits a new error notice event
export const showError = (message: string) => {
  const event = new CustomEvent<EventNotificationShow>(
    EVENT_NOTIFICATION_SHOW,
    {
      detail: { type: NotificationType.ERROR, message },
    } as CustomEventInit
  );
  window.dispatchEvent(event);
};
