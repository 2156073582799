import Player, { Options } from '@vimeo/player';
import {
  EVENT_PLAYER_SET_TIME,
  EVENT_PLAYER_TIME_UPDATE,
} from 'config/constants';

export type TimeUpdate = {
  seconds: number;
  percent: number;
  duration: number;
};

interface Props {
  onPlay: () => void;
  element: string | HTMLIFrameElement | HTMLElement;
  options?: Options | undefined;
}

export default class VimeoPlayer extends Player {
  onPlay: () => void;

  constructor({ onPlay, element, options }: Props) {
    super(element, options);

    this.onPlay = onPlay;

    process.env.NODE_ENV === 'development' && this.mute();

    this.startTimeUpdateEmitter();
    this.addSetTimeListener();

    this.startFromHashTime();
  }

  mute() {
    this.setVolume(0);
  }

  startFromHashTime() {
    const hash = window.location.hash;
    if (hash) {
      const startTime = parseFloat(hash.substring(1));
      if (startTime) {
        this.playFrom(startTime);
      }
    }
  }

  addSetTimeListener() {
    window.addEventListener(
      EVENT_PLAYER_SET_TIME,
      this.onSetTime as EventListener,
      false
    );
  }

  removeSetTimeListener() {
    window.removeEventListener(
      EVENT_PLAYER_SET_TIME,
      this.onSetTime as EventListener
    );
  }

  onSetTime = (e: CustomEvent<number>) => {
    this.playFrom(e.detail);
  };

  startTimeUpdateEmitter() {
    this.on('timeupdate', this.onTimeUpdate);
  }

  stopTimeUpdateEmitter() {
    this.off('timeupdate', this.onTimeUpdate);
  }

  onTimeUpdate = (t: TimeUpdate) => {
    const event = new CustomEvent<TimeUpdate>(EVENT_PLAYER_TIME_UPDATE, {
      detail: t,
    } as CustomEventInit);
    window.dispatchEvent(event);
  };

  destroy() {
    this.stopTimeUpdateEmitter();

    this.removeSetTimeListener();

    return super.destroy();
  }

  playFrom(t: number) {
    this.setCurrentTime(t);
    this.play();
    this.onPlay();
  }

  start() {
    this.play();
    this.onPlay();
  }

  static playFrom(t: number) {
    const event = new CustomEvent<number>(EVENT_PLAYER_SET_TIME, {
      detail: t,
    } as CustomEventInit);
    window.dispatchEvent(event);
  }
}
