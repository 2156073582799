import { useEffect, useState } from 'react';
import Annotations from 'models/Annotations';
import useAnnotation from './useAnnotation';
import { AnnotationType } from 'models/Annotation';

type Hook = (props: { episodeUID: string }) => Annotations | null;

// useAnnotations loads the episode annotations
const useAnnotations: Hook = ({ episodeUID }) => {
  const [annotations, setAnnotations] = useState<Annotations | null>(null);

  const [speechAnnotations, speechAnnotationsReady] = useAnnotation({
    annotationType: AnnotationType.SPEECH,
    episodeUID,
  });

  const [imageAnnotations, imageAnnotationsReady] = useAnnotation({
    annotationType: AnnotationType.IMAGE,
    episodeUID,
  });

  // clear annotations
  useEffect(() => {
    setAnnotations(null);
  }, [episodeUID]);

  // create annotations
  useEffect(() => {
    if (speechAnnotationsReady && imageAnnotationsReady) {
      const annotations = new Annotations();
      annotations.speech = speechAnnotations;
      annotations.image = imageAnnotations;
      setAnnotations(annotations);
    }
  }, [
    speechAnnotations,
    speechAnnotationsReady,
    imageAnnotations,
    imageAnnotationsReady,
  ]);

  return annotations;
};

export default useAnnotations;
