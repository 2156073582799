import { EntityType } from 'models/Entities';

export default class Speaker {
  type: string = EntityType.SPEAKER;
  id: string = '';
  name: string = '';
  firstName: string = '';
  lastName: string = '';
  description: string = '';
}
