import { useEffect, useState } from 'react';

type Hook = () => boolean;

const useFontsReady: Hook = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      // Font load in browser
      if (document.fonts && document.fonts.ready) {
        // Wait for fonts to load
        await document.fonts.ready;
      }
      setReady(true);
    };
    loadFonts();
  }, []);

  return ready;
};

export default useFontsReady;
