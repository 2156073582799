import Suggestion from 'models/Suggestion';
import { parseSuggestionType } from 'util/enumParser';

// SuggestionMapper maps objects to Suggestions
export default class SuggestionMapper {
  static suggestions(list: { _source: Partial<Suggestion> }[]): Suggestion[] {
    const suggestions: Suggestion[] = Array.isArray(list)
      ? list.map((obj) =>
          SuggestionMapper.suggestion(obj._source as Partial<Suggestion>)
        )
      : [];
    return suggestions;
  }

  static suggestion(obj: Partial<Suggestion>): Suggestion {
    const suggestion = new Suggestion();
    suggestion.id = obj.id || '';
    suggestion.name = obj.name || 'no-name';
    suggestion.type = parseSuggestionType(obj.type || '', suggestion.type);
    return suggestion;
  }
}
