import { FunctionComponent, useEffect, useState } from 'react';
import './Layouts.scss';
import classnames from 'clsx';
import { Layout, Mode } from 'models/Query';
import { patchQuery } from 'events/navigation';
import { TimelineSort } from 'models/visual/EpisodeTimelineLayout';
import { DistanceKey } from 'models/entities/Episode';
import { STORE_LAYOUT } from 'config/constants';
import ToolTip from 'components/Tooltip/ToolTip';
import { translateEntityType } from 'util/translate';

interface Props {
  mode: Mode;
  layout: Layout;
  layoutParam: string;
}

const paramsEpisodeCluster = {
  [DistanceKey.THEME]: 'thema',
  [DistanceKey.TAG]: 'onderwerp',
  [DistanceKey.LOCATION]: 'locatie',
};
const paramsEpisodeTimeline = {
  [TimelineSort.THEME]: 'thema',
  [TimelineSort.DATE]: 'datum',
};

// Layouts shows the details of an episode
const Layouts: FunctionComponent<Props> = ({ mode, layout, layoutParam }) => {
  // Episode timeline layout
  const timelineActive = layout === Layout.EPISODE_TIMELINE;
  const buttonEpisodeTimeline = (
    <LayoutButton
      layout={Layout.EPISODE_TIMELINE}
      title="tijdlijn"
      active={timelineActive}
      className="episode-timeline"
      currentParam={layoutParam}
      defaultParam={TimelineSort.THEME}
      params={mode === Mode.ADVANCED ? paramsEpisodeTimeline : {}}
    />
  );

  // Episode cluster layout
  const clusterActive = layout === Layout.EPISODE_CLUSTER;
  const buttonEpisodeCluster = (
    <LayoutButton
      layout={Layout.EPISODE_CLUSTER}
      title="clusters"
      active={clusterActive}
      className="episode-cluster"
      currentParam={layoutParam}
      defaultParam={DistanceKey.THEME}
      params={mode === Mode.ADVANCED ? paramsEpisodeCluster : {}}
    />
  );
  return (
    <div className="Layouts">
      <h4>weergave</h4>
      <ToolTip title={'Afleveringen chronologisch op een tijdlijn'}>
        {buttonEpisodeTimeline}
      </ToolTip>
      <ToolTip
        title={
          mode === Mode.SIMPLE
            ? 'Afleveringen gegroepeerd op thema'
            : 'Afleveringen gegroepeerd op ' +
              translateEntityType(
                getLastLayoutParam(Layout.EPISODE_CLUSTER, DistanceKey.THEME)
              )
        }
      >
        {buttonEpisodeCluster}
      </ToolTip>
    </div>
  );
};

export const getLastLayoutParam = (layout: Layout, defaultValue: string) => {
  return (
    window.localStorage.getItem(STORE_LAYOUT + '_' + layout) || defaultValue
  );
};

const setLastLayoutParam = (layout: Layout, param: string) => {
  return window.localStorage.setItem(STORE_LAYOUT + '_' + layout, param);
};

interface LayoutButtonProps {
  layout: Layout;
  title: string;
  active: boolean;
  className: string;
  currentParam: string;
  defaultParam: string;
  params: Record<string, string>;
}

const LayoutButton = ({
  layout,
  title,
  className,
  active,
  params,
  currentParam,
  defaultParam,
}: LayoutButtonProps) => {
  const [showParams, setShowParams] = useState(false);

  const onClick = (layoutParam = '') => {
    patchQuery({ layout, layoutParam });
  };

  const param = getLastLayoutParam(layout, defaultParam);

  useEffect(() => {
    if (!showParams) {
      return;
    }

    const hideParams = () => {
      setShowParams(false);
    };
    document.addEventListener('click', hideParams);

    return () => {
      document.removeEventListener('click', hideParams);
    };
  }, [showParams]);

  return (
    <div
      className={classnames('LayoutButton', className, {
        active,
      })}
      onClick={() => {
        onClick(currentParam);
      }}
    >
      <span>{title}</span>
      {Object.keys(params).length > 0 && (
        <div
          onClick={(e) => {
            setShowParams((v) => !v);
            e.stopPropagation();
          }}
          className={classnames('current-param', 'icon-' + param)}
        />
      )}
      {showParams && (
        <div className="params">
          {Object.keys(params).map((param) => (
            <div
              key={param}
              onClick={(e) => {
                e.stopPropagation();
                setShowParams(false);
                onClick(param);
                setLastLayoutParam(layout, param);
              }}
              className={classnames('param', 'icon-' + param)}
            >
              {params[param]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Layouts;
